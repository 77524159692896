<template>

    <eon-ui-section class="hydrated">

        <!-- <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Übersicht</a>
                <a href="/impressum">Impressum</a>
            </eon-ui-breadcrumb>
        </div> -->

        <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">

                <eon-ui-headline text="Impressum" size="h4" class="hydrated text-blue"></eon-ui-headline>

                    <div class="px-8">
                        <eon-ui-text text-style="copy">
                        <b>Bayernwerk Akademie GmbH </b><br />
                        Lilienthalstraße 7<br />
                        93049 Regensburg<br /><br />
                        Telefon: 09 41-2 01-00<br />
                        E-Mail: <eon-ui-link href="mailto: akademie@bayernwerk.de" text="akademie@bayernwerk.de" display-inline="true" class="hydrated" scheme="turquoise"></eon-ui-link> <br /><br />
                        </eon-ui-text>
                        <hr>

                        <div class="px-12 py-6">

                            <eon-ui-text text-style="copy"><b>Geschäftsführung</b><br />
                                Sabrina Hanner, Jürgen Kandlbinder<br /><br />

                            <!-- <b>Verantwortlicher gemäß Art. 18 Abs. 2 MStV</b><br />
                            Maximilian Zängl, Lilienthalstraße 7, 93049 Regensburg<br /><br /> -->

                                Sitz Regensburg, Registergericht Regensburg, HRB 9476<br />
                                Umsatzsteueridentifikationsnummer DE366202283, Steuernummer 244/117/20620<br /><br />

                            <!-- <eon-ui-link href="https://www.bayernwerk-netz.de/de/bayernwerk-netz-gmbh/gesetzliche-rahmenbedingungen.html" text="Gesetzliche Rahmenbedingungen" target="_blank" class="hydrated" scheme="turquoise"></eon-ui-link><br />
                            (Genehmigung der leitungsgebundenen Versorgung, Verhaltenskodex ...) -->
                            </eon-ui-text>

                        </div>

                    </div>

            </eon-ui-grid-control-column>
        </eon-ui-grid-control>
    </eon-ui-section>

</template>


