<template>
  
  <div class="bw-header-section">
    <div class="bw-header-nav">
      <router-link class="bw-header-nav-link" :to="{ name: 'startseite' }">
        <div class="bw-header-nav-btn" :style="( this.$route.name === 'startseite')?activStyle:''">Übersicht</div>
      </router-link>
      <router-link class="bw-header-nav-link" :to="{ name: 'katalog', params: { detail: 'gesamt' } }">
        <div class="bw-header-nav-btn" :style="( this.$route.name === 'katalog')?activStyle:''">Unsere&nbsp;Angebote</div>
      </router-link>


      <router-link class="bw-header-nav-link" :to="{ name: 'meine_daten' }"
        v-if="isLoginValid && !isAnprechpartner" >
        <div class="bw-header-nav-btn" :style="( this.$route.name === 'meine_daten')?activStyle:''">Meine&nbsp;Daten</div>
      </router-link>
      <router-link class="bw-header-nav-link" :to="{ name: 'meine_daten_ap' }"
        v-if="isLoginValid && isAnprechpartner" >
        <div class="bw-header-nav-btn" :style="( this.$route.name === 'meine_daten_ap')?activStyle:''">Meine&nbsp;Daten</div>
      </router-link>
          
      <router-link class="bw-header-nav-link" :to="{ name: 'meine_schulungen' }"
        v-if="isLoginValid && !isAnprechpartner" >
        <div class="bw-header-nav-btn" :style="( this.$route.name === 'meine_schulungen')?activStyle:''">Meine&nbsp;Schulungen</div>
      </router-link>
      
      <router-link class="bw-header-nav-link" :to="{ name: 'meine_befaehigungen' }"
        v-if="isLoginValid && !isAnprechpartner" >
        <div class="bw-header-nav-btn" :style="( this.$route.name === 'meine_befaehigungen')?activStyle:''">Meine&nbsp;Befähigungen</div>
      </router-link>

      <router-link class="bw-header-nav-link" :to="{ name: 'meine_mitarbeiter' }"
        v-if="isLoginValid && isFuehrungskraft" >
        <div class="bw-header-nav-btn" :style="( this.$route.name === 'meine_mitarbeiter')?activStyle:''">Meine&nbsp;Mitarbeiter</div>
      </router-link>
      <router-link class="bw-header-nav-link" :to="{ name: 'meine_mitarbeiter_ap' }"
        v-if="isLoginValid && isAnprechpartner" >
        <div class="bw-header-nav-btn" :style="( this.$route.name === 'meine_mitarbeiter_ap')?activStyle:''">Meine&nbsp;Mitarbeiter</div>
      </router-link>


      <router-link class="bw-header-nav-link" :to="{ name: 'bildungspartner' }">
        <div class="bw-header-nav-btn" :style="( this.$route.name === 'bildungspartner')?activStyle:''">Bildungspartner</div>
      </router-link>

      <div class='bw-burger-menu'>

        <div class='bw-burger-menu-icon' v-on:click="setBurgerIsOpen()">
          <span id="hamburger-icon">
            <span class="line line-1" :style="styleBurgerTop"></span>
            <span class="line line-2" :style="styleBurgerCenter"></span>
            <span class="line line-3" :style="styleBurgerBottom"></span>
          </span>
        </div>

        <div class="bw-burger-backdrop" :style="styleBurgerBackdrop"  v-on:click="setBurgerIsOpen()"></div>
        
        <div id="burger-context" class='bw-burger-menu-dropdown' 
          :style="styleBurgerContext">
          
          <router-link class="bw-header-nav-link bw-burger-link" 
            :to="{ name: 'startseite' }" v-on:click="setBurgerIsOpen()">
            <div class="bw-burger-btn" :style="( this.$route.name === 'startseite')?activBurgerStyle:''">
              <p class="bw-burger-btn-content">Übersicht</p>
            </div>
          </router-link>
          <router-link class="bw-header-nav-link bw-burger-link" 
            :to="{ name: 'katalog', params: { detail: 'gesamt' } }" v-on:click="setBurgerIsOpen()">
            <div class="bw-burger-btn" :style="( this.$route.name === 'katalog')?activBurgerStyle:''">
              <p class="bw-burger-btn-content">Unsere Angebote</p>
            </div>
          </router-link>


          <router-link class="bw-header-nav-link bw-burger-link" 
            :to="{ name: 'meine_daten' }"  v-on:click="setBurgerIsOpen()"
            v-if="isLoginValid && !isAnprechpartner" >
            <div class="bw-burger-btn" :style="( this.$route.name === 'meine_daten')?activBurgerStyle:''">
              <p class="bw-burger-btn-content">Meine Daten</p>
            </div>
          </router-link>
          <router-link class="bw-header-nav-link bw-burger-link" 
            :to="{ name: 'meine_daten_ap' }"  v-on:click="setBurgerIsOpen()"
            v-if="isLoginValid && isAnprechpartner" >
            <div class="bw-burger-btn" :style="( this.$route.name === 'meine_daten_ap')?activBurgerStyle:''">
              <p class="bw-burger-btn-content">Meine Daten</p>
            </div>
          </router-link>
              
          <router-link class="bw-header-nav-link bw-burger-link" 
            :to="{ name: 'meine_schulungen' }"  v-on:click="setBurgerIsOpen()"
            v-if="isLoginValid && !isAnprechpartner" >
            <div class="bw-burger-btn" :style="( this.$route.name === 'meine_schulungen')?activBurgerStyle:''">
              <p class="bw-burger-btn-content">Meine Schulungen</p>
            </div>
          </router-link>
          
          <router-link class="bw-header-nav-link bw-burger-link" 
            :to="{ name: 'meine_befaehigungen' }"  v-on:click="setBurgerIsOpen()"
            v-if="isLoginValid && !isAnprechpartner" >
            <div class="bw-burger-btn" :style="( this.$route.name === 'meine_befaehigungen')?activBurgerStyle:''">
              <p class="bw-burger-btn-content">Meine Befähigungen</p>
            </div>
          </router-link>

          <router-link class="bw-header-nav-link bw-burger-link" 
            :to="{ name: 'meine_mitarbeiter' }"  v-on:click="setBurgerIsOpen()"
            v-if="isLoginValid && isFuehrungskraft" >
            <div class="bw-burger-btn" :style="( this.$route.name === 'meine_mitarbeiter')?activBurgerStyle:''">
              <p class="bw-burger-btn-content">Meine Mitarbeiter</p>
            </div>
          </router-link>
          <router-link class="bw-header-nav-link bw-burger-link" 
            :to="{ name: 'meine_mitarbeiter_ap' }"  v-on:click="setBurgerIsOpen()"
            v-if="isLoginValid && isAnprechpartner" >
            <div class="bw-burger-btn" :style="( this.$route.name === 'meine_mitarbeiter_ap' )?activBurgerStyle:''">
              <p class="bw-burger-btn-content">Meine Mitarbeiter</p>
            </div>
          </router-link>


          <router-link class="bw-header-nav-link bw-burger-link" 
            :to="{ name: 'bildungspartner' }"  v-on:click="setBurgerIsOpen()">
            <div class="bw-burger-btn" :style="( this.$route.name === 'bildungspartner')?activBurgerStyle:''">
              <p class="bw-burger-btn-content">Bildungspartner</p>
            </div>
          </router-link>

        </div>

      </div> 
    </div>

    <div class="bw-header-top">
      <div class="bw-header-top-grid">
        
        <div class="bw-hl">
          <router-link :to="{ name: 'startseite' }">
            <svg width="121" height="46" viewBox="0 0 121 46" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_60_65021)">
                <path d="M41.9358 18.7544C42.677 18.7544 44.0149 18.7378 45.0311 18.7378C46.3374 18.7378 46.9975 18.8677 47.5461 19.2396C48.191 19.6612 48.5623 20.4394 48.5623 21.3628C48.5623 22.6277 47.9985 23.4695 46.8861 23.8593C48.2405 24.0211 49.0628 25.2044 49.0628 26.5978C49.0628 27.5377 48.6599 28.4294 47.982 28.9159C47.3055 29.4177 46.5148 29.6126 45.0641 29.6126L41.9358 29.5808V18.7544ZM44.5966 23.5677C45.7406 23.5677 46.2412 23.4211 46.7252 22.9512C47.1281 22.5461 47.3536 21.9946 47.3536 21.4112C47.3536 20.6164 46.9988 20.1312 46.2412 19.8713C45.7736 19.7248 45.5481 19.7096 43.3879 19.7096H43.1142V23.5677H44.5979H44.5966ZM43.7894 28.6574C45.643 28.6574 46.4007 28.5759 46.8847 28.3174C47.5131 27.9939 47.8679 27.3774 47.8679 26.6323C47.8679 25.8872 47.4966 25.2223 46.9163 24.8823C46.4172 24.6072 45.9648 24.5422 44.6103 24.5422H43.1115V28.6422C43.4016 28.6588 43.6753 28.6588 43.788 28.6588L43.7894 28.6574Z" fill="#008FBF"/>
                <path d="M50.7061 27.3773C50.7061 25.7075 51.7539 24.849 53.8179 24.849C54.5439 24.849 55.043 24.914 55.93 25.1089V24.8988C55.93 23.8289 55.93 22.7106 54.141 22.7106C53.2059 22.7106 52.4799 22.9373 51.4651 23.5206L51.2066 22.6622C52.11 21.9973 53.1729 21.6572 54.4311 21.6572C55.6893 21.6572 56.5433 22.159 56.8169 23.0022C56.9462 23.4556 56.9778 23.7155 56.9778 24.6555V27.9939C56.9943 28.9988 57.0094 29.2255 57.0905 29.5987H56.0262L55.8805 28.5772C55.1874 29.3555 54.2359 29.7605 53.0918 29.7605C51.593 29.7605 50.7061 28.8689 50.7061 27.3773ZM55.8819 27.6524V26.0323C55.1242 25.8374 54.6567 25.7724 53.9952 25.7724C52.5445 25.7724 51.8996 26.2742 51.8996 27.3939C51.8996 28.3173 52.3837 28.7721 53.3668 28.7721C54.2373 28.7721 54.834 28.497 55.8819 27.6538V27.6524Z" fill="#008FBF"/>
                <path d="M58.6553 32.3854L58.8643 31.6071C59.3154 31.7854 59.5739 31.8504 59.9438 31.8504C60.8623 31.8504 61.0562 31.4288 61.6846 29.3705H61.1043L58.3157 21.834H59.5092L61.928 28.6254L64.0566 21.834H65.1691L62.5729 29.9704C62.17 31.267 62.0738 31.4937 61.8469 31.867C61.444 32.4987 60.8953 32.7904 60.106 32.7904C59.5257 32.7904 59.171 32.6936 58.6553 32.3854Z" fill="#008FBF"/>
                <path d="M65.9749 25.724C65.9749 23.1957 67.3774 21.624 69.6353 21.624C71.6182 21.624 72.8599 23.0672 72.8599 25.3674C72.8599 25.4642 72.8599 25.6273 72.8434 25.8208H67.1684C67.2495 27.7657 68.1035 28.7057 69.8127 28.7057C70.6996 28.7057 71.4257 28.5108 72.2315 28.0256L72.5381 28.8841C71.7158 29.484 70.7642 29.7922 69.6518 29.7922C67.4434 29.7922 65.9762 28.1708 65.9762 25.724H65.9749ZM71.6168 24.9624C71.5205 23.3423 70.8426 22.5308 69.5693 22.5308C68.2314 22.5308 67.3926 23.3893 67.1987 24.9624H71.6168Z" fill="#008FBF"/>
                <path d="M74.5993 21.9791L75.7117 21.7358V23.1292C76.2274 22.221 77.0978 21.7192 78.0975 21.7192C78.4687 21.7192 78.6296 21.751 78.9514 21.8976L78.7905 23.1292C78.4674 22.9343 78.2267 22.8693 77.8225 22.8693C76.8228 22.8693 76.0967 23.3711 75.7103 24.3443V29.5958H74.5979V21.9777L74.5993 21.9791Z" fill="#008FBF"/>
                <path d="M80.1781 22.0124L81.2741 21.7691V23.2758C82.2572 22.1893 83.2569 21.7041 84.4505 21.7041C85.3044 21.7041 85.9988 22.0442 86.3522 22.6607C86.5942 23.0989 86.6589 23.5357 86.6589 24.7839V29.5972H85.5299V24.7508C85.5299 23.1943 85.2233 22.7574 84.159 22.7574C83.0947 22.7574 82.1926 23.309 81.2727 24.4107V29.5972H80.1768V22.0124H80.1781Z" fill="#008FBF"/>
                <path d="M88.1589 21.834H89.3841L91.158 28.107L93.2866 21.834H94.4155L96.5593 28.107L98.3166 21.834H99.4456L97.0749 29.5972H96.0106L93.7858 23.1306L91.5774 29.5972H90.5461L88.1603 21.834H88.1589Z" fill="#008FBF"/>
                <path d="M100.364 25.724C100.364 23.1957 101.767 21.624 104.025 21.624C106.008 21.624 107.249 23.0672 107.249 25.3674C107.249 25.4642 107.249 25.6273 107.233 25.8208H101.558C101.639 27.7657 102.493 28.7057 104.202 28.7057C105.089 28.7057 105.815 28.5108 106.621 28.0256L106.928 28.8841C106.105 29.484 105.154 29.7922 104.041 29.7922C101.833 29.7922 100.366 28.1708 100.366 25.724H100.364ZM106.006 24.9624C105.91 23.3423 105.232 22.5308 103.959 22.5308C102.621 22.5308 101.782 23.3893 101.588 24.9624H106.006Z" fill="#008FBF"/>
                <path d="M108.989 21.9791L110.101 21.7358V23.1292C110.617 22.221 111.487 21.7192 112.487 21.7192C112.858 21.7192 113.019 21.751 113.341 21.8976L113.18 23.1292C112.858 22.9343 112.616 22.8693 112.212 22.8693C111.212 22.8693 110.486 23.3711 110.1 24.3443V29.5958H108.987V21.9777L108.989 21.9791Z" fill="#008FBF"/>
                <path d="M114.615 18.5109L115.711 18.2842V25.1406H115.841L119.372 21.834H120.839L116.97 25.4488L121 29.5986H119.452L115.841 25.8221H115.711V29.5986H114.615V18.5123V18.5109Z" fill="#008FBF"/>
                <path d="M44.3215 34.9619H45.7571L49.4808 45.805H48.2075L47.0635 42.45H43.0001L41.9358 45.805H40.6941L44.3215 34.9619ZM46.7568 41.4133L45.016 36.0484L43.2902 41.4133H46.7568Z" fill="#006081"/>
                <path d="M50.8833 34.7189L51.9792 34.4922V41.3486H52.1085L55.6397 38.042H57.1069L53.2374 41.6568L57.2678 45.8066H55.7194L52.1085 42.0301H51.9792V45.8066H50.8833V34.7203V34.7189Z" fill="#006081"/>
                <path d="M57.7834 43.5848C57.7834 41.915 58.8313 41.0566 60.8952 41.0566C61.6213 41.0566 62.1204 41.1215 63.0074 41.3164V41.1063C63.0074 40.0364 63.0074 38.9181 61.2184 38.9181C60.2833 38.9181 59.5573 39.1448 58.5425 39.7281L58.284 38.8697C59.1874 38.2048 60.2503 37.8647 61.5085 37.8647C62.7667 37.8647 63.6207 38.3665 63.8943 39.2098C64.0236 39.6632 64.0552 39.923 64.0552 40.863V44.2014C64.0717 45.2063 64.0868 45.433 64.1679 45.8063H63.1036L62.9592 44.7847C62.2662 45.563 61.3146 45.968 60.1706 45.968C58.6717 45.968 57.7848 45.0764 57.7848 43.5848H57.7834ZM62.9592 43.8599V42.2398C62.2016 42.0449 61.734 41.98 61.0726 41.98C59.6219 41.98 58.977 42.4817 58.977 43.6014C58.977 44.5248 59.461 44.9796 60.4442 44.9796C61.3146 44.9796 61.9114 44.7045 62.9592 43.8613V43.8599Z" fill="#006081"/>
                <path d="M65.9102 42.012C65.9102 39.5155 67.2962 37.8622 69.3767 37.8622C70.3599 37.8622 70.9402 38.1539 71.6827 39.0621V34.734L72.8117 34.4907V44.0206C72.8117 44.7338 72.8598 45.3987 72.9409 45.8038H71.8766L71.6992 44.6855C71.0062 45.5936 70.2155 45.9821 69.1196 45.9821C67.1849 45.9821 65.9115 44.3938 65.9115 42.0106L65.9102 42.012ZM71.6814 43.5685V40.3753C71.1176 39.4187 70.3434 38.917 69.3919 38.917C67.9576 38.917 67.0858 40.0519 67.0858 41.9318C67.0858 43.8118 67.9081 44.9633 69.3107 44.9633C70.2939 44.9633 71.0516 44.5251 71.6814 43.5699V43.5685Z" fill="#006081"/>
                <path d="M74.6323 41.932C74.6323 39.4037 76.0349 37.832 78.2928 37.832C80.2756 37.832 81.5173 39.2752 81.5173 41.5754C81.5173 41.6722 81.5173 41.8353 81.5008 42.0288H75.8259C75.907 43.9738 76.7609 44.9137 78.4702 44.9137C79.3571 44.9137 80.0831 44.7188 80.8889 44.2336L81.1956 45.0921C80.3733 45.692 79.4217 46.0003 78.3093 46.0003C76.1009 46.0003 74.6337 44.3788 74.6337 41.932H74.6323ZM80.2756 41.1704C80.1794 39.5503 79.5015 38.7388 78.2282 38.7388C76.8902 38.7388 76.0514 39.5973 75.8575 41.1704H80.2756Z" fill="#006081"/>
                <path d="M83.2898 38.2204L84.3857 37.9772V39.3553C85.45 38.302 86.304 37.897 87.4013 37.897C88.4986 37.897 88.9812 38.367 89.1421 39.4037C89.9809 38.4153 91.0604 37.897 92.1412 37.897C92.9635 37.897 93.6084 38.237 93.915 38.8204C94.124 39.2088 94.1887 39.6152 94.1887 40.8787V45.8053H93.0597V40.8621C93.0597 39.7756 92.9786 38.9171 91.8992 38.9171C90.883 38.9171 89.9328 39.5171 89.2879 40.5386V45.8067H88.1754V40.9118C88.1754 39.3553 87.9334 38.9185 87.063 38.9185C86.0152 38.9185 84.9839 39.5502 84.3871 40.54V45.8081H83.2912V38.2232L83.2898 38.2204Z" fill="#006081"/>
                <path d="M96.4136 35.8052C96.4136 35.3186 96.7683 34.9951 97.317 34.9951C97.8326 34.9951 98.1874 35.3352 98.1874 35.8052C98.1874 36.2752 97.8326 36.6318 97.3005 36.6318C96.7683 36.6318 96.4136 36.2917 96.4136 35.8052ZM96.7367 38.1869L97.8657 37.9436V45.805H96.7367V38.1869Z" fill="#006081"/>
                <path d="M99.9448 41.932C99.9448 39.4037 101.347 37.832 103.605 37.832C105.588 37.832 106.83 39.2752 106.83 41.5754C106.83 41.6722 106.83 41.8353 106.813 42.0288H101.138C101.22 43.9738 102.073 44.9137 103.783 44.9137C104.67 44.9137 105.396 44.7188 106.201 44.2336L106.508 45.0921C105.686 45.692 104.734 46.0003 103.622 46.0003C101.413 46.0003 99.9462 44.3788 99.9462 41.932H99.9448ZM105.587 41.1704C105.491 39.5503 104.813 38.7388 103.539 38.7388C102.201 38.7388 101.363 39.5973 101.169 41.1704H105.587Z" fill="#006081"/>
                <path d="M2.83803 13.1986C3.22307 13.1986 3.5352 12.8848 3.5352 12.4977C3.5352 12.1107 3.22307 11.7969 2.83803 11.7969C2.453 11.7969 2.14087 12.1107 2.14087 12.4977C2.14087 12.8848 2.453 13.1986 2.83803 13.1986Z" fill="#FFD517"/>
                <path d="M35.2652 30.2608C35.184 28.2965 34.7619 26.306 33.9465 24.5117C33.2094 22.8916 32.1602 21.6129 30.7742 20.5057C27.7971 18.1253 23.5866 17.2503 20.0953 17.8157C18.7959 18.0244 17.5995 18.3299 16.3881 18.84V14.8989C15.6194 15.2556 14.7531 15.5389 13.7864 15.7076C12.7221 15.8942 11.7403 15.9191 10.8479 15.8334C10.8479 23.6367 10.8479 34.8668 10.8493 36.5574C10.8493 38.4111 10.8617 40.5648 11.4364 42.2028C11.9383 43.6405 13.246 44.4837 14.6335 44.9412C15.9756 45.3781 17.4029 45.5218 18.8082 45.5688C19.6993 45.5937 20.5958 45.6061 21.491 45.6103C22.3491 45.6117 23.3199 45.5854 24.1683 45.5246C25.7689 45.4098 27.2457 45.1776 28.7143 44.4878C30.0289 43.8699 31.1867 42.9576 32.119 41.8407C34.0565 39.5239 34.9915 36.491 35.25 33.5121C35.3449 32.4283 35.3422 31.3473 35.2665 30.2636L35.2652 30.2608ZM29.9422 31.9196C29.7924 35.8316 28.7322 39.6773 24.0638 40.027C23.0586 40.1003 20.2534 40.3173 18.1207 39.8999C17.1911 39.7188 16.3867 38.8714 16.384 37.1808C16.384 36.7025 16.3867 25.1365 16.3867 25.1365C17.1169 24.5656 17.9268 24.1025 18.7752 23.721C21.8774 22.3221 26.4963 22.8916 28.6112 25.8899C29.8144 27.5957 30.0206 29.9346 29.9422 31.9182V31.9196Z" fill="#008FBF"/>
                <path d="M18.804 5.93164L12.8458 9.63215C12.6712 9.73583 12.4649 9.77039 12.2669 9.72892L5.33103 8.19176H5.3269L6.05845 12.2614C6.23996 13.2442 9.03824 15.6356 13.5375 14.8975C17.7961 14.1994 19.801 11.0864 19.5851 9.91968C19.5328 9.63768 18.804 5.93579 18.8027 5.93164H18.804Z" fill="#008FBF"/>
                <path d="M11.3553 0.0137558C11.1793 -0.0249496 10.9098 0.0206675 10.7558 0.117431L1.10546 6.20939C0.952826 6.30615 0.972077 6.41674 1.14809 6.45682L12.2642 8.9395C12.4402 8.97959 12.7097 8.93121 12.8624 8.83444L22.4673 2.73005C22.6199 2.63328 22.6007 2.52131 22.4247 2.48123L11.3553 0.0137558Z" fill="#008FBF"/>
                <path d="M2.83798 11.0599C3.15424 11.0599 3.44714 11.1635 3.68365 11.3391V7.79894L1.9978 7.44092V11.335C2.23294 11.1622 2.52308 11.0599 2.83798 11.0599Z" fill="#008FBF"/>
                <path d="M3.6839 16.8951V13.6562C3.44738 13.8318 3.15449 13.9355 2.83822 13.9355C2.52195 13.9355 2.22906 13.8318 1.99255 13.6562V16.8951C1.99255 16.8951 -2.15881 19.1206 1.5264 22.0622V19.169H3.97816V22.0622C8.05802 19.0156 3.68527 16.8951 3.68527 16.8951H3.6839Z" fill="#008FBF"/>
              </g>
              <defs>
                <clipPath id="clip0_60_65021">
                  <rect width="121" height="46" fill="white"/>
                </clipPath>
              </defs>
            </svg>
          </router-link>
        </div>
        
        <div class="header-login bw-hr">

        <div v-if="this.$store.state.name_gesamt === ''" class="bw-login-box">
          <router-link class="bw-login-link" :to="{ name: 'login' }">
            <div>
              <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M7.0029 0.496094C5.14571 0.496094 3.36459 1.23398 2.05136 2.54743C0.738132 3.86089 0.000366211 5.64231 0.000366211 7.49981C0.000366211 9.35731 0.738132 11.1387 2.05136 12.4522C3.36459 13.7656 5.14571 14.5035 7.0029 14.5035C8.86009 14.5035 10.6412 13.7656 11.9544 12.4522C13.2677 11.1387 14.0054 9.35731 14.0054 7.49981C14.0054 5.64231 13.2677 3.86089 11.9544 2.54743C10.6412 1.23398 8.86009 0.496094 7.0029 0.496094ZM7.0029 3.49767C7.26565 3.49767 7.52582 3.54943 7.76856 3.64999C8.0113 3.75056 8.23186 3.89796 8.41765 4.08377C8.60344 4.26959 8.75081 4.49019 8.85136 4.73298C8.95191 4.97576 9.00366 5.23597 9.00366 5.49876C9.00366 5.76155 8.95191 6.02176 8.85136 6.26455C8.75081 6.50733 8.60344 6.72793 8.41765 6.91375C8.23186 7.09957 8.0113 7.24696 7.76856 7.34753C7.52582 7.44809 7.26565 7.49985 7.0029 7.49985C6.47227 7.49985 5.96337 7.28902 5.58815 6.91375C5.21294 6.53847 5.00215 6.02948 5.00215 5.49876C5.00215 4.96804 5.21294 4.45905 5.58815 4.08377C5.96337 3.7085 6.47227 3.49767 7.0029 3.49767ZM10.1441 11.3218C9.27436 12.0825 8.15826 12.5017 7.00292 12.5017C5.84759 12.5017 4.73149 12.0825 3.86178 11.3218C3.81595 11.2751 3.79027 11.2122 3.79027 11.1467C3.79027 11.0813 3.81595 11.0184 3.86178 10.9716C4.44788 10.2766 5.24633 9.79366 6.134 9.59739C7.02167 9.40113 7.94925 9.50241 8.77367 9.88563C9.32053 10.1399 9.80529 10.5105 10.194 10.9716C10.2338 11.0247 10.2509 11.0914 10.2416 11.1571C10.2322 11.2228 10.1971 11.282 10.1441 11.3218Z" fill="#0091BB"/>
              </svg>
            </div>
            <div>&nbsp;Anmelden</div>
          </router-link>
        </div>
        <div v-else class="bw-login-box">
          <eon-ui-navigation-toolbar-icon-link :text="this.$store.state.name_gesamt" icon="chevron_small_down" class="hydrated bw-loged-in">
            <eon-ui-context-menu :label="this.$store.state.name_gesamt" scheme="white" size="normal" icon-name="chevron_small_down" icon-with-label="" slot="context-menu" menu-offset-x="8" class="eonui-position-left hydrated" >
              <eon-ui-context-menu-item value="value-2" label="Logout" class="hydrated" v-on:click="logoutUser"></eon-ui-context-menu-item>
            </eon-ui-context-menu>
          </eon-ui-navigation-toolbar-icon-link>
        </div>

        </div>

      </div>
    </div>
  </div>
  
</template>

<script>
import _ from "lodash";

export default {
  name: "HeaderVue",
  data() {
    return {
      name_gesamt: this.$store.state.name_gesamt,
      loading: false,
      hasLogin: false,

      burgerIsOpen: false,
      styleBurgerTop: "",
      styleBurgerCenter: "opacity: 1;",
      styleBurgerBottom: "",
      styleBurgerContext: "left: -350px",
      activStyle: "border-bottom: 4px solid #0091bb;",
      activBurgerStyle: "border-bottom: 2px solid #0091bb;",
      styleBurgerBackdrop: "opacity: 1; display: none;",

      isAnprechpartner: this.$store.state.ansprechpartner == 1,
      isFuehrungskraft: this.$store.state.fuehrungskraft == 1,
      isLoginValid: (
        this.$store.state.token != undefined &&
        this.$store.state.token != null &&
        this.$store.state.token != "" &&
        this.$store.state.Tetra_id != undefined &&
        this.$store.state.Tetra_id != null &&
        this.$store.state.Tetra_id != ""
      )
    };
  },
  mounted(){
    // console.log("reload")
  },
  methods: {
    logoutUser() {
      this.$store.dispatch("user/logout");
      this.hasLogin = false;
      this.$router.go({ name: "startseite" });
    },
    setBurgerIsOpen() {
      this.burgerIsOpen = !this.burgerIsOpen;
      this.styleBurgerTop = (this.burgerIsOpen)?
        "transform:translateY(8px) translateX(0px) rotate(45deg);" +
        "WebkitTransform:translateY(8px) translateX(0px) rotate(45deg);" +
        "MozTransform:translateY(8px) translateX(0px) rotate(45deg)" :
        "";
      this.styleBurgerCenter = (this.burgerIsOpen)?
        "opacity: 0;" :
        "opacity: 1;";
      this.styleBurgerBottom = (this.burgerIsOpen)?
        "transform:translateY(-8px) translateX(0px) rotate(-45deg);" +
        "WebkitTransform:translateY(-8px) translateX(0px) rotate(-45deg)" +
        "MozTransform:translateY(-8px) translateX(0px) rotate(-45deg)" :
        "";
      this.styleBurgerContext = (this.burgerIsOpen)?
        "left: 0px" : "left: -303px";
      this.styleBurgerBackdrop = (this.burgerIsOpen)?
        "opacity: 1; display: unset;" :
        "opacity: 0; display: none;";
    }
  },
};
</script>
