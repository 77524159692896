<template>

    <eon-ui-section class="hydrated">

        <!-- <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Übersicht</a>
                <a href="/teilnahmebedingungen">Teilnahmebedingungen</a>
            </eon-ui-breadcrumb>
        </div> -->

        <eon-ui-headline text="Allgemeine Teilnahmebedingungen" class="hydrated text-blue mb-6" size="h4"></eon-ui-headline>
        <div class="mt-3 mb-3">
            <eon-ui-headline text="des Technischen Trainingscenters (TTC) der Bayernwerk Netz GmbH" class="hydrated text-blue" size="h7"></eon-ui-headline>
        </div>
        <div class="mt-2 mb-6">
            <eon-ui-text class="" size="small" text-style="copy">
                Im Besonderen gelten die ggf. weiteren Bedingungen der einzelnen Schulungen in der Anmeldung bzw. Anmeldebestätigung. Darin genannte
                Hinweise sind zwingend zu beachten.<br />
                Hinweis zum Datenschutz: Die Datenschutzerklärung erhalten Sie mit den Anmeldeunterlagen.
            </eon-ui-text>
        </div>

        <div class="mt-6 mb-3">
            <eon-ui-headline text="Anmeldung der Teilnehmer" class="hydrated text-blue" size="h7"></eon-ui-headline>
            <!-- <eon-ui-text class="hydrated text-blue" text-style="rtf">Anmeldung der Teilnehmer</eon-ui-text> -->
        </div>
        <div>
            <eon-ui-text class="" size="small" text-style="copy">
                Sie erhalten vom TTC für die gewünschte bzw. benötigte Schulung die entsprechenden Anmeldeunterlagen. Die Schulungsanmeldung hat in
                schriftlicher Form zu erfolgen (als E-Mail-Anlage, per Fax / Post). Mit Zusendung der erforderlichen und unterzeichneten
                Anmeldeunterlagen melden Sie Ihre genannten Mitarbeiter zu der / den entsprechenden Schulung(en) verbindlich an. Ihre in der / den
                unterzeichneten Anmeldeunterlage(n) genannten Mitarbeiter werden in die Voranmeldungsliste unserer Schulungsdatenbank aufgenommen
                und in die nächstmögliche(n) Schulung(en) eingebucht bzw. bei bereits angelegten Schulungen direkt eingebucht.
            </eon-ui-text>
        </div>

        <div class="mt-6 mb-3">
            <eon-ui-headline text="Anmeldebestätigung für Ihre Teilnehmer" class="hydrated text-blue" size="h7"></eon-ui-headline>
            <!-- <eon-ui-text class="hydrated text-blue" text-style="rtf">Anmeldebestätigung für Ihre Teilnehmer</eon-ui-text> -->
        </div>
        <div>
            <eon-ui-text class="" size="small" text-style="copy">
                Sie erhalten zeitnah nach Einbuchung der angemeldeten Teilnehmer in die entsprechende/n Schulung(en) eine verbindliche
                Anmeldebestätigung. Eine weitere Bestätigung unserer- oder Ihrerseits ist nicht erforderlich. Bitte informieren Sie Ihre in der
                Anmeldebestätigung gelisteten Mitarbeiter rechtzeitig über die darin genannten Einzelheiten, wie z. B. Schulungstermin(e),
                Schulungsort und die zur Schulung erforderliche Ausrüstung.
            </eon-ui-text>
        </div>

        <div class="mt-6 mb-3">
            <eon-ui-headline text="Anmeldungsänderung / Abmeldung / Nicht-Teilnahme / Nicht-Zulassung Ihrer angemeldeten Schulungsteilnehmer" class="hydrated text-blue" size="h7"></eon-ui-headline>
            <!-- <eon-ui-text class="hydrated text-blue" text-style="rtf">Anmeldungsänderung / Abmeldung / Nicht-Teilnahme / Nicht-Zulassung Ihrer
            angemeldeten Schulungsteilnehmer</eon-ui-text> -->
        </div>
        <div>
            <eon-ui-text class="" size="small" text-style="copy">
                Die Abmeldung der angemeldeten Schulungsteilnehmer (unabhängig davon, ob Sie bereits eine Anmeldebestätigung erhalten haben oder
                nicht) hat unmittelbar nach Kenntnisnahme der nicht möglichen / gewünschten Teilnahme, jedoch spätestens eine Woche vor
                Schulungsbeginn, in schriftlicher Form zu erfolgen.<br /><br />
                Gleiches gilt bei einer Anmeldungsänderung, wenn z. B. ein angemeldeter Mitarbeiter den genannten Schulungstermin nicht
                wahrnehmen kann und daher einen Ersatztermin benötigt.<br /><br />
                <b>Bei nicht rechtzeitiger Mitteilung zur Änderung der Anmeldung bzw. Abmeldung sowie bei verschuldetem oder unverschuldetem
                Fehlen an der Schulung müssen wir leider 75 % der Schulungsgebühren in Rechnung stellen. Die Kosten fallen nicht an, wenn Sie uns
                einen Ersatzteilnehmer schriftlich benennen oder es uns gelingt, den freigewordenen Schulungsplatz anderweitig zu
                belegen.</b><br /><br />
                Ein eventuelles Anreiserisiko (z. B. verursacht durch Fahrzeugpanne, Stau, Wetterbedingungen oder Bahnstreik) trägt der
                Auftraggeber (Firma / Bereich des Teilnehmers). Bei nicht rechtzeitiger Anwesenheit zu Schulungsbeginn besteht daher kein
                Anspruch auf Ersatzleistungen oder Reduzierung der Schulungskosten.<br /><br />
                Wir behalten uns vor, zu spät eintreffende Teilnehmer, die dadurch am ggf. notwendigen Theorieteil der Schulung nicht in
                ausreichendem Umfang teilnehmen konnten sowie Teilnehmer, die die ggf. erforderliche Voraussetzung „Theorietest bestanden“ nicht
                erfüllt haben oder Teilnehmer mit Mängel bei der erforderlichen Ausrüstung, zur anschließenden Praxis bzw. zum restlichen Verlauf
                der Schulung nicht mehr zuzulassen (in den Fällen werden die Schulungsgebühren in voller Höhe in Rechnung gestellt).<br /><br />
                Wir behalten uns außerdem vor, Schulungen auch kurzfristig aufgrund zu geringer Teilnehmerzahl oder aus anderweitigen Gründen,
                die wir nicht zu vertreten haben, abzusagen.
            </eon-ui-text>
        </div>

        <div class="mt-6 mb-3">
            <eon-ui-headline text="Schulungspreise / Rechnungsstellung" class="hydrated text-blue" size="h7"></eon-ui-headline>
            <!-- <eon-ui-text class="hydrated text-blue" text-style="rtf">Schulungspreise / Rechnungsstellung</eon-ui-text> -->
        </div>
        <div>
            <eon-ui-text class="" size="small" text-style="copy">
                Die Schulungspreise verstehen sich je Teilnehmer und wie angegeben pro Tag, pro Schulung oder nach Anzahl der Tätigkeiten,
                zzgl. aktuell geltender gesetzlichen Umsatzsteuer. Es gilt der jeweils zum Zeitpunkt der Schulung aktuelle Schulungspreis, der von
                dem in der Anmeldung genannten Schulungspreis abweichen kann (ggf. mussten im Zeitraum zwischen Anmeldung und Durchführung der
                Schulung die Schulungspreise aktualisiert werden – z. B. aufgrund einer notwendig gewordenen Nachkalkulation bei erforderlicher
                Aufwandsänderung der Schulung oder es erfolgte eine Preisanpassung mit Beginn eines neuen Schulungsjahres). Die Rechnungsstellung
                erfolgt nach der Schulung.
            </eon-ui-text>
        </div>

    </eon-ui-section>

</template>
