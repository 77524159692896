import { createApp } from "vue";
import _ from 'lodash';
import router from "./router";
import store from "./store";
import App from "./App.vue";
import {
  applyPolyfills,
  defineCustomElements,
} from "@eon-ui/eon-ui-components/loader";
// E.ON UI StyleSheet - contains CSS variable definitions
import "@eon-ui/eon-ui-components/dist/theme.json";

import "./css/app.css";
import "./css/custom.css";
import "./css/css-variables.css";
import { createI18n } from 'vue-i18n';
import de from './locales/de.json';
import en from './locales/en.json';

import "./css/custom-header.css";

const app = createApp(App);

const i18n = createI18n({
  locale: 'de',
  fallbackLocale: 'de',
  messages: {
     en, de
  }
})

app.config.productionTip = false;
// app.config.ignoredElements = [/eon-ui-\w*/];
app.config.compilerOptions.isCustomElement = (tag) => {
  return tag.startsWith('eon-ui-')
}
applyPolyfills().then(() => {
  defineCustomElements(window, {
    ce: (eventName,opts) => {
      // console.log('evt name', eventName, opts);
      if(['sortChanged','formSubmit','pageIncreased','pageDecreased','pageSizeChanged','searchTermChanged'].includes(eventName)
      || eventName == 'valueChanged' && _.get(opts,'detail', null) != true && _.get(opts,'detail', null) != false){
        return new CustomEvent(eventName.toLowerCase(), opts);
      }
      else{
        return new CustomEvent(eventName, opts);
      }
    }
  })
});
app.use(store);
app.use(router);
app.use(i18n);

app.mount("#app");
