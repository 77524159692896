import { createStore } from 'vuex'
import user from './modules/user';

const store = createStore({
  namespaced: true,
  modules: {
    user
  },
});

export default store;
