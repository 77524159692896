<template>

    <eon-ui-section class="hydrated">
        <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">

                <eon-ui-headline text="Trainingscenter" class="hydrated text-blue" size="h4"></eon-ui-headline>

                <eon-ui-section>
                    <div class="mb-6"><eon-ui-headline text="Bamberg" class="hydrated text-blue" size="h7"></eon-ui-headline></div>
                    <eon-ui-grid-control grid="grid__d8-4__t8-4">
                        <eon-ui-grid-control-column slot="column-1">
                            
                
                            <eon-ui-text class="" size="small" text-style="copy"> 
                                Unser Standort in Bamberg bietet einen
                                hellen Schulungsraum, der zur Vermittlung
                                von theoretischen Inhalten genutzt wird.
                                In den angrenzenden Schulungshallen
                                können die Teilnehmer anhand verschiedener
                                technischer Übungsobjekte praktische
                                Kenntnisse und Fähigkeiten erlangen.
                                Im Technischen Trainingscenter Bamberg
                                werden verschiedene Schulungen im Bereich
                                Strom angeboten, wie beispielsweise 
                                Montagequalifikation Kabeltechnik –
                                Niederspannung oder Schaltberechtigung
                                im Niederspannungsnetz. Die Besonderheit 
                                in Bamberg ist die Übungsanlage zur
                                Fehlersuche im Straßenbeleuchtungsnetz.
                                                            <!-- <eon-ui-link href="https://www.eon.com" text="zum Trainingszentrum" target="_blank"></eon-ui-link> -->
                            </eon-ui-text>
                        </eon-ui-grid-control-column>

                        <eon-ui-grid-control-column slot="column-2">
                            <eon-ui-image lazy src="../images/ttc_standorte/Bamberg/DSC_9189_1200.webp" src-desktop="../images/ttc_standorte/Bamberg/DSC_9189_1300.webp" src-mobile="../images/ttc_standorte/Bamberg/DSC_9189_400.webp"
                                alt="Technisches Trainingscenter der Bayernwerk Netz GmbH"
                                title="Technisches Trainingscenter der Bayernwerk Netz GmbH" aspect-ratio="ratio-16x9" height="auto" width="100%" class="mb-6">
                            </eon-ui-image>
                        </eon-ui-grid-control-column>
                    </eon-ui-grid-control>
                </eon-ui-section>




                <eon-ui-section>
                    <div class="mb-6"><eon-ui-headline text="Pfaffenhofen an der Ilm" class="hydrated text-blue" size="h7"></eon-ui-headline></div>
                    <eon-ui-grid-control grid="grid__d4-8__t4-8">
                        <eon-ui-grid-control-column slot="column-2">
                            
                
                            <eon-ui-text class="" size="small" text-style="copy"> 
                                Am Hauptstandort des Technischen Trainingscenters 
                                in Pfaffenhofen an der Ilm
                                betreibt die Bayernwerk Netz GmbH ein
                                modernes Schulungscenter für verschiedene 
                                technische Schulungen. Hier haben
                                Sie die Möglichkeit unter Anleitung und
                                Aufsicht Ihre Mit arbeiter in verschiedenen
                                Bereichen qualifizieren und weiterbilden
                                zu lassen. Im Schulungscenter sind speziell 
                               ausgestattete Schulungshallen für die
                                Bereiche Arbeiten unter Spannung, Kabelmontage 
                                und Schalterwartung. Im Besonderen 
                               können die Schulungen an reellen
                                Schaltübungsnetzen der Nieder- und
                                Mittelspannung durchgeführt werden.

                                
                            </eon-ui-text>
                        </eon-ui-grid-control-column>

                        <eon-ui-grid-control-column slot="column-1">
                            <eon-ui-image lazy src="../images/ttc_standorte/Pfaffenhofen/Pfaffenhofen_Drohne_zugeschnitten.JPG" src-desktop="../images/ttc_standorte/Pfaffenhofen/Pfaffenhofen_Drohne_zugeschnitten.JPG" src-mobile="../images/ttc_standorte/Pfaffenhofen/Pfaffenhofen_Drohne_zugeschnitten.JPG"
                                alt="Technisches Trainingscenter der Bayernwerk Netz GmbH"
                                title="Technisches Trainingscenter der Bayernwerk Netz GmbH" aspect-ratio="ratio-16x9" height="auto" width="100%" class="mb-6">
                            </eon-ui-image>
                        </eon-ui-grid-control-column>
                    </eon-ui-grid-control>
                </eon-ui-section>

 




                <eon-ui-section>
                    <div class="mb-6"><eon-ui-headline text="Bayreuth" class="hydrated text-blue" size="h7"></eon-ui-headline></div>
                    <eon-ui-grid-control grid="grid__d8-4__t8-4">
                        <eon-ui-grid-control-column slot="column-1">
                            
                
                            <eon-ui-text class="" size="small" text-style="copy"> 
                                Unser Standort in Bayreuth ist zugleich
                                ein Ausbildungszentrum. Aus diesem
                                Grund sind neben Theorieräumen umfangreiche 
                                praktische Schulungsmöglichkeiten 
                                vorhanden. Im Speziellen können
                                Schulungen im Bereich Gas an unseren
                                Gasschulungswänden durchgeführt
                                werden. Des Weiteren befinden sich im
                                Außenbereich des Standorts Freileitungen
                                im Bereich der Nieder-und Mittelspannung 
                                für Schulungszwecke. Diese Freileitungsschulungen 
                                sind auch unter
                                Spannung durchführbar

                             
                            </eon-ui-text>
                        </eon-ui-grid-control-column>

                        <eon-ui-grid-control-column slot="column-2">
                            <eon-ui-image lazy src="../images/ttc_standorte/Bayreuth/DSC_9869_1200.webp" src-desktop="../images/ttc_standorte/Bayreuth/DSC_9869_1200.webp" src-mobile="../images/ttc_standorte/Bayreuth/DSC_9869_1200.webp"
                                alt="Technisches Trainingscenter der Bayernwerk Netz GmbH"
                                title="Technisches Trainingscenter der Bayernwerk Netz GmbH" aspect-ratio="ratio-16x9" height="auto" width="100%" class="mb-6">
                            </eon-ui-image>
                        </eon-ui-grid-control-column>
                    </eon-ui-grid-control>
                </eon-ui-section>



                <eon-ui-section>
                    <div class="mb-6"><eon-ui-headline text="Schwandorf" class="hydrated text-blue" size="h7"></eon-ui-headline></div>
                    <eon-ui-grid-control grid="grid__d4-8__t4-8">
                        <eon-ui-grid-control-column slot="column-2">
                            
                
                            <eon-ui-text class="" size="small" text-style="copy"> 
                                Unser modernes, in Schwandorf gelegenes, 
                               Schulungszentrum bietet Ihnen
                                attraktive Tagungsräumlichkeiten und
                                moderne Medientechnik

                               
                            </eon-ui-text>
                        </eon-ui-grid-control-column>

                        <eon-ui-grid-control-column slot="column-1">
                            <eon-ui-image lazy src="../images/ttc_standorte/Schwandorf/P1000639.JPG" src-desktop="../images/ttc_standorte/Schwandorf/P1000639.JPG" src-mobile="../images/ttc_standorte/Schwandorf/P1000639.JPG"
                                alt="Technisches Trainingscenter der Bayernwerk Netz GmbH"
                                title="Technisches Trainingscenter der Bayernwerk Netz GmbH" aspect-ratio="ratio-16x9" height="auto" width="100%" class="mb-6">
                            </eon-ui-image>
                        </eon-ui-grid-control-column>
                    </eon-ui-grid-control>
                </eon-ui-section>


     

                <eon-ui-section>
                    <div class="mb-6"><eon-ui-headline text="Schulungen vor Ort" class="hydrated text-blue" size="h7"></eon-ui-headline></div>
                    <eon-ui-grid-control grid="grid__d8-4__t8-4">
                        <eon-ui-grid-control-column slot="column-1">
                            
                
                            <eon-ui-text class="" size="small" text-style="copy"> 
                              <p> <b> Kundencenter vor Ort </b></p>
                        
                                Unsere Schulungen finden nicht nur an den
                                Standorten des Technischen Trainingscenters
                                statt, sondern können ggf.auch regional an
                                den 19 Kundencentern der Bayernwerk Netz
                                GmbH durchgeführt werden.

                            <p> <b> Weitere Standorte (nach Bedarf)</b></p>  
                                Einige Schulungen werden auch an weiteren
                                Standorten durchgeführt, wie z.B. Niederspannungs-Schaltschulungen
                                (Ponholz) oder
                                Schulungen zur Erdungsmesstechnik.

                               
                            </eon-ui-text>
                        </eon-ui-grid-control-column>

                        <eon-ui-grid-control-column slot="column-2">
                            <eon-ui-image lazy src="../images/ttc_standorte/Regensburg/Regensburg_400.webp" src-desktop="../images/ttc_standorte/Regensburg/Regensburg_400.webp" src-mobile="../images/ttc_standorte/Regensburg/Regensburg_400.webp"
                                alt="Technisches Trainingscenter der Bayernwerk Netz GmbH"
                                title="Technisches Trainingscenter der Bayernwerk Netz GmbH" aspect-ratio="ratio-16x9" height="auto" width="100%" class="mb-6">
                            </eon-ui-image>
                        </eon-ui-grid-control-column>
                    </eon-ui-grid-control>
                </eon-ui-section>





                  
            </eon-ui-grid-control-column>
        </eon-ui-grid-control>
    </eon-ui-section>

</template>
