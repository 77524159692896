<template>
    <eon-ui-error-message style="" icon-name="radio_checkbox_tick" :is-visible="message_visible"
        inner-background="eon-bag-green" class="hydrated" fixed-position="true">
        <eon-ui-headline slot="headline" size="h5" :text="text_error" class="hydrated"></eon-ui-headline>
    </eon-ui-error-message>

    <eon-ui-error-message style="" icon-name="radio_checkbox_tick" :is-visible="message_visible_success"
        inner-background="eon-bag-green" class="hydrated custom-message-success" fixed-position="true" close-timer="5">
        <eon-ui-headline slot="headline" size="h5" :text="text_success" class="hydrated"></eon-ui-headline>
    </eon-ui-error-message>

    <eon-ui-section class="hydrated">
        <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">
                <eon-ui-headline size="h4" :text="dataSeminar.Bezeichnung" class="hydrated text-black"
                    style="margin-bottom: 0px">
                </eon-ui-headline>
                <eon-ui-text class="" size="small" text-style="copy-small">
                    {{ dataSeminar.Datum }} - {{ dataSeminar.Endedatum }}
                    {{dataSeminar.Zeit ? dataSeminar.Zeit + " - " + dataSeminar.Zeit_ende + " Uhr" : "" }}
                </eon-ui-text>

                <eon-ui-grid-control v-if="list_ansprechpartner.length" grid="grid__d12__t12" remove-bottom-spacings>
                    <eon-ui-grid-control-column slot="column-1" class="hydrated">
                        <eon-ui-headline size="h5" text="Ansprechpartner" class="hydrated text-black"
                            style="margin-bottom: 10px">
                        </eon-ui-headline>
                        <eon-ui-data-table size="medium" selectable-rows="false" expandable-rows="false"
                            paging="true" page-sizes="10,20,50,100"
                            class="hydrated table-custom" @selectionchanged="selectionChangedAnsprechpartner">
                            <eon-ui-table-toolbar slot="table-toolbar" class="hydrated">
                                <eon-ui-table-toolbar-selected slot="toolbar-selected" inner-background="eon-turquoise"
                                    class="hydrated">
                                </eon-ui-table-toolbar-selected>
                            </eon-ui-table-toolbar>

                            <eon-ui-table-head class="hydrated">
                                <eon-ui-table-head-row class="hydrated">

                                    <eon-ui-table-head-cell sortable="false" sort-key="FrauHerr"
                                        class="eonui-medium eonui-pointer hydrated" role="button" tabindex="0"
                                        sort-order="none">
                                        <eon-ui-checkbox @valuechanged="(e)=>{selectionChanged_(e,'allAp');}"
                                            :checked="allApChecked"></eon-ui-checkbox>
                                    </eon-ui-table-head-cell>

                                    <eon-ui-table-head-cell sortable="true" sort-key="FrauHerr"
                                        class="eonui-medium eonui-pointer hydrated" role="button" tabindex="0"
                                        sort-order="none">Anrede</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell sortable="true" sort-key="OrPe_Nachname"
                                        class="eonui-medium eonui-pointer hydrated" role="button" tabindex="0"
                                        sort-order="none">Nachname</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell sortable="true" sort-key="OrPe_Vorname"
                                        class="eonui-medium eonui-pointer hydrated" role="button" tabindex="0"
                                        sort-order="none">Vorname</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell sortable="true" sort-key="znr"
                                        class="eonui-medium eonui-pointer hydrated" role="button" tabindex="0"
                                        sort-order="none">Zangen-Nr.</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell sortable="false"
                                        class="eonui-medium eonui-pointer hydrated" role="button" tabindex="0"
                                        sort-order="none">Informationen</eon-ui-table-head-cell>
                                </eon-ui-table-head-row>
                            </eon-ui-table-head>

                            <eon-ui-table-body size="medium" class="hydrated">
                                <eon-ui-table-row v-for="(item, index) in list_ansprechpartner" :key="index"
                                    :data-row-id="item.OrPe_TeTra_ID" visible="true" class="hydrated" size="medium">
                                    
                                    <eon-ui-table-cell :value="item.OrPe_TeTra_ID" class="eonui-medium hydrated"
                                        size="medium">
                                        <eon-ui-checkbox @valuechanged="(e)=>{selectionChanged_(e,item.OrPe_TeTra_ID)}"
                                            :checked="item.selected"></eon-ui-checkbox>
                                    </eon-ui-table-cell>

                                    <eon-ui-table-cell :value="item.FrauHerr" class="eonui-medium hydrated"
                                        size="medium">{{ item.FrauHerr }}</eon-ui-table-cell>
                                    <eon-ui-table-cell :value="item.OrPe_Nachname" class="eonui-medium hydrated"
                                        size="medium">{{ item.OrPe_Nachname }}</eon-ui-table-cell>
                                    <eon-ui-table-cell :value="item.OrPe_Vorname" class="eonui-medium hydrated"
                                        size="medium">{{ item.OrPe_Vorname }}</eon-ui-table-cell>

                                    <eon-ui-table-cell :value="item.znr" v-if="item.znr !=''"> {{ item.znr }} </eon-ui-table-cell>
                                    <eon-ui-table-cell :value="item.znr" v-else> - </eon-ui-table-cell>
                                    
                                    <eon-ui-table-cell :value="item.befaehigungen" v-if="(item.count_bef != 0 && item.count_bef != '') || (item.count_schu != 0 && item.count_schu != '')">
                                        <eon-ui-button :text="item.count_bef < 10 ? 'BF (0' + item.count_bef + ')' : 'BF (' + item.count_bef + ')'" size="small"
                                            class="btn-custom-blue mr-2" v-on:click="fetchTeilnehmerBefaehigungen(item.OrPe_TeTra_ID, item.FrauHerr, item.OrPe_Vorname, item.OrPe_Nachname)"
                                            v-if="item.count_bef > 0" v-b-tooltip.hover title="Befähigungen">
                                        </eon-ui-button>
                                        <eon-ui-button :text="item.count_schu < 10 ? 'SG (0' + item.count_schu + ')' : 'SG (' + item.count_schu + ')'" size="small"
                                            class="btn-custom-blue mt-1" v-on:click="fetchTeilnehmerSchulungen(item.OrPe_TeTra_ID, item.FrauHerr, item.OrPe_Vorname, item.OrPe_Nachname)"
                                            v-if="item.count_schu > 0" v-b-tooltip.hover title="Schulungen">
                                        </eon-ui-button>
                                    </eon-ui-table-cell>
                                    <eon-ui-table-cell v-else> - </eon-ui-table-cell>

                                </eon-ui-table-row>
                            </eon-ui-table-body>
                        </eon-ui-data-table>
                    </eon-ui-grid-control-column>
                </eon-ui-grid-control>

                <eon-ui-grid-control v-if="list_fuehrungskraft.length" grid="grid__d12__t12" remove-bottom-spacings>
                    <eon-ui-grid-control-column slot="column-1" class="hydrated">
                        <eon-ui-checkbox required label="Ich möchte mich selbst anmelden"  :value="user.user_tetra_id"
                            :name="'self_registration'" 
                            @valuechanged="setSelfRegistration" :checked="'false'"></eon-ui-checkbox>
                    </eon-ui-grid-control-column>
                </eon-ui-grid-control>

                <eon-ui-grid-control v-if="list_fuehrungskraft.length && selfRegistration==false" grid="grid__d12__t12" remove-bottom-spacings>
                    <eon-ui-grid-control-column slot="column-1" class="hydrated">
                        <eon-ui-headline size="h5" text="Führungskräfte" class="hydrated text-black"
                            style="margin-bottom: 10px">
                        </eon-ui-headline>
                        <eon-ui-data-table size="medium" selectable-rows="false" expandable-rows="false"
                            paging="true" page-sizes="10,20,50,100"
                            class="hydrated table-custom" @selectionchanged="(e)=>{selectionChangedFuehrungskraft(e)}">
                            <eon-ui-table-toolbar slot="table-toolbar" class="hydrated">
                                <eon-ui-table-toolbar-selected slot="toolbar-selected" inner-background="eon-turquoise"
                                    class="hydrated">
                                </eon-ui-table-toolbar-selected>
                            </eon-ui-table-toolbar>

                            <eon-ui-table-head class="hydrated">
                                <eon-ui-table-head-row class="hydrated">

                                    <eon-ui-table-head-cell sortable="false" sort-key="FrauHerr"
                                        class="eonui-medium eonui-pointer hydrated" role="button" tabindex="0"
                                        sort-order="none">
                                        <eon-ui-checkbox @valuechanged="(e)=>{selectionChanged_(e,'allFk');}"
                                            :checked="allFkChecked" ></eon-ui-checkbox>
                                    </eon-ui-table-head-cell>

                                    <eon-ui-table-head-cell sortable="true" sort-key="FrauHerr"
                                        class="eonui-medium eonui-pointer hydrated" role="button" tabindex="0"
                                        sort-order="none">Anrede</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell sortable="true" sort-key="OrPe_Nachname"
                                        class="eonui-medium eonui-pointer hydrated" role="button" tabindex="0"
                                        sort-order="none">Nachname</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell sortable="true" sort-key="OrPe_Vorname"
                                        class="eonui-medium eonui-pointer hydrated" role="button" tabindex="0"
                                        sort-order="none">Vorname</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell sortable="true" sort-key="znr"
                                        class="eonui-medium eonui-pointer hydrated" role="button" tabindex="0"
                                        sort-order="none">Zangen-Nr.</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell sortable="false"
                                        class="eonui-medium eonui-pointer hydrated" role="button" tabindex="0"
                                        sort-order="none">Informationen</eon-ui-table-head-cell>

                                </eon-ui-table-head-row>
                            </eon-ui-table-head>

                            <eon-ui-table-body size="medium" class="hydrated">
                                <eon-ui-table-row v-for="(item, index) in list_fuehrungskraft" :key="index"
                                    :data-row-id="item.OrPe_TeTra_ID" visible="true" class="hydrated" size="medium">

                                    <eon-ui-table-cell :value="item.OrPe_TeTra_ID" class="eonui-medium hydrated"
                                        size="medium">
                                        <eon-ui-checkbox @valuechanged="(e)=>{selectionChanged_(e,item.OrPe_TeTra_ID)}"
                                            :checked="item.selected"></eon-ui-checkbox>
                                    </eon-ui-table-cell>

                                    <eon-ui-table-cell :value="item.FrauHerr" class="eonui-medium hydrated"
                                        size="medium">{{ item.FrauHerr }}</eon-ui-table-cell>
                                    <eon-ui-table-cell :value="item.OrPe_Nachname" class="eonui-medium hydrated"
                                        size="medium">{{ item.OrPe_Nachname }}</eon-ui-table-cell>
                                    <eon-ui-table-cell :value="item.OrPe_Vorname" class="eonui-medium hydrated"
                                        size="medium">{{ item.OrPe_Vorname }}</eon-ui-table-cell>

                                    <eon-ui-table-cell :value="item.znr" v-if="item.znr !=''"> {{ item.znr }} </eon-ui-table-cell>
                                    <eon-ui-table-cell :value="item.znr" v-else> - </eon-ui-table-cell>

                                    <eon-ui-table-cell :value="item.befaehigungen" v-if="(item.count_bef != 0 && item.count_bef != '') || (item.count_schu != 0 && item.count_schu != '')">
                                        <eon-ui-button :text="item.count_bef < 10 ? 'BF (0' + item.count_bef + ')' : 'BF (' + item.count_bef + ')'" size="small"
                                            class="btn-custom-blue mr-2" v-on:click="fetchTeilnehmerBefaehigungen(item.OrPe_TeTra_ID, item.FrauHerr, item.OrPe_Vorname, item.OrPe_Nachname)"
                                            v-if="item.count_bef > 0" title="Befähigungen">
                                        </eon-ui-button>
                                        <eon-ui-button :text="item.count_schu < 10 ? 'SG (0' + item.count_schu + ')' : 'SG (' + item.count_schu + ')'" size="small"
                                            class="btn-custom-blue mt-1" v-on:click="fetchTeilnehmerSchulungen(item.OrPe_TeTra_ID, item.FrauHerr, item.OrPe_Vorname, item.OrPe_Nachname)"
                                            v-if="item.count_schu > 0" title="Schulungen">
                                        </eon-ui-button>
                                    </eon-ui-table-cell>
                                    <eon-ui-table-cell v-else> - </eon-ui-table-cell>

                                </eon-ui-table-row>
                            </eon-ui-table-body>
                        </eon-ui-data-table>
                    </eon-ui-grid-control-column>
                </eon-ui-grid-control>

                <eon-ui-grid-control v-if="dataSeminar.Ort" grid="grid__d12__t12" remove-bottom-spacings>
                    <eon-ui-grid-control-column slot="column-1" class="hydrated">
                        <eon-ui-headline size="h5" text="Ort" class="hydrated text-black" style="margin-bottom: 10px">
                        </eon-ui-headline>
                        <eon-ui-text class="text-black" size="small" text-style="copy">
                            {{ dataSeminar.Ort }}
                        </eon-ui-text>
                    </eon-ui-grid-control-column>
                </eon-ui-grid-control>

                <eon-ui-grid-control v-if="dataSeminar.Gebuehr"
                    grid="grid__d12__t12" remove-bottom-spacings>
                    <eon-ui-grid-control-column slot="column-1" class="hydrated">
                        <eon-ui-headline size="h5" text="Preis" class="hydrated text-black" style="margin-bottom: 10px">
                        </eon-ui-headline>
                        <eon-ui-text class="text-black" size="small" text-style="copy">
                            {{ dataSeminar.preis_art }} {{ dataSeminar.Gebuehr }} €
                        </eon-ui-text>
                    </eon-ui-grid-control-column>
                </eon-ui-grid-control>

                <eon-ui-grid-control v-if="this.$store.state.Tetra_id != ''" grid="grid__d12__t12" remove-bottom-spacings>
                    <eon-ui-grid-control-column slot="column-1" class="hydrated">
                        <eon-ui-headline v-if="dataSkills.length || dataSkillsGroup.length" size="h5"
                            text="Befähigungen" class="hydrated text-black pt-4" style="margin-bottom: 20px">
                        </eon-ui-headline>
                        <div class="form-group mb-4" v-for="item in dataSkillsGroup" :key="item.ds_id">
                            <eon-ui-checkbox required name="group"
                                :label="item.ds_id + ' ' + item.SGAuSVK_NodeText" :value="item.ds_id"
                                @valuechanged="handleCheckbox" :checked="item.checkedGroup"></eon-ui-checkbox>

                            <div class="form-group mb-4 ml-4 mt-2" v-for="groupItem in dataSkillsFromGroup.filter(
                                (groupItem) => groupItem.Berechtigung_Gruppe_ID == item.ds_id
                            )" :key="groupItem.nr">
                                <eon-ui-checkbox required :label="groupItem.nr + ' ' + groupItem.name"  :value="groupItem.Berechtigung_ID"
                                    :name="groupItem.Berechtigung_Gruppe_ID + '|groupItem'" 
                                    @valuechanged="handleCheckbox" :checked="groupItem.checked"></eon-ui-checkbox>
                            </div>
                        </div>

                        <div class="form-group mb-4" v-for="item in dataSkills" :key="item.nr">
                            <eon-ui-checkbox required :label="item.nr + ' ' + item.name" :value="item.ds_id"
                                :name="item.nr"
                                @valuechanged="handleCheckbox" :checked="item.checked"></eon-ui-checkbox>
                        </div>
                    </eon-ui-grid-control-column>
                </eon-ui-grid-control>

                <eon-ui-grid-control grid="grid__d12__t12" remove-bottom-spacings>
                    <eon-ui-grid-control-column slot="column-1" class="hydrated">
                        <div class="form-group mb-4">
                            <eon-ui-button v-if="this.$store.state.Tetra_id != ''" class="btn-custom-green" text="Jetzt verbindlich anmelden"
                                input-type="button" v-on:click="bookingSeminar"></eon-ui-button>
                        </div>
                    </eon-ui-grid-control-column>
                </eon-ui-grid-control>
            </eon-ui-grid-control-column>
        </eon-ui-grid-control>
    </eon-ui-section>

    <div class="loading-section" :class="loading ? 'show' : ''">
        <eon-ui-preloader-circle></eon-ui-preloader-circle>
    </div>

    <eon-ui-modal modal-id="modalBefaehigungen" name="modalBefaehigungen" show-closing-x="true" role="alertdialog" aria-modal="true"
        aria-live="assertive" class="hydrated" @modalclose="closeModal('modalBefaehigungen')">

        <eon-ui-headline text="Befähigungen" size="h4" class="hydrated text-blue"></eon-ui-headline>

        <eon-ui-text text-style="copy" class="hydrated">{{ sub_title }}</eon-ui-text>

        <div v-if="ma_befTable == ''">Keine Befähigungen gefunden.</div>

        <div v-else>
            <eon-ui-data-table zebra="true" selectable-rows="false" expandable-rows="false">
                <eon-ui-table-head>
                    <eon-ui-table-head-row>
                        <eon-ui-table-head-cell sort-key="befaehigung_id">Nummer</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell sort-key="name">Name</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell sort-key="gueltigkeit">gültig bis</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell></eon-ui-table-head-cell>
                    </eon-ui-table-head-row>
                </eon-ui-table-head>
                <eon-ui-table-body>
                    <eon-ui-table-row v-for="ma_bef in ma_befTable" :key="ma_bef.befaehigung_id">
                        <eon-ui-table-cell :value="ma_bef.nummer" v-if="ma_bef.aktuell !=''"> {{ ma_bef.nummer }} </eon-ui-table-cell>
                        <eon-ui-table-cell :value="ma_bef.name" v-if="ma_bef.aktuell !=''"> {{ ma_bef.name }} </eon-ui-table-cell>
                        <eon-ui-table-cell :value="ma_bef.gueltigkeit" v-if="ma_bef.aktuell !='' && ma_bef.gueltigkeit != ''"> {{ ma_bef.gueltigkeit }} </eon-ui-table-cell>
                        <eon-ui-table-cell v-if="ma_bef.aktuell !=''">
                            <span v-if="ma_bef.zulassungseinschraenkungstext != '' && ma_bef.entzogen == 0 && ma_bef.aktuell !=''"><br /><br /></span>
                            <span v-if="ma_bef.entzogen == 1 && ma_bef.aktuell !=''">entzogen</span>
                        </eon-ui-table-cell>
                    </eon-ui-table-row>
                </eon-ui-table-body>
            </eon-ui-data-table>
        </div>

        <div class="mt-3">
            <eon-ui-button text="Drucken" size="small" class="btn-custom-blue" v-on:click="exportPdf_Befaehigungen()"></eon-ui-button>
        </div>

        <div class="loading-section" :class="loading ? 'show' : ''">
            <eon-ui-preloader-circle></eon-ui-preloader-circle>
        </div>

    </eon-ui-modal>

    <eon-ui-modal modal-id="modalSchulungen" name="modalSchulungen" show-closing-x="true" @modalclose="closeModal('modalSchulungen')">

        <eon-ui-headline text="Schulungen" size="h4" class="hydrated text-blue"></eon-ui-headline>

        <eon-ui-text text-style="copy" class="hydrated">{{ sub_title }}</eon-ui-text>

        <div class="hydrated" v-if="ma_schuTable == ''">Keine Schulungen gefunden.</div>

        <div v-else>
            <eon-ui-data-table class="hydrated" zebra="false" selectable-rows="false" expandable-rows="false">
                <eon-ui-table-head>
                    <eon-ui-table-head-row>
                        <eon-ui-table-head-cell>Nummer</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell>Bezeichnung</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell sortable="true" sort-key="type" sort-order="dsc">Beginn</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell>Ende</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell>Ort</eon-ui-table-head-cell>
                    </eon-ui-table-head-row>
                </eon-ui-table-head>
                <eon-ui-table-body>
                    <eon-ui-table-row v-for="item in ma_schuTable" :key="item.id">
                        <eon-ui-table-cell :value="item.nummer" v-if="item.seminar_id != ''">
                            <router-link :to="{ name: 'seminareDetail', params: { id: item.seminar_id } }">
                                <eon-ui-link size="small" :text="item.nummer" target="_self" hide-icon="true" class="hydrated fs-14" scheme="turquoise"
                                    v-on:click="closeModal('modalSchulungen')">
                                </eon-ui-link>
                            </router-link>
                        </eon-ui-table-cell>
                        <eon-ui-table-cell :value="item.bezeichnung" v-if="item.seminar_id != ''">
                            <router-link :to="{ name: 'seminareDetail', params: { id: item.seminar_id } }">
                                <eon-ui-link size="small" :text="item.bezeichnung" target="_self" hide-icon="true" class="hydrated fs-14" scheme="turquoise"
                                    v-on:click="closeModal('modalSchulungen')">
                                </eon-ui-link>
                            </router-link>
                        </eon-ui-table-cell>
                        <eon-ui-table-cell :value="item.beginn" v-if="item.seminar_id != ''"> {{ item.beginn }} </eon-ui-table-cell>
                        <eon-ui-table-cell :value="item.ende" v-if="item.seminar_id != ''"> {{ item.ende }} </eon-ui-table-cell>
                        <eon-ui-table-cell :value="item.ort" v-if="item.seminar_id != '' && item.ort != ''"> {{ item.ort }} </eon-ui-table-cell>
                        <eon-ui-table-cell :value="item.ort" v-if="item.seminar_id != '' && item.ort == ''"> - </eon-ui-table-cell>
                    </eon-ui-table-row>
                </eon-ui-table-body>
            </eon-ui-data-table>
        </div>

        <div class="loading-section" :class="loading ? 'show' : ''">
            <eon-ui-preloader-circle></eon-ui-preloader-circle>
        </div>

    </eon-ui-modal>
</template>

<script>
import Resource from "../../composables/bayernwerk";
import { formatDateDMY, formatPrice, compareDateToday } from "../../utils/filter";

const bayernwerkResource = new Resource();

export default {
    name: "SeminareDetail",
    data() {
        return {
            dataSeminar: {},
            dataSkills: [],
            dataSkillsGroup: [],
            dataSkillsFromGroup: [],
            id_kurs: this.$route.params.id,
            loading: false,
            message_visible: false,
            message_visible_success: false,
            text_success: "",
            text_error: "",
            user: {
                user_teilnehmer: this.$store.state.teilnehmer,
                user_fuehrungskraft: this.$store.state.fuehrungskraft,
                user_ansprechpartner: this.$store.state.ansprechpartner,
                user_ds_id: this.$store.state.ds_id,
                user_tetra_id: this.$store.state.Tetra_id,
                user_email: this.$store.state.email,
            },
            list_ansprechpartner: [],
            list_fuehrungskraft: [],
            Mitarbeiter_ID: "",
            ma_befTable: [],
            ma_schuTable: [],
            sub_title: "",
            registerCheck: {
                userIsRegistered : false,
                lookedAt: [],
                alreadyRegistered: [],
            },
            selfRegistration: false,
            getEmployeesQuery: {
                page: 1,
                ds_id: this.$store.state.ds_id,
                limit: 10, 
                aktiv: 1
            },
            allApChecked: false,
            allFkChecked: false,
        };
    },
    computed: {
        user_token() {
            return this.$store.state.token;
        },
    },
    watch: {
        user_token(newUserData, oldUserData) {
            this.id_kurs = this.$route.params.id;
            this.fetchDataSeminarBookingDetail(this.id_kurs);
        },
    },
    mounted() {
        this.id_kurs = this.$route.params.id;
        if (!this.$store.state.token) {
            this.text_error =
                "Sie sind nicht angemeldet. Bitte loggen Sie sich über die Schaltfläche \"Anmelden\" (oben rechts) ein.";
            this.message_visible = true;
            setTimeout(() => this.$router.push({ name: "startseite" }), 4000);
        } else {
            this.loading = true;
            this.fetchDataSeminarBookingDetail(this.id_kurs);
            this.selfRegistration = false;
            if (this.user.user_ansprechpartner == 1) {
                this.getListTeilnehmerAnsprechpartner(this.getEmployeesQuery);
            }
            if (this.user.user_fuehrungskraft == 1) {
                this.getListTeilnehmerFuehrungskraft(this.getEmployeesQuery);
            }
            if (this.user.user_fuehrungskraft != 1 && this.user.user_ansprechpartner != 1) {
                this.checkUserRegistration();
                this.selfRegistration = true;
            }
        }
        // this.registerCheck = {
        //     userIsRegistered : false,
        //     lookedAt: [],
        //     alreadyRegistered: [],
        // };
        
    },
    created() { },
    setup() { },
    methods: {
        fetchDataSeminarBookingDetail(ID_Kurs) {
            this.loading = true;
            bayernwerkResource
                .getSeminareBookingDetail({ ID_Kurs: ID_Kurs })
                .then((res) => {
                    if (res != null) {
                        if (res.response &&
                            res.response.data &&
                            res.messages[0].code == "0"
                        ) {
                            if(!compareDateToday(res.response.data[0]["fieldData"]["Datum"])) {
                                this.text_error = "Der Kurs hat begonnen oder ist beendet. Automatischer Rücksprung auf die Seite „Katalog“.";
                                this.message_visible = true;
                                setTimeout(() => this.message_visible = false, 2500);
                                // seminar test id 173
                                if(ID_Kurs !== '173'){
                                    setTimeout(() => this.$router.push({ name: "katalog", params: { detail:'gesamt'}  }), 3000);
                                }
                            }
                            this.dataSeminar = {
                                ...res.response.data[0]["fieldData"],
                                Datum: formatDateDMY(
                                    res.response.data[0]["fieldData"]["Datum"]
                                ),
                                Endedatum: formatDateDMY(
                                    res.response.data[0]["fieldData"]["Endedatum"]
                                ),
                                Gebuehr: formatPrice(
                                    res.response.data[0]["fieldData"]["Gebuehr"]
                                ),
                            };

                            if (res.response.data[0]["portalData"]["skills"].length) {
                                this.dataSkills = res.response.data[0]["portalData"][
                                    "skills"
                                ].map((value) => {
                                    return {
                                        recordId: value["recordId"],
                                        ds_id: value["skills_details::ds_id"],
                                        name: value["skills_details::name"],
                                        nr: value["skills_details::nr"],
                                        checked: false,
                                    };
                                },[]);
                            }
                            if (res.response.data[0]["portalData"]["skills_groups"].length) {
                                this.dataSkillsGroup = res.response.data[0]["portalData"][
                                    "skills_groups"
                                ].map((value) => {
                                    return {
                                        recordId: value["recordId"],
                                        ds_id: value["skills_groups_details::ds_id"],
                                        SGAuSVK_NodeText:
                                            value["skills_groups_details::SGAuSVK_NodeText"],
                                        nr: value["skills_groups_details::nr"],
                                        checkedGroup: false,
                                    };
                                },[]);
                            }
                            if (res.response.data[0]["portalData"]["skills_from_groups"].length) {
                                this.dataSkillsFromGroup = res.response.data[0]["portalData"][
                                    "skills_from_groups"
                                ].map((value) => {
                                    return {
                                        recordId: value["recordId"],
                                        Berechtigung_Gruppe_ID:
                                            value["skills_from_groups::Berechtigung_Gruppe_ID"],
                                        Berechtigung_ID:
                                            value["skills_from_groups::Berechtigung_ID"],
                                        name: value["skills_from_groups_details::name"],
                                        nr: value["skills_from_groups_details::nr"],
                                        checked: false,
                                    };
                                },[]);
                            }
                        } else {
                            this.text_error = res.error_msg;
                            this.message_visible = true;
                            const code = res.messages[0].code;
                            if (code == 952 || code == 10) {
                                this.logoutTokenExpired();
                            }
                        }
                    } else {
                        this.text_error = "Token expired";
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        this.logoutTokenExpired();
                    }
                    this.loading = false;
                })
                .catch((error) => {
                    this.loading = false;
                    this.text_error = error;
                    this.message_visible = true;
                    const code = _.get(error, "response.data.messages[0].code", 0);
                    if (code == 952 || code == 10) {
                        this.logoutTokenExpired();
                    }
                });
        },
        /** Checks whether the user is already Registarted for this Seminar ot not.
         */
        checkUserRegistration() {
            bayernwerkResource.getListSchulungen({
                ma_id: this.user.user_tetra_id
            }).then( (res) => {
                if(res!=null) {
                    let seminarData = res.response.data;
                    if ( seminarData === undefined ) {
                        this.registerCheck.userIsRegistered = false;
                    }
                    seminarData.map( (seminar) => {
                        if (seminar['fieldData']['seminar_id'] == this.id_kurs ) {
                            this.registerCheck.userIsRegistered = true;
                            // this.text_error = "Sie sind bereits für diesen Kurs Registriert.";
                            // this.message_visible = true;
                            // setTimeout(() => this.message_visible = false, 4000);
                        }
                    });
                }
            }).catch( (error) => {

            });
        },
        /** Toggels the group elements when the group checkbox is clicked.
         * All group elements will be eighter checked or unchecked.
         * @param event
         */
        handleSkillGroup(event) {
            this.dataSkillsGroup = this.dataSkillsGroup.map( (item) => {
                if (event.target.value == item.ds_id) {
                    item.checkedGroup = (event.target.checked)? true : false;
                }
                return { ...item };
            }, []);

            this.dataSkillsFromGroup = this.dataSkillsFromGroup.map( (itemGroup) => {
                if (itemGroup.Berechtigung_Gruppe_ID == event.target.value) {
                    itemGroup.checked = (event.target.checked)? true : false;
                }
                return { ...itemGroup };
            }, []);
        },
        /** Checks the group checkbox when a group element is checked.
         * @param event event (valueChanged)
         * @param groupId
         */
        handleSkillWihtGroup(event, groupId) {
            this.dataSkillsFromGroup = this.dataSkillsFromGroup.map( (groupItem) => {
                let condition = 
                    groupItem.Berechtigung_ID === event.target.value 
                    &&
                    groupItem.Berechtigung_Gruppe_ID == groupId;

                if (condition) {
                    groupItem.checked = (event.target.checked)? true: false; 
                }
                return { ...groupItem };
            }, []);

            this.dataSkillsGroup = this.dataSkillsGroup.map( (group) => {
                let noItemChecked = true;
                this.dataSkillsFromGroup = this.dataSkillsFromGroup.map( (groupItem) => {
                    if(groupItem.checked) {
                        noItemChecked=false;
                    }
                    return { ...groupItem };
                }, []);

                group.checkedGroup = (noItemChecked)? false : true;
                return { ...group };
            }, []);
        },
        /** Checks only skills whitout skull groups
         * @param event
         */
        handleSkillsWithoutGroup(event) {
             this.dataSkills = this.dataSkills.map( (item) => {
                if (item.ds_id == event.target.value) {
                    item.checked = (event.target.checked)? true : false;
                }
                return { ...item };
            }, []);
        },
        /** Handles all skill checkboxes
         * @param event
         */
        handleCheckbox(event) {
            let groupId = event.target.name.split('|');
            if(event.target.name === "group") {

                this.handleSkillGroup(event);

            } else if(groupId[1] != null && groupId[1]=="groupItem") {

                this.handleSkillWihtGroup(event, groupId[0]);

            } else {
               
                this.handleSkillsWithoutGroup(event);

            }
            // console.log(this.list_fuehrungskraft);
        },
        /** Sets skill_id, skill_group and skill_checked for all skills.
         * @returns { {checkedInfo: string[], allSkills: object[]} }
         */
        setSkillData() {
            let selectedInfo = [];
            let skills = [];

            for(let i = 0; i < this.dataSkillsFromGroup.length; i++) {
                let item = this.dataSkillsFromGroup[i];
                let skill = {
                    "temp_booking_skills::skill_id": item.Berechtigung_ID,
                    "temp_booking_skills::skill_id_group": item.Berechtigung_Gruppe_ID,
                    "temp_booking_skills::skill_checked": (item.checked)? 1 : 0,
                };
                skills.push(skill);
                if(item.checked){
                    selectedInfo.push(item.nr + " " + item.name);
                }
            }
            
            for(let i = 0; i < this.dataSkills.length; i++) {
                let item = this.dataSkills[i];
                let skill = {
                    "temp_booking_skills::skill_id": "" + item.ds_id,
                    "temp_booking_skills::skill_id_group": "0",
                    "temp_booking_skills::skill_checked": (item.checked)? 1 : 0,
                };
                skills.push(skill);
                if(item.checked){
                    selectedInfo.push(item.nr + " " + item.name);
                }
            }
            
            return {checkedInfo: selectedInfo, allSkills: skills};
        },
        /** Sets the data for all checked empoyees and leaders.
         * @returns { {ansprechpartner: object[], fuehrungskraft: object[], mailAP: object[], mailFK: object[]} }
         */
        setEmployeeData() {
            let ansprechpartner = [];
            let fuehrungskraft = []; 
            let mailAP = [];
            let mailFK = [];
            
            for(let i = 0; i < this.list_ansprechpartner.length; i++) {
                let item = this.list_ansprechpartner[i];
                
                let record = {
                    orpe_tetra_id: item.OrPe_TeTra_ID,
                    salutation_and_Name: item.FrauHerr + " " + item.OrPe_Nachname
                };
                
                if (item.selected == true) {
                    ansprechpartner.push(record);
                    mailAP.push(item);
                }
            }

            for(let i = 0; i < this.list_fuehrungskraft.length; i++) {
                let item = this.list_fuehrungskraft[i];
                
                let record = {
                    orpe_tetra_id: item.OrPe_TeTra_ID,
                    salutation_and_Name: item.FrauHerr + " " + item.OrPe_Nachname
                };
                
                if (item.selected == true) {
                    fuehrungskraft.push(record);
                    mailFK.push(item);
                }
            }
            
            return {
                ansprechpartner: ansprechpartner, 
                fuehrungskraft: fuehrungskraft, 
                mailAP: mailAP, 
                mailFK: mailFK
            };
        },
        /** Sends saves all given data to the backend.
         * @param {object[]} employeeGroup
         * @param {array} allSkills
         * @param {string[]} checkedSkills
         * @param {array|null} employeeEmails=null
         */
        sendData(employeeGroup, allSkills, checkedSkills, employeeEmails=null) {
            this.loading = true;
            employeeGroup.map( (item, index) => {
               bayernwerkResource.saveSeminareBooking({
                    orpe_tetra_id: item.orpe_tetra_id,
                    id_kurs: this.id_kurs,
                    temp_booking_skills: allSkills,
                    name_employee: item.salutation_and_Name
                }).then((res) => {
                    if( res === null ) {
                        this.text_error = "Token expired";
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        this.logoutTokenExpired();
                        return;
                    }

                    if( res["error_msg"] !== undefined ) {
                        this.text_error = res["error_msg"];
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        setTimeout(() => this.$router.go(), 4000);
                        return;
                    }

                    if( index == employeeGroup.length - 1 ) {
                        this.sendBookingMail(checkedSkills, employeeEmails);

                        this.text_success = "Sie wurden erfolgreich angemeldet. Ihre Anmeldung wird nun verarbeitet. Nach Abschluss der Anmeldung erhalten Sie eine Bestätigung per E-Mail.";
                        this.message_visible_success = true;
                        setTimeout(() => this.$router.go(), 6000);
                    }

                }).catch( (error) => {
                    this.loading = false;
                    this.text_error = error;
                    this.message_visible = true;
                    const code = _.get(error, "response.data.messages[0].code", 0);
                    if (code == 952 || code == 10) {
                        this.logoutTokenExpired();
                    }
                });
            });
            this.loading = false;
        },
        /** Returns the content for the ics-file.
         * @param {string[]} checkedSkills
         * 
         * @return {string} icsContent
         */
        getIcsContent(checkedSkills) {
            let location = window.location.toString();
            let domane = location.substring( 
                0, location.indexOf("registration")
            );
            
            let startDate = this.dataSeminar.Datum.split(".");
            let startTime = this.dataSeminar.Zeit.split(":");
            let seminarStart = 
                startDate[2] + startDate[1] + startDate[0] + "T" +
                startTime[0] + startTime[1] + startTime[2];

            let endDate = this.dataSeminar.Endedatum.split(".");
            let endTime = this.dataSeminar.Zeit_ende.split(":");
            let seminarEnd =  
                endDate[2] + endDate[1] + endDate[0] + "T" +
                endTime[0] + endTime[1] + endTime[2];

            let seminarLocation = this.dataSeminar.Ort;
            let currentTime = this.getTimestampForIcs();
            let seminarTitle = this.dataSeminar.Bezeichnung;
            let seminarUrl = (domane==="")? "" : domane + "seminare/" + this.dataSeminar.ID_Kurs;

            let seminarDescription = "Befähigung:\\n";
            checkedSkills.map( (skill, index) => {
                seminarDescription += skill + ".";
                seminarDescription += ( (index < checkedSkills.length - 1) )? "\\n" : "" ;
            });
            
            return "BEGIN:VCALENDAR\n" +
                "VERSION:2.0\n" +
                "CALSCALE:GREGORIAN\n" +
                "BEGIN:VEVENT\n" +
                "DTSTART:" + seminarStart + "\n" +
                "DTEND:" + seminarEnd + "\n" + 
                "LOCATION:" + seminarLocation + "\n" +
                "DTSTAMP:" + currentTime + "\n" +
                "SUMMARY:" + seminarTitle + "\n" +
                "URL;VALUE=URI:" + seminarUrl + "\n" +
                ( (checkedSkills.length > 0)? "DESCRIPTION:" + seminarDescription + "\n" : "" ) +
                "UID:" + currentTime + "-" + seminarStart + "-" + seminarEnd + "\n" +
                "END:VEVENT\n" +
                "END:VCALENDAR";
        },
        /** Sends the bookin e-mail to this.user.
         * @param {string[]} checkedSkills 
         * @param {object[]|null} employeeEmails 
         */
        sendBookingMail(checkedSkills, employeeEmails=null) {
            let icsContent = this.getIcsContent(checkedSkills);

            if(employeeEmails===null) {
                bayernwerkResource.sendMailRegistration({
                    ID_Kurs: this.id_kurs,
                    email: this.$store.state.email,
                    Tetra_id: this.$store.state.Tetra_id,
                    ics_content: icsContent
                });
            }
            else {
                bayernwerkResource.sendMailRegistrationEmployees({
                    ID_Kurs: this.id_kurs,
                    email: this.user.user_email,
                    Tetra_id: this.user.user_tetra_id,
                    list_email: employeeEmails,
                    ics_content: icsContent
                });
            }
        },
        /** Returns the current timestamp in ics format (YYYYMMDDTHHMMSS).
         * @return {string}
         */
        getTimestampForIcs() {
            let now = new Date();
            let year = now.getFullYear();
            let month = now.getMonth() + 1;
            let day = now.getDate();
            let hour = now.getHours();
            let minute = now.getMinutes();
            let secund = now.getSeconds();

            return year +
                ((month < 10)? "0" + month : month) +
                ((day < 10)? "0" + day : day) + "T" +
                ((hour < 10)? "0" + hour : hour) +
                ((minute < 10)? "0" + minute : minute) +
                ((secund < 10)? "0" + secund : secund);
        },
        /** Sets the "this.selfRegistration" bool according to the checkbox.
         * @param event
         */
        setSelfRegistration(event) {
            this.checkUserRegistration();
            this.selfRegistration = (event.target.checked)? true: false;
        },
        /** Checks whether the selected Employes are already booked or not.
         * @param {object[]} employees
         * @return {bool}
         */
        checkSelectedEmployeeRegistration(employees) {
            let alreadyRegistered = this.registerCheck.alreadyRegistered;
            let reply = false;
            employees.forEach( (employee) => {
                if(alreadyRegistered.includes(employee.orpe_tetra_id)) {
                    reply =  true;
                }
            });
            return reply;
        },
        bookingSeminar() {
            const noEmployees = this.list_ansprechpartner.length == 0 && this.list_fuehrungskraft.length == 0;
            
            const bookingSkillData = this.setSkillData();
            const tempList = this.setEmployeeData();
            
            if(noEmployees || this.selfRegistration) {

                if(this.registerCheck.userIsRegistered) {
                    this.text_error = "Sie sind bereits für diesen Kurs Registriert.";
                    this.message_visible = true;
                    setTimeout(() => this.message_visible = false, 4000);
                    setTimeout(() => this.$router.go(), 4000);
                }
                else {
                    this.sendData([{
                            orpe_tetra_id: this.user.user_tetra_id,
                            salutation_and_Name: 'selbst'
                        }],
                        bookingSkillData.allSkills,
                        bookingSkillData.checkedInfo
                    );
                }

                return;
            }

            const employeeSelected = tempList.ansprechpartner.length > 0 || tempList.fuehrungskraft.length > 0;
            
            if(!employeeSelected) {
                alert("Bitte wählen Sie mindestens 1 Mitarbeiter oder sich selbst aus!");
                return;
            }

            const employeesAlreadyRegistered = 
                this.checkSelectedEmployeeRegistration(tempList.ansprechpartner) || 
                this.checkSelectedEmployeeRegistration(tempList.fuehrungskraft);

            if(employeesAlreadyRegistered) {
                this.text_error = "Eine oder mehrere Personen in Ihrer Auswahl sind bereis für diesen Kurs registriert.";
                this.message_visible = true;
                setTimeout(() => this.message_visible = false, 4000);
                setTimeout(() => this.$router.go(), 4000);
                return;
            }

            let icsContent = this.getIcsContent(bookingSkillData.checkedInfo);
            if(tempList.ansprechpartner.length > 0) {
                this.sendData(
                    tempList.ansprechpartner,
                    bookingSkillData.allSkills,
                    bookingSkillData.checkedInfo,
                    tempList.mailAP
                );

                tempList.mailAP.forEach( (employee) => {
                    if( employee.hasEmail == 1 ) {
                        bayernwerkResource.sendMailRegistration({
                            ID_Kurs: this.id_kurs,
                            email: employee.email,
                            Tetra_id: employee.OrPe_TeTra_ID,
                            ics_content: icsContent
                        });
                    }
                });
            }
            if(tempList.fuehrungskraft.length > 0) {
                this.sendData(
                    tempList.fuehrungskraft,
                    bookingSkillData.allSkills,
                    bookingSkillData.checkedInfo,
                    tempList.mailFK
                );

                tempList.mailFK.forEach( (employee) => {
                    if( employee.hasEmail == 1 ) {
                        bayernwerkResource.sendMailRegistration({
                            ID_Kurs: this.id_kurs,
                            email: employee.email,
                            Tetra_id: employee.OrPe_TeTra_ID,
                            ics_content: icsContent
                        });
                    }
                });
            }
        },
        getListTeilnehmerAnsprechpartner(query) {
            bayernwerkResource
                .getTeilnehmerAnsprechpartner(query)
                .then((res) => {
                    if (res == null) {
                        this.text_error = "Token expired";
                        this.message_visible = true;
                        this.logoutTokenExpired();
                        return;
                    }

                    let isResValid = (res.response && res.response.data && res.messages[0].code == "0");
                    if ( isResValid && res.response.data.length ) {
                        this.list_ansprechpartner = res.response.data.map( (value) => {
                            return {
                                onlineportal_id: value["fieldData"]["onlineportal_id"],
                                OrPe_Vorname: value["fieldData"]["OrPe_Vorname"],
                                OrPe_Nachname: value["fieldData"]["OrPe_Nachname"],
                                FrauHerr: value["fieldData"]["FrauHerr"],
                                OrPe_TeTra_ID: JSON.stringify(
                                    value["fieldData"]["OrPe_TeTra_ID"]
                                ),
                                selected: false,
                                count_bef: value.fieldData.nummer_anzeige_b,
                                count_schu: value.fieldData.nummer_anzeige_t,
                                znr: value["fieldData"]["zangen_teilnehmer::zangen_nr"],
                                email: value["fieldData"]["telekommunikation::OrTe_DienstEmail1"],
                                hasEmail: (value["fieldData"]["telekommunikation::OrTe_DienstEmail1"] != "")? 1 : 0,
                                hasSeminar: value.fieldData.nummer_anzeige_t > 0 
                            };
                        });
                    } else {
                        this.list_ansprechpartner = [];
                    }
                })
                .catch((error) => {
                    const code = _.get(error, "response.data.messages[0].code", 0);
                    if (code == 952 || code == 10) {
                        this.logoutTokenExpired();
                    }
                });
        },
        getListTeilnehmerFuehrungskraft(query) {
            bayernwerkResource
                .getTeilnehmerFuehrungskraft(query)
                .then((res) => {
                    if (res != null) {
                        // console.log(res);
                        let isResValid = res.response && res.response.data && res.messages[0].code == "0";
                        if ( isResValid && res.response.data.length ) {
                            this.list_fuehrungskraft = res.response.data.map((value) => {
                                return {
                                    onlineportal_id: value["fieldData"]["onlineportal_id"],
                                    OrPe_Vorname: value["fieldData"]["OrPe_Vorname"],
                                    OrPe_Nachname: value["fieldData"]["OrPe_Nachname"],
                                    FrauHerr: value["fieldData"]["FrauHerr"],
                                    OrPe_TeTra_ID: JSON.stringify(
                                        value["fieldData"]["OrPe_TeTra_ID"]
                                    ),
                                    selected: false,
                                    count_bef: value.fieldData.nummer_anzeige_b,
                                    count_schu: value.fieldData.nummer_anzeige_t,
                                    znr: value["fieldData"]["zangen_teilnehmer::zangen_nr"],
                                    email: value["fieldData"]["telekommunikation::OrTe_DienstEmail1"],
                                    hasEmail: (value["fieldData"]["telekommunikation::OrTe_DienstEmail1"] != "")? 1 : 0,
                                    hasSeminar: value.fieldData.nummer_anzeige_t > 0 
                                };
                            });
                        } else {
                            this.list_fuehrungskraft = [];
                        }
                    } else {
                        this.text_error = "Token expired";
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        this.logoutTokenExpired();
                    }
                })
                .catch((error) => {
                    const code = _.get(error, "response.data.messages[0].code", 0);
                    if (code == 952 || code == 10) {
                        this.logoutTokenExpired();
                    }
                });
        },


        selectionChanged_(event, from) {
            if( from === "allAp" ) {
                this.handleAllSelected(event.detail, this.list_ansprechpartner);
            }
            else {
                this.list_ansprechpartner = this.list_ansprechpartner.map( (item) => {
                    if (from == item.OrPe_TeTra_ID) {
                        this.handleEmployeeSelection(event.detail, item);
                    }
                    return { ...item };
                }, []);
            }
            
            if( from === "allFk" ) {
                this.handleAllSelected(event.detail, this.list_fuehrungskraft);
            }
            else {
                this.list_fuehrungskraft = this.list_fuehrungskraft.map( (item) => {
                    if (from == item.OrPe_TeTra_ID) {
                        this.handleEmployeeSelection(event.detail, item);
                    }
                    return { ...item };
                }, []);
            }

            this.refreschEmployeeList();

        },
        handleAllSelected(selected, employeeList=[]) {
            employeeList = employeeList.map( (item) => {
                this.handleEmployeeSelection(selected, item);
                return { ...item };
            }, []);
        },
        handleEmployeeSelection(selected, employee) {
            employee.selected = selected;
            let isLookedAt = this.registerCheck.lookedAt.includes(employee.OrPe_TeTra_ID);
            let isAlreadyRegistered = this.registerCheck.alreadyRegistered.includes(employee.OrPe_TeTra_ID);
        
            if (employee.selected && !isLookedAt && !isAlreadyRegistered && employee.hasSeminar) {
                this.checkRegistration(
                    employee.OrPe_TeTra_ID, 
                    employee.FrauHerr, 
                    employee.OrPe_Vorname, 
                    employee.OrPe_Nachname
                );
            }
            else if( employee.selected && isAlreadyRegistered ){
                let txt = "" + employee.FrauHerr;
                txt += " " + employee.OrPe_Vorname + " " + employee.OrPe_Nachname;
                txt += " ist bereits für diesen Kurs Registriert.";

                this.text_error = txt;
                this.message_visible = true;
                setTimeout(() => this.message_visible = false, 4000);
            }
        },
        checkRegistration(tetraId, salutation, firstName, lastName) {
            let txt = "";
            bayernwerkResource.getListSchulungen({ma_id: tetraId})
            .then( (res) => {
                if(res!=null) {
                    let seminarData = res.response.data;
                    if ( seminarData === undefined ) {
                        this.registerCheck.lookedAt.push(tetraId);
                    }
                    else {
                        seminarData.forEach( (seminar) => {
                            if (seminar['fieldData']['seminar_id'] == this.id_kurs ) { // this.id_kurs - 173
                                this.registerCheck.alreadyRegistered.push(tetraId);

                                txt = salutation;
                                txt += " " + firstName + " " + lastName;
                                txt += " ist bereits für diesen Kurs Registriert.";

                                this.text_error = txt;
                                this.message_visible = true;
                                setTimeout(() => this.message_visible = false, 4000);
                            }
                            else {
                                this.registerCheck.lookedAt.push(tetraId);
                            }
                        });
                    }
                }
            }).catch( (error) => {
                console.log(error.message);
            } ).finally( () => {
                this.text_error = txt;
                this.message_visible = txt !== "";	
                setTimeout(() => this.message_visible = false, 4000);
            });
        },
        refreschEmployeeList() {
            let isEmployeeSelected = false
            
            for(let i=0; i < this.list_ansprechpartner.length; i++) {
                if(this.list_ansprechpartner[i].selected) {
                    isEmployeeSelected = true;
                    break;
                }
            }
            
            this.allApChecked = isEmployeeSelected;

            isEmployeeSelected = false;

            for(let i=0; i < this.list_fuehrungskraft.length; i++) {
                if(this.list_fuehrungskraft[i].selected) {
                    isEmployeeSelected = true;
                    break;
                }
            }

            this.allFkChecked = isEmployeeSelected;
            
        },


        selectionChanged(event, list=[]) {
            let txt="";
            list = list.map( (item) => {
                item.selected = (event.detail.includes(item.OrPe_TeTra_ID))? true : false;
                
                let isLookedAt = this.registerCheck.lookedAt.includes(item.OrPe_TeTra_ID);
                let isAlreadyRegistered = this.registerCheck.alreadyRegistered.includes(item.OrPe_TeTra_ID);
                
                if (item.selected && !isLookedAt && !isAlreadyRegistered && item.hasSeminar) {
                    bayernwerkResource.getListSchulungen({
                        ma_id: item.OrPe_TeTra_ID
                    }).then( (res) => {
                        if(res!=null) {
                            let seminarData = res.response.data;
                            if ( seminarData === undefined ) {
                                this.registerCheck.lookedAt.push(item.OrPe_TeTra_ID);
                            }
                            else {
                                seminarData.forEach( (seminar) => {
                                    if (seminar['fieldData']['seminar_id'] == this.id_kurs ) { // this.id_kurs - 173
                                        this.registerCheck.alreadyRegistered.push(item.OrPe_TeTra_ID);

                                        txt = "" + item.FrauHerr;
                                        txt += " " + item.OrPe_Vorname + " " + item.OrPe_Nachname;
                                        txt += " ist bereits für diesen Kurs Registriert.";

                                        this.text_error = txt;
                                        this.message_visible = true;
                                        setTimeout(() => this.message_visible = false, 4000);
                                    }
                                    else {
                                        this.registerCheck.lookedAt.push(item.OrPe_TeTra_ID);
                                    }
                                });
                            }
                        }
                        else{
                            // some error message
                            // this.registerCheck.lookedAt.push(item.OrPe_TeTra_ID);
                        }
                    }).catch( (error) => {
                        console.log(error.message);
                    } );
                    
                    // this.registerCheck.lookedAt.push(item.OrPe_TeTra_ID);

                }
                else if( item.selected && isAlreadyRegistered ){
                    txt = "" + item.FrauHerr;
                    txt += " " + item.OrPe_Vorname + " " + item.OrPe_Nachname;
                    txt += " ist bereits für diesen Kurs Registriert.";

                    this.text_error = txt;
                    this.message_visible = true;
                    setTimeout(() => this.message_visible = false, 4000);
                }
                return { ...item };
            }, []);
        },
        selectionChangedAnsprechpartner(event) {
            
            this.selectionChanged(event, this.list_ansprechpartner);

        },
        selectionChangedFuehrungskraft(event) {
            
            this.selectionChanged(event, this.list_fuehrungskraft);

        },
        logoutTokenExpired() {
            this.$store.dispatch("user/logout");
            setTimeout(() => this.$router.go(), this.$router.push({ name: "startseite" }), 4000);
        },
        // copy-past from MeineMitarbeiter.vue
        fetchTeilnehmerBefaehigungen(Mitarbeiter_ID, anrede, vorname, nachname) {
            
            this.Mitarbeiter_ID = parseInt(Mitarbeiter_ID);
            this.loading = true;
            this.ma_befTable = [];

            this.sub_title = "von " + anrede;
            this.sub_title += (anrede == "Herr")? "n" : "";
            this.sub_title += " " + vorname + " " + nachname;

            bayernwerkResource.getListBefaehigungen({Mitarbeiter_ID: Mitarbeiter_ID}).then( (res) => {
                if (res != null) {
                    this.valid = true;
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;
                        this.ma_befTable = data_array.map( (value) => {
                            return {
                                name: value.fieldData.name,
                                nummer: value.fieldData.nr,
                                aktuell: value.fieldData.aktuell,
                                entzogen: value.fieldData.entzogen,
                                gueltigkeit: formatDateDMY(value.fieldData.gültigkeit_datum_anzeige),
                                zulassungseinschraenkung: value.fieldData.Zulassungseinschraenkung,
                                zulassungseinschraenkungstext: value.fieldData.Zulassungseinschraenkungstext
                            };
                        });
                    } else {
                        this.text_error = res.error_msg ? res.error_msg : res.messages[0].message;
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                    }
                } else {
                    console.log('Token expired!');
                }
                this.loading = false;
                this.openModal('modalBefaehigungen');
            });
        },
        // copy-past from MeineMitarbeiter.vue
        fetchTeilnehmerSchulungen(Mitarbeiter_ID, anrede, vorname, nachname) {
            this.Mitarbeiter_ID = parseInt(Mitarbeiter_ID);
            this.loading = true;
            // this.ma_schuTable = [];

            this.sub_title = "von " + anrede;
            this.sub_title += (anrede == "Herr")? "n" : "";
            this.sub_title += " " + vorname + " " + nachname;

            bayernwerkResource.getListSchulungen({ma_id: Mitarbeiter_ID}).then((res) => {
                if (res != null) {
                    this.valid = true;
                    if(res.response && res.response.data && res.messages[0].code == "0" ) {
                        const data_array = res.response.data;
                        this.ma_schuTable = data_array.map((value) => {
                            return {
                                id: value.fieldData.ds_id,
                                seminar_id: value.fieldData.seminar_id,
                                nummer: value["fieldData"]["BW_Seminare::seminarnr"],
                                bezeichnung: value["fieldData"]["BW_Seminare::Bezeichnung"],
                                beginn: formatDateDMY(value["fieldData"]["BW_Seminare::Datum"]),
                                ende: formatDateDMY(value["fieldData"]["BW_Seminare::Endedatum"]),
                                ort: value["fieldData"]["BW_Seminare::Ort"]
                            };
                        });
                    } else {
                        this.text_error = res.error_msg ? res.error_msg : res.messages[0].message;
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                    }
                }else{
                    console.log('Token expired!');
                }
                this.loading = false;
                this.openModal('modalSchulungen');
            });
        },
        // copy-past from MeineMitarbeiter.vue
        openModal(modalId) {
            const openModal = document.querySelector("[modal-id=" + modalId + "]");
            openModal.setAttribute("visible", "true");
            this.error_msg ='';
        },
        closeModal(modalId) {
            this.ma_befTable = [];
            this.ma_schuTable = [];
            this.Mitarbeiter_ID = '';
            this.sub_title = ''
            const closeModal = document.querySelector("[modal-id=" + modalId + "]");
            closeModal.setAttribute("visible", "false");
            this.error_msg ='';
        },
        exportPdf_Befaehigungen() {
            if (this.ma_befTable.length) {
                // initialize
                var doc = new jsPDF({
                  orientation: 'landscape',
                  unit: 'px',
                  format: 'a4',
                  putOnlyUsedFonts:true
                });
                // get date
                let objectDate = new Date();
                let day = objectDate.getDate();
                let month = objectDate.getMonth() + 1;
                let year = objectDate.getFullYear();
                if (day < 10) {
                  day = '0' + day;
                }
                if (month < 10) {
                  month = '0' + month;
                }
                // set variables
                let totalPages = '{total_pages_count_string}';
                let date_footer = `${day}.${month}.${year}`;
                let date_filename = `${day}${month}${year}`;
                let logo = '../images/Logo/logo_pdf.png';
                let sub_title = this.sub_title;

                autoTable(doc, {
                headStyles: { fillColor : [0, 143, 189] },
                styles: { fontSize: 12 },
                head: [['Nummer', 'Name', 'gültig bis']],
                columns: [
                  {dataKey: 'nummer', header: 'Nummer'},
                  {dataKey: 'name', header: 'Name'},
                  {dataKey: 'gueltigkeit', header: 'gültig bis'}
                ],
                body: this.ma_befTable,
                didDrawPage: function () {
                    // Footer
                    var str = 'Seite ' + doc.internal.getNumberOfPages()
                    if (typeof doc.putTotalPages === 'function') {
                      str = str + ' von ' + totalPages
                    }
                    // Header
                    doc.setFont(undefined, 'bold');
                    doc.setFontSize(18);
                    doc.text('Mitarbeiter Befähigungen', 30, 30);
                    doc.setFontSize(14);
                    doc.setFont(undefined, 'normal');
                    doc.text(sub_title, 30, 46);
                    doc.setDrawColor(255, 0, 0);
                    doc.line(30, 418, 600, 418);
                    doc.addImage(logo, 'PNG', 494, 20);
                    doc.setFontSize(10);
                    doc.text(str, 554, 430);
                    doc.text(date_footer, 30, 430);
                },
                showHead: 'everyPage',
                margin: {top: 70},
                });
                if (typeof doc.putTotalPages === 'function') {
                    doc.putTotalPages(totalPages)
                }
                doc.save('befaehigungen_mitarbeiter' + date_filename + '.pdf')
            }
            else {
                this.text_error = 'No data to export!';
                this.message_visible = true;
                setTimeout(() => this.message_visible = false, 4000);
            }
        }
    },
};
</script>
