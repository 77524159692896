<template>

    <eon-ui-error-message style="" icon-name="radio_checkbox_tick" :is-visible="message_visible" inner-background="eon-bag-green" class="hydrated" fixed-position="true">
        <eon-ui-headline slot="headline" size="h5" :text="text_error" class="hydrated"></eon-ui-headline>
    </eon-ui-error-message>

    <!-- <eon-ui-section height="100px">
        <eon-ui-image lazy src="https://www.bayernwerk-shop.de/media/image/55/38/53/Header-Schulungen_1920x1080.jpg"
            alt="Technisches Trainingscenter der Bayernwerk Netz GmbH"
            title="Technisches Trainingscenter der Bayernwerk Netz GmbH" use-as-background="true">
        </eon-ui-image>
    </eon-ui-section> -->

    <eon-ui-section class="hydrated">

        <!-- <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Übersicht</a>
                <a href="/katalog/seminartyp">Seminartyp</a>
            </eon-ui-breadcrumb>
        </div> -->

        <eon-ui-grid-control grid="grid__d8-4__t8-4">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">

                <eon-ui-headline size="h4" text="Schulungskatalog" class="hydrated text-blue"></eon-ui-headline>

                    <eon-ui-text text-style="rtf" v-if="this.seminartyp != ''">Seminartyp: {{ this.seminartyp }}</eon-ui-text>
                    
            </eon-ui-grid-control-column>
        </eon-ui-grid-control>

        <eon-ui-data-table class="hydrated" paging="true" zebra="true" selectable-rows="false"
            expandable-rows="false" page-sizes="10,20,50,100" :initial-page-size="query.limit" :total-items="query.total"
            @pagechanged="pageChanged" @pagedecreased="pageDecreased" @pageincreased="pageIncreased" @pagesizechanged="pageSizeChanged"
            @selectionchanged="selectionChanged" @sortchanged="sortChanged">
            <eon-ui-table-head>
                <eon-ui-table-head-row>
                    <eon-ui-table-head-cell class="" sortable="false" sort-key="id_kurs" :sort-order="sort.id_kurs">Nr.</eon-ui-table-head-cell>
                    <eon-ui-table-head-cell class="" sortable="false" sort-key="extra_nr" :sort-order="sort.extra_nr">Titel</eon-ui-table-head-cell>
                    <eon-ui-table-head-cell class="" sortable="false" sort-key="datum" :sort-order="sort.datum">Beginn</eon-ui-table-head-cell>
                    <eon-ui-table-head-cell class="" sortable="false" sort-key="endedatum" :sort-order="sort.endedatum">Ende</eon-ui-table-head-cell>
                    <eon-ui-table-head-cell class="" sortable="false" sort-key="gruppe_kurs" :sort-order="sort.gruppe_kurs">Seminarart</eon-ui-table-head-cell>
                    <eon-ui-table-head-cell class="" sortable="false" sort-key="ort" :sort-order="sort.ort">Ort</eon-ui-table-head-cell>
                </eon-ui-table-head-row>
            </eon-ui-table-head>
            <eon-ui-table-body v-show="dataTable">
                <eon-ui-table-row v-for="item in dataTable" :key="item?.ID_Kurs">
                    <eon-ui-table-cell :value="item?.extra_nr">
                        <router-link :to="{ name: 'seminareDetail', params: { id: item?.ID_Kurs } }"><eon-ui-link size="small" :text="item?.extra_nr" target="_self"
                            hide-icon="true" class="hydrated katalog-link" scheme="turquoise"></eon-ui-link></router-link>
                    </eon-ui-table-cell>
                    <eon-ui-table-cell :value="item?.Bezeichnung">
                        <router-link :to="{ name: 'seminareDetail', params: { id: item?.ID_Kurs } }"><eon-ui-link size="small" :text="item?.Bezeichnung" target="_self"
                            hide-icon="true" class="hydrated katalog-link" scheme="turquoise"></eon-ui-link></router-link>
                    </eon-ui-table-cell>
                    <eon-ui-table-cell :value="item?.Datum">{{ item?.Datum }}</eon-ui-table-cell>
                    <eon-ui-table-cell :value="item?.Endedatum">{{ item?.Endedatum }}</eon-ui-table-cell>
                    <eon-ui-table-cell :value="item?.gruppe_kurs">{{ item?.gruppe_kurs }}</eon-ui-table-cell>
                    <eon-ui-table-cell :value="item?.Ort">{{item?.Ort}}</eon-ui-table-cell>
                </eon-ui-table-row>
            </eon-ui-table-body>
        </eon-ui-data-table>

    </eon-ui-section>

    <div class="loading-section" :class="loading ? 'show' : ''">
        <eon-ui-preloader-circle></eon-ui-preloader-circle>
    </div>

</template>

<script>
import Resource from "../../composables/bayernwerk.js";
import { formatDateDMY } from "../../utils/filter";
import _ from "lodash";
const bayernwerkResource = new Resource();

export default {
    name: "Seminartyp",
    data() {
        return {
            dataTable: [],
            seminartyp: '',
            query:
            {
                ds_id: this.$route.params.id,
                page: 1,
                limit: 10,
                total: 10
            },
            sort: {
                id_kurs: "",
                extra_nr: "",
                datum: "",
                endedatum: "",
                gruppe_kurs: "",
                ort: "",
            },
            loading: false,
            text_error: "",
            message_visible: false
        };
    },
    computed: {
        user_token() {
            return this.$store.state.token;
        },
    },
    watch: {
        user_token(newUserData, oldUserData) {
            this.fetchDataSeminartyp(this.query);
        },
    },
    mounted() {
        this.fetchDataSeminartyp(this.query);
    },
    created() {
    },
    setup() {
    },
    methods: {
        fetchDataSeminartyp(query) {
            this.loading = true;
            this.dataTable = [];
            const offset = (query.page - 1) * query.limit + 1;
            query.offset = offset;
            bayernwerkResource.getListSeminartyp(query).then((res) => {

                if (res != null) {
                    console.log(res);
                    this.seminartyp = res.response.data[0]['fieldData']['name'];
                    this.query.total = _.get(res, "response.dataInfo.foundCount", 0);
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data[0]['portalData']['BW_Seminare'];
                        this.dataTable = data_array.map((value) => {
                        return {
                            Bezeichnung: value['BW_Seminare::Bezeichnung'],
                            Datum: formatDateDMY(value['BW_Seminare::Datum']),
                            Endedatum: formatDateDMY(value['BW_Seminare::Endedatum']),
                            Ort: value['BW_Seminare::Ort'],
                            ID_Kurs: value['BW_Seminare::ID_Kurs'],
                            extra_nr: value['BW_Seminare::extra_nr'],
                            gruppe_kurs: value['BW_Seminare::gruppe_kurs']
                        };
                    });
                    console.log(this.dataTable);
                } else {
                    this.text_error = "Keine Ergebnisse gefunden.";
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        const code = res.messages[0].code;
                        if (code == 952 || code == 10) {
                            this.logoutTokenExpired();
                        }
                    }
                }else{
                    this.text_error = "Token expired";
                    this.message_visible = true;
                    this.logoutTokenExpired();
                }
            this.loading = false;
            }).catch((error) => {
                this.loading = false;
                const code = _.get(error, "response.data.messages[0].code", 0);
                if (code == 952 || code == 10) {
                    this.logoutTokenExpired();
                }
            });
        },
        pageChanged(event) {
            this.query.page = event.detail;
            this.fetchDataSeminartyp(this.query);
        },
        pageDecreased(event) {
            this.query.page = event.detail;
            this.fetchDataSeminartyp(this.query);
        },
        pageIncreased(event) {
            this.query.page = event.detail;
            this.fetchDataSeminartyp(this.query);
        },
        pageSizeChanged(event) {
            this.query.limit = event.detail;
            this.query.page = 1;
            this.fetchDataSeminartyp(this.query);
        },
        selectionChanged(event) {
            // console.log('selectionChanged',event);
        },
        sortChanged(event) {
            this.sort = {
                id_kurs: "",
                extra_nr: "",
                datum: "",
                endedatum: "",
                gruppe_kurs: "",
                ort: ""
            }
            this.sort[event.detail.sortKey] = event.detail.sortOrder;
        },
        logoutTokenExpired() {
            this.$store.dispatch("user/logout");
            setTimeout(() => this.$router.go(), this.$router.push({ name: "startseite" }), 4000);
        },
        resetSort() {
            console.log('resetSort');
            this.sort = {
                id_kurs: "",
                extra_nr: "",
                datum: "",
                endedatum: "",
                gruppe_kurs: "",
                ort: ""
            }
        }
    },
};
</script>
