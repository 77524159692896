<template>

    <eon-ui-error-message style="" icon-name="radio_checkbox_tick" :is-visible="message_visible && text_error_message != ''"
        inner-background="eon-bag-green" class="hydrated" fixed-position="true">
        <eon-ui-headline slot="headline" size="h5" :text="text_error_message" class="hydrated"></eon-ui-headline>
    </eon-ui-error-message>

    <eon-ui-section class="hydrated">

        <!-- <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Startseite</a>
                <a href="/meine_mitarbeiter">Meine Mitarbeiter</a>
            </eon-ui-breadcrumb>
        </div> -->

        <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">

                <eon-ui-headline text="Hierfür ist eine Anmeldung erforderlich." size="h4" class="hydrated text-blue" v-if="ds_id == ''"></eon-ui-headline>

                <div v-else>

                    <!-- <eon-ui-tabs active-tab-index="0" inner-background="eon-lightgrey" scheme="lightgrey">
                        <eon-ui-tab header="Meine Mitarbeiter" class="hydrated"> -->

                            <eon-ui-headline text="Meine Mitarbeiter" size="h4" class="hydrated text-blue" v-if="ds_id != ''"></eon-ui-headline>
                            <eon-ui-headline text="Hierfür ist eine Anmeldung erforderlich." size="h4" class="hydrated text-blue" v-else></eon-ui-headline>

                            <div class="pb-4 pt-4">
                                <eon-ui-text text-style="copy">Hier finden Sie die Ihnen zugeteilten Mitarbeiter.</eon-ui-text>
                            </div>

                            <eon-ui-data-table zebra="true" paging="true" page-sizes="10,20,50,100" :initial-page-size="query.limit" :total-items="query.total"
                            server-mode="true"
                            ref="tbl" v-if="dataTable.length > 0"
                            @pagechanged="pageChanged" @pagedecreased="pageChanged" @pageincreased="pageChanged" @pagesizechanged="pageSizeChanged"
                            @selectionchanged="selectionChanged" @sortchanged="sortChanged" selectable-rows="false" expandable-rows="false" name="maTable" id="maTable">
                                <eon-ui-table-toolbar slot="table-toolbar" @searchtermchanged="getSearchValueTeilnehmerFK"></eon-ui-table-toolbar>
                                <eon-ui-table-head>
                                    <eon-ui-table-head-row>
                                        <eon-ui-table-head-cell sortable="true" sort-key="anrede">Anrede</eon-ui-table-head-cell>
                                        <eon-ui-table-head-cell sortable="true" sort-key="vorname">Vorname</eon-ui-table-head-cell>
                                        <eon-ui-table-head-cell sortable="true" sort-key="nachname" sort-order="asc">Nachname</eon-ui-table-head-cell>
                                        <eon-ui-table-head-cell sortable="true" sort-key="pers_nr">Personalnummer</eon-ui-table-head-cell>
                                        <eon-ui-table-head-cell sortable="true" sort-key="kid">KID</eon-ui-table-head-cell>
                                        <eon-ui-table-head-cell sortable="true" sort-key="znr">Zangen-Nr.</eon-ui-table-head-cell>
                                        <eon-ui-table-head-cell sortable="false">Informationen</eon-ui-table-head-cell>
                                    </eon-ui-table-head-row>
                                </eon-ui-table-head>
                                <eon-ui-table-body>
                                    <eon-ui-table-row v-for="item in dataTable" :key="item.id">
                                        <eon-ui-table-cell :value="item.anrede"> {{ item.anrede }} </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="item.vorname"> {{ item.vorname }} </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="item.nachname"> {{ item.nachname }} </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="item.pers_nr" v-if="item.pers_nr !=''"> {{ item.pers_nr }} </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="item.pers_nr" v-else> - </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="item.kid" v-if="item.kid !=''"> {{ item.kid }} </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="item.kid" v-else> - </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="item.znr" v-if="item.znr !=''"> {{ item.znr }} </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="item.znr" v-else> - </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="item.befaehigungen" v-if="(item.count_bef != 0 && item.count_bef != '') || (item.count_schu != 0 && item.count_schu != '')">
                                            <eon-ui-button :text="item.count_bef < 10 ? 'BF (0' + item.count_bef + ')' : 'BF (' + item.count_bef + ')'" size="small"
                                                class="btn-custom-blue mr-2" v-on:click="fetchTeilnehmerBefaehigungen(item.id, item.anrede, item.vorname, item.nachname)"
                                                v-if="item.count_bef > 0" title="Befähigungen">
                                            </eon-ui-button>
                                            <eon-ui-button :text="item.count_schu < 10 ? 'SG (0' + item.count_schu + ')' : 'SG (' + item.count_schu + ')'" size="small"
                                                class="btn-custom-blue mt-1" v-on:click="fetchTeilnehmerSchulungen(item.id, item.anrede, item.vorname, item.nachname)"
                                                v-if="item.count_schu > 0" title="Schulungen">
                                            </eon-ui-button>
                                        </eon-ui-table-cell>
                                        <eon-ui-table-cell v-else> - </eon-ui-table-cell>
                                    </eon-ui-table-row>
                                </eon-ui-table-body>
                            </eon-ui-data-table>

                            <div v-else>
                                <eon-ui-text text-style="copy">Sie haben keine aktiven Mitarbeiter</eon-ui-text>
                            </div>

                            <eon-ui-divider class="mb-6"></eon-ui-divider>

                        <!-- </eon-ui-tab>
                        <eon-ui-tab header="Befähigungen" class="hydrated"> -->

                            <eon-ui-headline text="Befähigungen" size="h4" class="hydrated text-blue"></eon-ui-headline>

                            <div class="pb-4 pt-4">
                                <eon-ui-text text-style="copy">Hier ist eine Liste aller Befähigungen einsehbar.</eon-ui-text>
                            </div>

                            <eon-ui-data-table zebra="true" paging="true" page-sizes="10,20,50,100" :initial-page-size="bef_query.limit" :total-items="bef_query.total"
                            server-mode="true"
                            ref="befTbl"
                            onPageChanged="alert('test');"
                            @pagechanged="befpageChanged" @pagedecreased="befpageDecreased" @pageincreased="befpageIncreased" @pagesizechanged="befpageSizeChanged"
                            @selectionchanged="selectionChanged" @sortchanged="befSortChanged" selectable-rows="false" expandable-rows="false" id="befTable">
                                <eon-ui-table-toolbar slot="table-toolbar" @searchtermchanged="getSearchValueBefaehigungenFK"></eon-ui-table-toolbar>
                                <eon-ui-table-head>
                                    <eon-ui-table-head-row>
                                        <eon-ui-table-head-cell sortable="true" sort-key="nummer">Nummer</eon-ui-table-head-cell>
                                        <eon-ui-table-head-cell sortable="true" sort-key="name">Name</eon-ui-table-head-cell>
                                        <eon-ui-table-head-cell sortable="true" sort-key="gueltigkeit" sort-order="asc">gültig bis</eon-ui-table-head-cell>
                                        <eon-ui-table-head-cell sortable="true" sort-key="vorname">Vorname</eon-ui-table-head-cell>
                                        <eon-ui-table-head-cell sortable="true" sort-key="nachname">Nachname</eon-ui-table-head-cell>
                                    </eon-ui-table-head-row>
                                </eon-ui-table-head>
                                <eon-ui-table-body>
                                    <eon-ui-table-row v-for="bef in befTable" :key="bef.id">
                                        <eon-ui-table-cell :value="bef.nummer"> {{ bef.nummer }} </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="bef.name"> {{ bef.name }} </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="bef.gueltigkeit"> {{ bef.gueltigkeit }} </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="bef.vorname"> {{ bef.vorname }} </eon-ui-table-cell>
                                        <eon-ui-table-cell :value="bef.nachname"> {{ bef.nachname }} </eon-ui-table-cell>
                                    </eon-ui-table-row>
                                </eon-ui-table-body>
                            </eon-ui-data-table>

                            <eon-ui-divider class="mb-6"></eon-ui-divider>

                        <!-- </eon-ui-tab>
                        <eon-ui-tab header="Befähigungen" class="hydrated"> -->

                            <eon-ui-headline text="Auswertungen" size="h4" class="hydrated text-blue"></eon-ui-headline>

                            <div class="pb-4 pt-4">
                                <eon-ui-text text-style="copy">Hier können Sie Auswertungen zu Ihren Mitarbeitern und deren Befähigungen erstellen.</eon-ui-text>
                            </div>

                            <eon-ui-grid-control grid="grid__d2-8-2" class="hydrated" content-position-col-3="bottom">
                                <eon-ui-grid-control-column slot="column-1" class="hydrated">
                                    <div>
                                        <eon-ui-text text-style="copy">Bezugsjahr:</eon-ui-text>
                                        <eon-ui-dropdown :selected="year" required name="jahr" class="hydrated mt-4" size="small" id="jahr">
                                            <eon-ui-dropdown-option class="hydrated" :value="year - 1" :label="year - 1"></eon-ui-dropdown-option>
                                            <eon-ui-dropdown-option class="hydrated" :value="year" :label="year"></eon-ui-dropdown-option>
                                            <eon-ui-dropdown-option class="hydrated" :value="year + 1" :label="year + 1"></eon-ui-dropdown-option>
                                        </eon-ui-dropdown>
                                    </div>
                                </eon-ui-grid-control-column>
                                <eon-ui-grid-control-column slot="column-2" class="hydrated">
                                    <div>
                                        <eon-ui-text text-style="copy">Auswertung:</eon-ui-text>
                                        <eon-ui-dropdown value="auslBefaehigungen" required  name="auswertung" class="hydrated mt-4" size="small" id="auswertung">
                                            <eon-ui-dropdown-option class="hydrated" value="auslBefaehigungen" label="auslaufende Befähigungen"></eon-ui-dropdown-option>
                                            <eon-ui-dropdown-option class="hydrated" value="schulungsteilnahmen" label="Schulungsteilnahmen"></eon-ui-dropdown-option>
                                        </eon-ui-dropdown>
                                    </div>
                                </eon-ui-grid-control-column>
                                <eon-ui-grid-control-column slot="column-3" class="hydrated">
                                    <div>
                                        <eon-ui-button text="erstellen" size="small" class="btn-custom-blue" v-on:click="btnAuswertungen()"></eon-ui-button>
                                    </div>
                                </eon-ui-grid-control-column>
                            </eon-ui-grid-control>

                            <!-- spacer -->
                            <div class="pb-4 pt-4"></div>

                        <!-- </eon-ui-tab>
                    </eon-ui-tabs> -->

                </div>
            </eon-ui-grid-control-column>
        </eon-ui-grid-control>
    </eon-ui-section>

    <div class="loading-section" :class="loading ? 'show' : ''">
        <eon-ui-preloader-circle></eon-ui-preloader-circle>
    </div>

    <eon-ui-modal modal-id="modalBefaehigungen" name="modalBefaehigungen" show-closing-x="true" @modalclose="clear_cache('modalBefaehigungen')">

        <eon-ui-headline text="Befähigungen" size="h4" class="hydrated text-blue"></eon-ui-headline>

        <eon-ui-text text-style="copy" class="hydrated">{{ sub_title }}</eon-ui-text>

        <div class="hydrated" v-if="ma_befTable == ''">Keine Befähigungen gefunden.</div>

        <div v-else>
            <eon-ui-data-table class="hydrated" zebra="true" selectable-rows="false" expandable-rows="false">
                <eon-ui-table-head>
                    <eon-ui-table-head-row>
                        <eon-ui-table-head-cell sort-key="befaehigung_id">Nummer</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell sort-key="name">Name</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell sort-key="gueltigkeit">gültig bis</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell></eon-ui-table-head-cell>
                    </eon-ui-table-head-row>
                </eon-ui-table-head>
                <eon-ui-table-body>
                    <eon-ui-table-row v-for="ma_bef in ma_befTable" :key="ma_bef.befaehigung_id">
                        <eon-ui-table-cell :value="ma_bef.nummer" v-if="ma_bef.aktuell !=''"> {{ ma_bef.nummer }} </eon-ui-table-cell>
                        <eon-ui-table-cell :value="ma_bef.name" v-if="ma_bef.aktuell !=''"> {{ ma_bef.name }} </eon-ui-table-cell>
                        <eon-ui-table-cell :value="ma_bef.gueltigkeit" v-if="ma_bef.aktuell !='' && ma_bef.gueltigkeit != ''"> {{ ma_bef.gueltigkeit }} </eon-ui-table-cell>
                        <eon-ui-table-cell v-if="ma_bef.aktuell !=''">
                            <span v-if="ma_bef.zulassungseinschraenkungstext != '' && ma_bef.entzogen == 0 && ma_bef.aktuell !=''"><br /><br /></span>
                            <span v-if="ma_bef.entzogen == 1 && ma_bef.aktuell !=''">entzogen</span>
                        </eon-ui-table-cell>
                    </eon-ui-table-row>
                </eon-ui-table-body>
            </eon-ui-data-table>
        </div>

        <div class="mt-3">
            <eon-ui-button text="Drucken" size="small" class="btn-custom-blue" v-on:click="exportPdf_Befaehigungen()"></eon-ui-button>
        </div>

        <div class="loading-section" :class="loading ? 'show' : ''">
            <eon-ui-preloader-circle></eon-ui-preloader-circle>
        </div>

    </eon-ui-modal>

    <eon-ui-modal modal-id="modalSchulungen" name="modalSchulungen" show-closing-x="true" @modalclose="clear_cache('modalSchulungen')">

        <eon-ui-headline text="Schulungen" size="h4" class="hydrated text-blue"></eon-ui-headline>

        <eon-ui-text text-style="copy" class="hydrated">{{ sub_title }}</eon-ui-text>

        <div class="hydrated" v-if="ma_schuTable == ''">Keine Schulungen gefunden.</div>

        <div v-else>
            <eon-ui-data-table class="hydrated" zebra="false" selectable-rows="false" expandable-rows="false">
                <eon-ui-table-head>
                    <eon-ui-table-head-row>
                        <eon-ui-table-head-cell>Nummer</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell>Bezeichnung</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell sortable="true" sort-key="type" sort-order="dsc">Beginn</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell>Ende</eon-ui-table-head-cell>
                        <eon-ui-table-head-cell>Ort</eon-ui-table-head-cell>
                    </eon-ui-table-head-row>
                </eon-ui-table-head>
                <eon-ui-table-body>
                    <eon-ui-table-row v-for="item in ma_schuTable" :key="item.id">
                        <eon-ui-table-cell :value="item.nummer" v-if="item.seminar_id != ''">
                            <router-link :to="{ name: 'seminareDetail', params: { id: item.seminar_id } }">
                                <eon-ui-link size="small" :text="item.nummer" target="_self" hide-icon="true" class="hydrated fs-14" scheme="turquoise"
                                    v-on:click="closeModal('modalSchulungen')"></eon-ui-link>
                            </router-link>
                        </eon-ui-table-cell>
                        <eon-ui-table-cell :value="item.bezeichnung" v-if="item.seminar_id != ''">
                            <router-link :to="{ name: 'seminareDetail', params: { id: item.seminar_id } }">
                                <eon-ui-link size="small" :text="item.bezeichnung" target="_self" hide-icon="true" class="hydrated fs-14" scheme="turquoise"
                                    v-on:click="closeModal('modalSchulungen')">
                                </eon-ui-link>
                            </router-link>
                        </eon-ui-table-cell>
                        <eon-ui-table-cell :value="item.beginn" v-if="item.seminar_id != ''"> {{ item.beginn }} </eon-ui-table-cell>
                        <eon-ui-table-cell :value="item.ende" v-if="item.seminar_id != ''"> {{ item.ende }} </eon-ui-table-cell>
                        <eon-ui-table-cell :value="item.ort" v-if="item.seminar_id != '' && item.ort != ''"> {{ item.ort }} </eon-ui-table-cell>
                        <eon-ui-table-cell :value="item.ort" v-if="item.seminar_id != '' && item.ort == ''"> - </eon-ui-table-cell>
                    </eon-ui-table-row>
                </eon-ui-table-body>
            </eon-ui-data-table>
        </div>

        <div class="loading-section" :class="loading ? 'show' : ''">
            <eon-ui-preloader-circle></eon-ui-preloader-circle>
        </div>

    </eon-ui-modal>

</template>

<script>
import Resource from "../../composables/bayernwerk";
import { formatDateDMY } from "../../utils/filter";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import _ from "lodash";

const bayernwerkResource = new Resource();

export default {
    name: "MeineMitarbeiter",
    data() {
        return {
            dataTable: [],
            befTable: [],
            ma_befTable: [],
            ma_schuTable: [],
            bef_jahrTable: [],
            schu_jahrTable: [],
            sub_title: '',
            ds_id: this.$store.state.ds_id,
            Mitarbeiter_ID: '',
            dataTable_switch: 0,
            dataTable_searchValue: '',
            befTable_switch: 0,
            befTable_searchValue: '',
            // variable year because of dropdown menu
            year: 0,
            document: '',
            query:
            {
                page: 1,
                limit: 10,
                total: 10,
                ds_id: this.$store.state.ds_id,
                aktiv: 1,
                sort:{sortOrder: 'asc', sortKey: 'nachname'}
            },
            bef_query:
            {
                page: 1,
                limit: 10,
                total: 10,
                ds_id: this.$store.state.ds_id,
                aktuell: 1,
                entzogen: 0,
                sort:{sortOrder: 'asc', sortKey: 'nachname'}
            },
            loading: false,
            text_error: "",
            message_visible: false,
            text_error_message: ''
        };
    },
    computed: {
    user_token() {
      return this.$store.state.token;
    },
  },
    watch: {
        user_token(newUserData, oldUserData) {
            this.ds_id = this.$store.state.ds_id;
            this.fetchTeilnehmerFuehrungskraft(this.query);
            this.fetchBefaehigungenFuehrungskraft(this.bef_query);
        }
    },
    mounted(){
        let checkExitsModal = document.getElementsByName("modalBefaehigungen").length;
        if(checkExitsModal > 1 ){
            document.getElementsByName("modalBefaehigungen")[0].outerHTML="";
            document.getElementsByName("modalSchulungen")[0].outerHTML="";
            document.getElementsByName("modalBefaehigungen")[0].remove();
            document.getElementsByName("modalSchulungen")[0].remove();
        }
        this.ds_id = this.$store.state.ds_id;
        this.fetchTeilnehmerFuehrungskraft(this.query);
        this.fetchBefaehigungenFuehrungskraft(this.bef_query);
        // this.getYear();
        
        this.year = new Date().getFullYear();
    },
    created() {
    },
    setup() {
    },
    methods: {        
        fetchTeilnehmerFuehrungskraft(query) {
            this.loading = true;
            const offset = (query.page - 1) * query.limit + 1;
            query.offset = offset;
            // console.log(query);
            bayernwerkResource.getTeilnehmerFuehrungskraft(query).then((res) => {
                if (res != null) {
                    let total =  _.get(res, "response.dataInfo.foundCount", this.query.total);
                    if(total != this.query.total){
                        this.query.total = total;
                    }
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;
                        this.dataTable = this.sort(data_array.map((value) => {
                            return {
                                nachname: value.fieldData.OrPe_Nachname,
                                vorname: value.fieldData.OrPe_Vorname,
                                anrede: value.fieldData.FrauHerr,
                                titel: value.fieldData.OrPe_Titel,
                                onlineportal_id: value.fieldData.onlineportal_id,
                                pers_nr: value.fieldData.OrPe_PersNummer,
                                kid: value.fieldData.OrPe_LoginID,
                                znr: value["fieldData"]["zangen_teilnehmer::zangen_nr"],
                                id: value.fieldData.OrPe_TeTra_ID,
                                count_bef: value.fieldData.nummer_anzeige_b,
                                count_schu: value.fieldData.nummer_anzeige_t
                            };
                        }), this.query.sort);
                        
                        let state = {pageSize:query.limit, begin: offset - 1, end: (offset + query.limit - 1 > total ? total: offset + query.limit - 1),totalItems:total,currentPage:query.page,searchTerm:''};
                        this.$refs.tbl.setTableState(state);
                    } else {
                        this.text_error = res.error_msg;
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        const code = res.messages[0].code;
                        if (code == 952 || code == 10) {
                            this.logoutTokenExpired();
                        }
                    }
                }else{
                    this.text_error = "Token expired";
                    this.message_visible = true;
                    this.logoutTokenExpired();
                }
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                console.log("er", error);
                const code = _.get(error, "response.data.messages[0].code", 0);
                if (code == 952 || code == 10) {
                    this.logoutTokenExpired();
                }
            });
        },
        fetchBefaehigungenFuehrungskraft(bef_query) {
            this.loading = true;
            const offset = (bef_query.page - 1) * bef_query.limit + 1;
            bef_query.offset = offset;
            bayernwerkResource.getBefaehigungenFuehrungskraft(bef_query).then((res) => {
                if (res != null) {
                    this.bef_query.total = _.get(res, "response.dataInfo.foundCount", 0);
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;
                        this.befTable = this.sort(data_array.map((value) => {
                            return {
                                id: value.fieldData.ds_id,
                                nummer: value.fieldData.nr,
                                name: value.fieldData.name,
                                gueltigkeit: formatDateDMY(value.fieldData.gültigkeit_datum_anzeige),
                                vorname: value["fieldData"]["BW_Teilnehmer::OrPe_Vorname"],
                                nachname: value["fieldData"]["BW_Teilnehmer::OrPe_Nachname"]
                            };
                        }), this.bef_query.sort) ;
                        
                        let state = {pageSize:bef_query.limit, begin: offset - 1, end: (offset + bef_query.limit - 1 > this.bef_query.total ? this.bef_query.total: offset + bef_query.limit - 1),totalItems:this.bef_query.total,currentPage:bef_query.page,searchTerm:''};
                        this.$refs.befTbl.setTableState(state);
                    } else {
                        this.text_error = res.error_msg;
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        const code = res.messages[0].code;
                        if (code == 952 || code == 10) {
                            this.logoutTokenExpired();
                        }
                    }
                }else{
                    this.text_error = "Token expired";
                    this.message_visible = true;
                    this.logoutTokenExpired();
                }
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                console.log("er", error);
                const code = _.get(error, "response.data.messages[0].code", 0);
                if (code == 952 || code == 10) {
                    this.logoutTokenExpired();
                }
            });
        },
        fetchTeilnehmerBefaehigungen(Mitarbeiter_ID, anrede, vorname, nachname) {
            this.Mitarbeiter_ID = Mitarbeiter_ID;
            this.loading = true;
            this.ma_befTable = [];
            this.sub_title = (anrede == 'Herr')?
               'von ' + anrede + 'n' : 'von ' + anrede;

            this.sub_title += ' ' + vorname + ' ' + nachname;

            bayernwerkResource.getListBefaehigungen({Mitarbeiter_ID: Mitarbeiter_ID}).then((res) => {
                if (res != null) {
                    this.valid = true;
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;
                        this.ma_befTable = data_array.map((value) => {
                        return {
                            name: value.fieldData.name,
                            nummer: value.fieldData.nr,
                            aktuell: value.fieldData.aktuell,
                            entzogen: value.fieldData.entzogen,
                            gueltigkeit: formatDateDMY(value.fieldData.gültigkeit_datum_anzeige),
                            zulassungseinschraenkung: value.fieldData.Zulassungseinschraenkung,
                            zulassungseinschraenkungstext: value.fieldData.Zulassungseinschraenkungstext
                        };
                    });
                    } else {
                        this.text_error = res.error_msg ? res.error_msg : res.messages[0].message;
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                    }
                }else{
                    console.log('Token expired!');
                }
                this.loading = false;
                this.openModal('modalBefaehigungen');
            });
        },
        fetchTeilnehmerSchulungen(Mitarbeiter_ID, anrede, vorname, nachname) {
            this.Mitarbeiter_ID = Mitarbeiter_ID;
            this.loading = true;
            this.ma_schuTable = [];
            this.sub_title = (anrede == 'Herr')?
               'von ' + anrede + 'n': 'von ' + anrede;
            
            this.sub_title += ' ' + vorname + ' ' + nachname;

            bayernwerkResource.getListSchulungen({ma_id: Mitarbeiter_ID}).then((res) => {
                if (res != null) {
                    this.valid = true;
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;
                        this.ma_schuTable = data_array.map((value) => {
                            return {
                                id: value.fieldData.ds_id,
                                seminar_id: value.fieldData.seminar_id,
                                nummer: value["fieldData"]["BW_Seminare::seminarnr"],
                                bezeichnung: value["fieldData"]["BW_Seminare::Bezeichnung"],
                                beginn: formatDateDMY(value["fieldData"]["BW_Seminare::Datum"]),
                                ende: formatDateDMY(value["fieldData"]["BW_Seminare::Endedatum"]),
                                ort: value["fieldData"]["BW_Seminare::Ort"]
                            };
                        });
                    } else {
                        this.text_error = res.error_msg ? res.error_msg : res.messages[0].message;
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                    }
                }else{
                    console.log('Token expired!');
                }
                this.loading = false;
                this.openModal('modalSchulungen');
            });
        },
        SearchTeilnehmerFK() {
            this.loading = true;
            this.dataTable = [];
            const offset = (this.query.page - 1) * this.query.limit + 1;
            bayernwerkResource.getSearchResultTeilnehmerFK(this.$store.state.ds_id, this.dataTable_searchValue, offset, this.query.limit).then((res) => {
                if (res != null) {
                    this.query.total = _.get(res, "response.dataInfo.foundCount", 0);
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;
                        this.dataTable = this.sort(data_array.map((value) => {
                            return {
                                nachname: value.fieldData.OrPe_Nachname,
                                vorname: value.fieldData.OrPe_Vorname,
                                anrede: value.fieldData.FrauHerr,
                                titel: value.fieldData.OrPe_Titel,
                                onlineportal_id: value.fieldData.onlineportal_id,
                                pers_nr: value.fieldData.OrPe_PersNummer,
                                kid: value.fieldData.OrPe_LoginID,
                                znr: value["fieldData"]["zangen_teilnehmer::zangen_nr"],
                                id: value.fieldData.OrPe_TeTra_ID,
                                count_bef: value.fieldData.nummer_anzeige_b,
                                count_schu: value.fieldData.nummer_anzeige_t
                            };
                        }), this.query.sort);
                        let state = {pageSize:query.limit, begin: offset - 1, end: (offset + query.limit - 1 > total ? total: offset + query.limit - 1),totalItems:total,currentPage:query.page,searchTerm:''};
                        this.$refs.tbl.setTableState(state);
                    } else {
                        this.text_error = 'Keine Ergebnisse gefunden.';
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        console.log(res.messages[0].message);
                    }
                }else{
                    console.log('Token expired!');
                }
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                console.log("er", error);
                const code = _.get(error, "response.data.messages[0].code", 0);
                if (code == 952) {
                    setTimeout(() => this.$router.push({ name: "startseite" }), 4000);
                }
            });
            this.dataTable.length = 0;
        },
        SearchBefaehigungenFK() {
            this.loading = true;
            this.befTable.length = 0;
            const offset = (this.bef_query.page - 1) * this.bef_query.limit + 1;
            bayernwerkResource.getSearchResultBefaehigungenFK(this.$store.state.ds_id, this.befTable_searchValue, offset, this.bef_query.limit).then((res) => {
                if (res != null) {
                    this.bef_query.total = _.get(res, "response.dataInfo.foundCount", 0);
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;
                        this.befTable = this.sort(data_array.map((value) => {
                            return {
                                id: value.fieldData.ds_id,
                                nummer: value.fieldData.nr,
                                name: value.fieldData.name,
                                gueltigkeit: formatDateDMY(value.fieldData.gültigkeit_datum_anzeige),
                                vorname: value["fieldData"]["BW_Teilnehmer::OrPe_Vorname"],
                                nachname: value["fieldData"]["BW_Teilnehmer::OrPe_Nachname"]
                            };
                        }), this.bef_query.sort);
                        let state = {pageSize:bef_query.limit, begin: offset - 1, end: (offset + bef_query.limit - 1 > this.bef_query.total ? this.bef_query.total: offset + bef_query.limit - 1),totalItems:this.bef_query.total,currentPage:bef_query.page,searchTerm:''};
                        this.$refs.befTbl.setTableState(state);
                    }
                    else {
                        this.text_error = 'Keine Ergebnisse gefunden.';
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        console.log(res.error_msg);
                    }
                }
                else {
                    console.log('Token expired!');
                }
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                console.log("er", error);
                const code = _.get(error, "response.data.messages[0].code", 0);
                if (code == 952) {
                    setTimeout(() => this.$router.push({ name: "startseite" }), 4000);
                }
            });
            this.befTable.length = 0;
        },
        fetchTeilnehmerBefaehigungenFKJahr(jahr)
        {
            this.loading = true;
            this.bef_jahrTable = [];
            bayernwerkResource.getTeilnehmerBefaehigungenJahr(jahr, this.ds_id, this.$store.state.fuehrungskraft).then((res) => {
                if (res != null) {
                    this.valid = true;
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;
                        this.bef_jahrTable = data_array.map((value, index) => {
                        return {
                            index: index + 1 < 10 ? '0' + (index + 1) : index + 1,
                            nummer: value.fieldData.nr,
                            bezeichnung: value.fieldData.name,
                            vorname: value["fieldData"]["BW_Teilnehmer::OrPe_Vorname"],
                            nachname: value["fieldData"]["BW_Teilnehmer::OrPe_Nachname"],
                            gueltigkeit: formatDateDMY(value.fieldData.gültigkeit_datum)
                        };
                    });
                    this.exportPdf_Auswertungen(jahr);
                    } else {
                        this.text_error = res.error_msg ? res.error_msg : res.messages[0].message;
                        this.text_error_message = 'Keine Datensätze gefunden.';
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                    }
                }else{
                    console.log('Token expired!');
                }
                this.loading = false;
            });
        },
        fetchTeilnehmerSchulungenFKJahr(jahr)
        {
            this.loading = true;
            this.schu_jahrTable = [];
            bayernwerkResource.getTeilnehmerSchulungenJahr(jahr, this.ds_id, this.$store.state.fuehrungskraft).then((res) => {
                if (res != null) {
                    this.valid = true;
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;
                        this.schu_jahrTable = data_array.map((value, index) => {
                        return {
                            index: index + 1 < 10 ? '0' + (index + 1) : index + 1,
                            vorname: value.fieldData.Vorname,
                            nachname: value.fieldData.name,
                            extra_nr: value["fieldData"]["BW_Seminare::extra_nr"],
                            bezeichnung: value["fieldData"]["BW_Seminare::Bezeichnung"],
                            von: formatDateDMY(value["fieldData"]["BW_Seminare::Datum"]),
                            bis: formatDateDMY(value["fieldData"]["BW_Seminare::Endedatum"])
                        };
                    });
                    this.exportPdf_Auswertungen(jahr);
                    } else {
                        this.text_error = res.error_msg ? res.error_msg : res.messages[0].message;
                        this.text_error_message = 'Keine Datensätze gefunden.';
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                    }
                }else{
                    console.log('Token expired!');
                }
                this.loading = false;
            });
        },
        pageChanged(event) {
            if(this.dataTable_switch == 0)
            {
                this.query.page = event.detail;
                this.fetchTeilnehmerFuehrungskraft(this.query);
            }
            else
            {
                this.query.page = event.detail;
                this.SearchTeilnehmerFK();
            }
        },
        pageDecreased(event) {
            if(this.dataTable_switch == 0)
            {
                this.query.page = event.detail;
                this.fetchTeilnehmerFuehrungskraft(this.query);
            }
            else
            {
                this.query.page = event.detail;
                this.SearchTeilnehmerFK();
            }
        },
        pageIncreased(event) {
            console.log('eve ', event);
            if(this.dataTable_switch == 0)
            {
                this.query.page = event.detail;
                this.fetchTeilnehmerFuehrungskraft(this.query);
            }
            else
            {
                this.query.page = event.detail;
                this.SearchTeilnehmerFK();
            }
        },
        pageSizeChanged(event) {
            if(this.dataTable_switch == 0)
            {
                this.query.limit = event.detail;
                this.query.page = 1;
                this.fetchTeilnehmerFuehrungskraft(this.query);
            }
            else
            {
                this.query.limit = event.detail;
                this.query.page = 1;
                this.SearchTeilnehmerFK();
            }
        },
        befpageChanged(event) {
            if(this.befTable_switch == 0)
            {
                this.bef_query.page = event.detail;
                this.fetchBefaehigungenFuehrungskraft(this.bef_query);
            }
            else
            {
                this.bef_query.page = event.detail;
                this.SearchBefaehigungenFK();
            }
        },
        befpageDecreased(event) {
            if(this.befTable_switch == 0)
            {
                this.bef_query.page = event.detail;
                this.fetchBefaehigungenFuehrungskraft(this.bef_query);
            }
            else
            {
                this.bef_query.page = event.detail;
                this.SearchBefaehigungenFK();
            }
        },
        befpageIncreased(event) {
            if(this.befTable_switch == 0)
            {
                this.bef_query.page = event.detail;
                this.fetchBefaehigungenFuehrungskraft(this.bef_query);
            }
            else
            {
                this.bef_query.page = event.detail;
                this.SearchBefaehigungenFK();
            }
        },
        befpageSizeChanged(event) {
            if(this.befTable_switch == 0)
            {
                this.bef_query.limit = event.detail;
                this.bef_query.page = 1;
                this.fetchBefaehigungenFuehrungskraft(this.bef_query);
            }
            else
            {
                this.bef_query.limit = event.detail;
                this.bef_query.page = 1;
                this.SearchBefaehigungenFK();
            }
        },
        selectionChanged() {
            // console.log('selectionChanged',event);
        },
        sort(table, sort){
            // console.log('s', table, sort);
            return table.sort((a,b)=>{
                const nameA = a[sort.sortKey].toUpperCase(); // ignore upper and lowercase
                const nameB = b[sort.sortKey].toUpperCase(); // ignore upper and lowercase
                if (nameA < nameB) {
                    if(sort.sortOrder == 'desc'){
                        return -1;
                    }
                    else{
                        return 1;
                    }
                }
                if (nameA > nameB) {
                    if(sort.sortOrder == 'desc'){
                        return 1;
                    }
                    else {
                        return -1;
                    }
                }
                // names must be equal
                return 0;
            });
        },
        sortChanged(event) {
            if(event.detail){
                if(this.query.sort.sortOrder != event.detail.sortOrder || this.query.sort.sortKey != event.detail.sortKey){
                    this.query.sort = event.detail;
                    this.dataTable = this.sort(this.dataTable, this.query.sort);
                }
            }
        },
        befSortChanged(event) {
            if(event.detail){
                if(this.bef_query.sort.sortOrder != event.detail.sortOrder || this.bef_query.sort.sortKey != event.detail.sortKey){
                    this.bef_query.sort = event.detail;
                    this.befTable = this.sort(this.befTable, this.bef_query.sort);
                }
            }
        },
        clear_cache () {
            this.ma_befTable = [];
            this.ma_schuTable = [];
            this.Mitarbeiter_ID = '';
            this.sub_title = '';
        },
        openModal (modalId) {
            const openModal = document.querySelector("[modal-id=" + modalId + "]");
            openModal.setAttribute("visible", "true");
            this.error_msg ='';
        },
        closeModal (modalId) {
            const closeModal = document.querySelector("[modal-id=" + modalId + "]");
            closeModal.setAttribute("visible", "false");
            this.error_msg ='';
        },
        logoutTokenExpired() {
            this.$store.dispatch("user/logout");
            setTimeout(() => this.$router.go(), this.$router.push({ name: "startseite" }), 4000);
        },
        // getYear() {
        //     const d = new Date();
        //     this.year = d.getFullYear();
        // },
        btnAuswertungen() {
            let auswertung = document.getElementById("auswertung").value;
            let jahr = document.getElementById("jahr").value;

            if(auswertung == 'auslBefaehigungen')
            {
                this.document = 'auslBefaehigungen',
                this.fetchTeilnehmerBefaehigungenFKJahr(jahr);
            }
            if(auswertung == 'schulungsteilnahmen')
            {
                this.document = 'schulungsteilnahmen',
                this.fetchTeilnehmerSchulungenFKJahr(jahr);
            }
        },
        getSearchValueTeilnehmerFK(event) {
            if (event.detail != "") {
                this.dataTable_switch = 1;
                this.dataTable_searchValue = event.detail;
                this.query.page = 1;
                this.query.limit = 10;
                this.query.total = 10;
                this.SearchTeilnehmerFK();
            }
            else {
                this.dataTable_switch = 0;
                this.query.page = 1;
                this.query.limit = 10;
                this.query.total = 10;
                this.fetchTeilnehmerFuehrungskraft(this.query);
            }
        },
        getSearchValueBefaehigungenFK(event) {
            if (event.detail != "") {
                this.befTable_switch = 1;
                this.befTable_searchValue = event.detail;
                this.bef_query.page = 1;
                this.bef_query.limit = 10;
                this.bef_query.total = 10;
                this.SearchBefaehigungenFK();
            }
            else {
                this.befTable_switch = 0;
                this.bef_query.page = 1;
                this.bef_query.limit = 10;
                this.bef_query.total = 10;
                this.fetchBefaehigungenFuehrungskraft(this.bef_query);
            }
        },
        exportPdf_Auswertungen(jahr) {
            if (this.bef_jahrTable.length || this.schu_jahrTable.length) {
                // initialize
                var doc = new jsPDF({
                  orientation: 'landscape',
                  unit: 'px',
                  format: 'a4',
                  putOnlyUsedFonts:true
                });
                // get date
                let objectDate = new Date();
                let day = objectDate.getDate();
                let month = objectDate.getMonth() + 1;
                let year = objectDate.getFullYear();
                if (day < 10) {
                  day = '0' + day;
                }
                if (month < 10) {
                  month = '0' + month;
                }
                // set variables
                let totalPages = '{total_pages_count_string}';
                let date_footer = `${day}.${month}.${year}`;
                let date_filename = `${day}${month}${year}`;
                let logo = '../images/Logo/logo_pdf.png';

                // columnStyles: { tage: { cellWidth: 32, halign: 'right' }

                if(this.document == 'auslBefaehigungen') {
                    autoTable(doc, {
                    headStyles: { fillColor : [0, 143, 189] },
                    styles: { fontSize: 12 },
                    head: [['#', 'Vorname', 'Nachname', 'Nummer', 'Bezeichnung', 'Gültigkeit']],
                    columns: [
                      {dataKey: 'index', header: '#'},
                      {dataKey: 'vorname', header: 'Vorname'},
                      {dataKey: 'nachname', header: 'Nachname'},
                      {dataKey: 'nummer', header: 'Nummer'},
                      {dataKey: 'bezeichnung', header: 'Bezeichnung'},
                      {dataKey: 'gueltigkeit', header: 'Gültigkeit'},
                    ],
                    body: this.bef_jahrTable,
                    didDrawPage: function () {
                        // Footer
                        var str = 'Seite ' + doc.internal.getNumberOfPages()
                        if (typeof doc.putTotalPages === 'function') {
                          str = str + ' von ' + totalPages
                        }
                        // Header
                        doc.setFont(undefined, 'bold');
                        doc.setFontSize(18);
                        doc.text('Mitarbeiter Befähigungen', 30, 30);
                        doc.setFontSize(14);
                        doc.text('mit Ablauf Ende ' + jahr, 30, 46);
                        doc.setFont(undefined, 'normal');
                        doc.setDrawColor(255, 0, 0);
                        doc.line(30, 418, 600, 418);
                        doc.addImage(logo, 'PNG', 494, 20);
                        doc.setFontSize(10);
                        doc.text(str, 554, 430);
                        doc.text(date_footer, 30, 430);
                    },
                    showHead: 'everyPage',
                    margin: {top: 70},
                    });
                    if (typeof doc.putTotalPages === 'function') {
                        doc.putTotalPages(totalPages)
                    }
                    doc.save('befaehigungen_' + date_filename + '.pdf')
                }
                if(this.document == 'schulungsteilnahmen') {
                    autoTable(doc, {
                    headStyles: { fillColor : [0, 143, 189] },
                    styles: { fontSize: 12 },
                    head: [['#', 'Vorname', 'Nachname', 'Nummer', 'Bezeichnung', 'Beginn', 'Ende']],
                    columns: [
                      {dataKey: 'index', header: '#'},
                      {dataKey: 'vorname', header: 'Vorname'},
                      {dataKey: 'nachname', header: 'Nachname'},
                      {dataKey: 'extra_nr', header: 'Nummer'},
                      {dataKey: 'bezeichnung', header: 'Bezeichnung'},
                      {dataKey: 'von', header: 'Beginn'},
                      {dataKey: 'bis', header: 'Ende'}
                    ],
                    body: this.schu_jahrTable,
                    didDrawPage: function () {
                        // Footer
                        var str = 'Seite ' + doc.internal.getNumberOfPages()
                        if (typeof doc.putTotalPages === 'function') {
                          str = str + ' von ' + totalPages
                        }
                        // Header
                        doc.setFont(undefined, 'bold');
                        doc.setFontSize(18);
                        doc.text('Mitarbeiter Schulungsteilnahmen', 30, 30);
                        doc.setFontSize(14);
                        doc.text('im Jahr ' + jahr, 30, 46);
                        doc.setFont(undefined, 'normal');
                        doc.setDrawColor(255, 0, 0);
                        doc.line(30, 418, 600, 418);
                        doc.addImage(logo, 'PNG', 494, 20);
                        doc.setFontSize(10);
                        doc.text(str, 554, 430);
                        doc.text(date_footer, 30, 430);
                    },
                    showHead: 'everyPage',
                    margin: {top: 70},
                    });
                    if (typeof doc.putTotalPages === 'function') {
                        doc.putTotalPages(totalPages)
                    }
                    doc.save('schulungsteilnahmen_' + date_filename + '.pdf')
                }
            }
            else {
                this.text_error = 'No data to export!';
                this.message_visible = true;
                setTimeout(() => this.message_visible = false, 4000);
            }
        },
        exportPdf_Befaehigungen() {
            if (this.ma_befTable.length) {
                // initialize
                var doc = new jsPDF({
                  orientation: 'landscape',
                  unit: 'px',
                  format: 'a4',
                  putOnlyUsedFonts:true
                });
                // get date
                let objectDate = new Date();
                let day = objectDate.getDate();
                let month = objectDate.getMonth() + 1;
                let year = objectDate.getFullYear();
                if (day < 10) {
                  day = '0' + day;
                }
                if (month < 10) {
                  month = '0' + month;
                }
                // set variables
                let totalPages = '{total_pages_count_string}';
                let date_footer = `${day}.${month}.${year}`;
                let date_filename = `${day}${month}${year}`;
                let logo = '../images/Logo/logo_pdf.png';
                let sub_title = this.sub_title;

                autoTable(doc, {
                headStyles: { fillColor : [0, 143, 189] },
                styles: { fontSize: 12 },
                head: [['Nummer', 'Name', 'gültig bis']],
                columns: [
                  {dataKey: 'nummer', header: 'Nummer'},
                  {dataKey: 'name', header: 'Name'},
                  {dataKey: 'gueltigkeit', header: 'gültig bis'}
                ],
                body: this.ma_befTable,
                didDrawPage: function () {
                    // Footer
                    var str = 'Seite ' + doc.internal.getNumberOfPages()
                    if (typeof doc.putTotalPages === 'function') {
                      str = str + ' von ' + totalPages
                    }
                    // Header
                    doc.setFont(undefined, 'bold');
                    doc.setFontSize(18);
                    doc.text('Mitarbeiter Befähigungen', 30, 30);
                    doc.setFontSize(14);
                    doc.setFont(undefined, 'normal');
                    doc.text(sub_title, 30, 46);
                    doc.setDrawColor(255, 0, 0);
                    doc.line(30, 418, 600, 418);
                    doc.addImage(logo, 'PNG', 494, 20);
                    doc.setFontSize(10);
                    doc.text(str, 554, 430);
                    doc.text(date_footer, 30, 430);
                },
                showHead: 'everyPage',
                margin: {top: 70},
                });
                if (typeof doc.putTotalPages === 'function') {
                    doc.putTotalPages(totalPages)
                }
                doc.save('befaehigungen_mitarbeiter' + date_filename + '.pdf')
            }
            else {
                this.text_error = 'No data to export!';
                this.message_visible = true;
                setTimeout(() => this.message_visible = false, 4000);
            }
        }
    },
};
</script>

