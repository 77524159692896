<template>
    <!-- <eon-ui-text class="custom-text-footer" size="small" text-style="copy-small">© 2022 - Lorem ipsum
    </eon-ui-text>
    <eon-ui-link class="custom-link-footer" hide-icon="true" style="padding-bottom: 0px !important" size="small"
        href="https://www.lorem-ipsum.com" text="Lorem ipsum" target="_blank"></eon-ui-link>
    <eon-ui-link class="custom-link-footer" hide-icon="true" style="padding-bottom: 0px !important" size="small"
        href="https://lorem-ipsuming.com" text="Lorem ipsuming" target="_blank"></eon-ui-link>
    <eon-ui-color-separator class="hydrated"></eon-ui-color-separator> -->


    <eon-ui-footer-bar copyright="© 2024 Bayernwerk Akademie">
        <!-- <eon-ui-link slot="socialmedia" target="_blank" icon="social_facebook" href="https://www.facebook.com/bayernwerkAG"></eon-ui-link>
        <eon-ui-link slot="socialmedia" target="_blank" icon="social_instagram" href="https://www.instagram.com/bayernwerk"></eon-ui-link>
        <eon-ui-link slot="socialmedia" target="_blank" icon="social_twitter" href="https://www.twitter.com/Bayernwerk"></eon-ui-link>
        <eon-ui-link slot="socialmedia" target="_blank" icon="social_youtube" href="https://www.youtube.com/channel/UCaV8ZKg8Eumgi_aPrWEkNkg"></eon-ui-link>
        <eon-ui-link slot="socialmedia" target="_blank" icon="social_xing" href="https://www.xing.com/company/bayernwerk"></eon-ui-link>
        <eon-ui-link slot="socialmedia" target="_blank" icon="social_linked_in" href="https://www.linkedin.com/company/bayernwerk-ag"></eon-ui-link> -->
        <eon-ui-link slot="links" scheme="darkgrey" target="_self" size="small" class="custom-link-footer space" text="Datenschutz" href="/datenschutz"></eon-ui-link>
        <eon-ui-link slot="links" scheme="darkgrey" target="_self" size="small" class="custom-link-footer" text="AGB" href="/agb"></eon-ui-link>
        <eon-ui-link slot="links" scheme="darkgrey" target="_self" size="small" class="custom-link-footer" text="Impressum" href="/impressum"></eon-ui-link>
        <eon-ui-image slot="logo" src="/images/Logo/Academy/bayernwerk-akademie-header-logo.svg" alt="Bayernwerk Akademie Logo"></eon-ui-image>
    </eon-ui-footer-bar>

</template>

<script>
export default {
    name: "FooterVue",
    setup() { },
    methods: {},
};
</script>
