<template>
    <eon-ui-error-message style="" icon-name="radio_checkbox_tick" :is-visible="message_visible"
        inner-background="eon-bag-green" class="hydrated" fixed-position="true">
        <eon-ui-headline slot="headline" size="h5" :text="text_error" class="hydrated"></eon-ui-headline>
    </eon-ui-error-message>

    <eon-ui-section class="hydrated">

        <!-- <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Startseite</a>
                <a href="/meine_schulungen">Meine Schulungen</a>
            </eon-ui-breadcrumb>
        </div> -->

        <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">

                <eon-ui-headline text="Hierfür ist eine Anmeldung erforderlich." size="h4" class="hydrated text-blue" v-if="Mitarbeiter_ID === ''"></eon-ui-headline>

                <div v-if="Mitarbeiter_ID != ''">

                    <eon-ui-headline text="Meine Schulungen" size="h4" class="hydrated text-blue"></eon-ui-headline>
                    <div class="pb-4 pt-4">
                        <eon-ui-text text-style="copy">Hier finden Sie Ihre Schulungen und deren Kenndaten.</eon-ui-text>
                    </div>

                    <eon-ui-data-table class="hydrated" zebra="false" selectable-rows="false" expandable-rows="false" paging="true" page-sizes="10,20,50,100"
                        server-mode="true"
                        ref="tbl"
                        :initial-page-size="query.limit" :total-items="query.total" @pagechanged="pageChanged" @pagedecreased="pageChanged"
                        @pageincreased="pageChanged" @pagesizechanged="pageSizeChanged" @selectionchanged="selectionChanged" @sortchanged="sortChanged">
                        <eon-ui-table-head>
                            <eon-ui-table-head-row>
                                <eon-ui-table-head-cell>Nummer</eon-ui-table-head-cell>
                                <eon-ui-table-head-cell>Bezeichnung</eon-ui-table-head-cell>
                                <eon-ui-table-head-cell sortable="true" sort-key="beginn" sort-order="desc">Beginn</eon-ui-table-head-cell>
                                <eon-ui-table-head-cell>Ende</eon-ui-table-head-cell>
                                <eon-ui-table-head-cell>Status</eon-ui-table-head-cell>
                                <eon-ui-table-head-cell>Ort</eon-ui-table-head-cell>
                            </eon-ui-table-head-row>
                        </eon-ui-table-head>
                        <eon-ui-table-body>
                            <eon-ui-table-row v-for="item in dataTable" :key="item.id">
                                <eon-ui-table-cell :value="item.nummer" v-if="item.seminar_id != ''">
                                    <router-link :to="{ name: 'seminareDetail', params: { id: item.seminar_id } }">
                                        <eon-ui-link size="small" :text="item.nummer" target="_self" align-icon-center="true" hide-icon="true"
                                            class="hydrated katalog-link" scheme="turquoise"></eon-ui-link></router-link>
                                </eon-ui-table-cell>
                                <eon-ui-table-cell :value="item.bezeichnung" v-if="item.seminar_id != ''">
                                    <router-link :to="{ name: 'seminareDetail', params: { id: item.seminar_id } }">
                                        <eon-ui-link size="small" :text="item.bezeichnung" target="_self" align-icon-center="true" hide-icon="true"
                                            class="hydrated katalog-link" scheme="turquoise"></eon-ui-link></router-link>
                                </eon-ui-table-cell>
                                <eon-ui-table-cell :value="item.beginn" v-if="item.seminar_id != ''"> {{ item.beginn }} </eon-ui-table-cell>
                                <eon-ui-table-cell :value="item.ende" v-if="item.seminar_id != ''"> {{ item.ende }} </eon-ui-table-cell>
                                <eon-ui-table-cell :value="item.status"  v-if="item.seminar_id != ''"> {{ item.status }} </eon-ui-table-cell>
                                <eon-ui-table-cell :value="item.ort" v-if="item.seminar_id != '' && item.ort != ''"> {{ item.ort }} </eon-ui-table-cell>
                                <eon-ui-table-cell :value="item.ort" v-if="item.seminar_id != '' && item.ort == ''"> - </eon-ui-table-cell>
                            </eon-ui-table-row>
                        </eon-ui-table-body>
                    </eon-ui-data-table>

                    <div class="mt-3">
                        <eon-ui-button text="Drucken" size="small" class="btn-custom-blue" v-on:click="this.fetchDataSchulungen(this.query_pdf);"></eon-ui-button>
                    </div>

                    <span v-if="this.$store.state.doz_id">
                        <eon-ui-divider class="mt-10 mb-10"></eon-ui-divider>

                        <eon-ui-headline text="Als Dozent" size="h4" class="hydrated text-blue"></eon-ui-headline>
                        <div class="pb-4 pt-4">
                            <eon-ui-text text-style="copy">Hier ist eine Liste aller Schulungen einsehbar, in denen Sie Dozent sind.</eon-ui-text>
                        </div>

                        <eon-ui-data-table class="hydrated" zebra="false" selectable-rows="false" expandable-rows="false" paging="true" page-sizes="10,20,50,100"
                            :initial-page-size="dozent_query.limit" :total-items="dozent_query.total" @pagechanged="dozent_pageChanged" @pagedecreased="dozent_pageDecreased"
                            @pageincreased="dozent_pageIncreased" @pagesizechanged="dozent_pageSizeChanged" @selectionchanged="dozent_selectionChanged" @sortchanged="dozent_sortChanged">
                        <!-- <eon-ui-data-table class="hydrated" zebra="false" selectable-rows="false" expandable-rows="false"> -->
                            <eon-ui-table-head>
                                <eon-ui-table-head-row>
                                    <eon-ui-table-head-cell>Nr.</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell>Titel</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell>Beginn</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell>Ende</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell>Seminarart</eon-ui-table-head-cell>
                                    <eon-ui-table-head-cell>Ort</eon-ui-table-head-cell>
                                </eon-ui-table-head-row>
                            </eon-ui-table-head>
                            <eon-ui-table-body v-show="dataTableDozent">
                                <eon-ui-table-row v-for="item in dataTableDozent" :key="item?.ID_Kurs">
                                    <eon-ui-table-cell :value="item?.extra_nr">
                                        <router-link :to="{ name: 'seminareDetail', params: { id: item?.ID_Kurs } }">
                                            <eon-ui-link size="small" :text="item?.extra_nr" target="_self" hide-icon="true" class="hydrated katalog-link" scheme="turquoise"></eon-ui-link>
                                        </router-link>
                                    </eon-ui-table-cell>
                                    <eon-ui-table-cell :value="item?.Bezeichnung">
                                        <router-link :to="{ name: 'seminareDetail', params: { id: item?.ID_Kurs } }">
                                            <eon-ui-link size="small" :text="item?.Bezeichnung" target="_self" hide-icon="true" class="hydrated katalog-link" scheme="turquoise"></eon-ui-link>
                                        </router-link>
                                    </eon-ui-table-cell>
                                    <eon-ui-table-cell :value="item?.Datum">{{ item?.Datum }}</eon-ui-table-cell>
                                    <eon-ui-table-cell :value="item?.Endedatum">{{ item?.Endedatum }}</eon-ui-table-cell>
                                    <eon-ui-table-cell :value="item?.gruppe_kurs">{{ item?.gruppe_kurs }}</eon-ui-table-cell>
                                    <eon-ui-table-cell :value="item?.Ort">{{item?.Ort}}</eon-ui-table-cell>
                                </eon-ui-table-row>
                            </eon-ui-table-body>
                        </eon-ui-data-table>

                        <div class="mt-3">
                            <eon-ui-button text="Drucken" size="small" class="btn-custom-blue" v-on:click="this.fetchDataSchulungenDozent(this.query_pdf);"></eon-ui-button>
                        </div>
                    </span>

                </div>

            </eon-ui-grid-control-column>
        </eon-ui-grid-control>

    </eon-ui-section>

    <div class="loading-section" :class="loading ? 'show' : ''">
        <eon-ui-preloader-circle></eon-ui-preloader-circle>
    </div>

</template>

<script>
import Resource from "../../composables/bayernwerk";
import { formatDateDMY } from "../../utils/filter";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const bayernwerkResource = new Resource();

export default {
    name: "getListSchulungen",
    data() {
        return {
            dataTable: [],
            dataTableDozent: [],
            pdfTable: [],
            query:
            {
                page: 1,
                limit: 10,
                total: 10,
                ma_id: this.$store.state.Tetra_id,
                sort_dir: 'desc'
            },
            dozent_query:
            {
                page: 1,
                limit: 10,
                total: 10,
                doz_id: this.$store.state.doz_id
            },
            query_pdf:
            {
                total: 'pdf',
                ma_id: this.$store.state.Tetra_id,
                doz_id: this.$store.state.doz_id,
            },
            Mitarbeiter_ID: this.$store.state.Tetra_id,
            doz_id: this.$store.state.doz_id,
            loading: false,
            text_error: "",
            message_visible: false,
        };
    },
    computed: {
    user_token() {
      return this.$store.state.token;
    },
  },
    watch: {
        user_token(newUserData, oldUserData) {
            this.Mitarbeiter_ID = this.$store.state.Tetra_id;
            this.fetchDataSchulungen(this.query);
            if(this.$store.state.doz_id)
            {
                this.doz_id = this.$store.state.doz_id;
                this.fetchDataSchulungenDozent(this.dozent_query);
            }
        }
    },
    mounted(){
        this.Mitarbeiter_ID = this.$store.state.Tetra_id;
        this.fetchDataSchulungen(this.query);
        if(this.$store.state.doz_id)
        {
            this.doz_id = this.$store.state.doz_id;
            this.fetchDataSchulungenDozent(this.dozent_query);
        }
    },
    created() {
    },
    setup() {
    },
    methods: {
        fetchDataSchulungen(query) {
            this.loading = true;
            if(query.total != 'pdf') {
                const offset = (query.page - 1) * query.limit + 1;
                query.offset = offset;
            }
            bayernwerkResource.getListSchulungen(query).then((res) => {
                if (res != null) {
                    if(query.total != 'pdf') {
                        this.query.total = _.get(res, "response.dataInfo.foundCount", 0);
                    }
                    if(res.response && res.response.data && res.messages[0].code == "0" ) {
                        const data_array = res.response.data;
                        // setze Status
                        data_array.forEach(row => {
                            row['fieldData']['teilgenommen'] = row['fieldData']['F_fest_angemeldet'] == 'n' ? 'Warteliste' : row['fieldData']['teilgenommen'] == 'j' ? 'teilgenommen' : 'eingebucht';
                        });

                        if(query.total != 'pdf') {
                            this.dataTable = data_array.map((value) => {
                                let dateEnd = new Date(value["fieldData"]["BW_Seminare::Endedatum"]);
                                return {
                                    id: value.fieldData.ds_id,
                                    seminar_id: value.fieldData.seminar_id,
                                    nummer: value["fieldData"]["BW_Seminare::seminarnr"],
                                    bezeichnung: value["fieldData"]["BW_Seminare::Bezeichnung"],
                                    beginn: formatDateDMY(value["fieldData"]["BW_Seminare::Datum"]),
                                    ende: formatDateDMY(value["fieldData"]["BW_Seminare::Endedatum"]),
                                    status: (dateEnd.getTime() >= Date.now())? "angemeldet" : value['fieldData']['teilgenommen'],
                                    ort: value["fieldData"]["BW_Seminare::Ort"]
                                };
                            });
                            let state = {pageSize:query.limit, begin: query.offset - 1, end: (query.offset + query.limit - 1 > this.query.total ? this.query.total: query.offset + query.limit - 1), totalItems:this.query.total,currentPage:query.page,searchTerm:''};
                            this.$refs.tbl.setTableState(state);
                        }
                        else {
                            this.pdfTable = data_array.map((value) => {
                                let dateEnd = new Date(value["fieldData"]["BW_Seminare::Endedatum"]);
                                return {
                                    id: value.fieldData.ds_id,
                                    seminar_id: value.fieldData.seminar_id,
                                    nummer: value["fieldData"]["BW_Seminare::seminarnr"],
                                    bezeichnung: value["fieldData"]["BW_Seminare::Bezeichnung"],
                                    beginn: formatDateDMY(value["fieldData"]["BW_Seminare::Datum"]),
                                    ende: formatDateDMY(value["fieldData"]["BW_Seminare::Endedatum"]),
                                    status: (dateEnd.getTime() >= Date.now())? "angemeldet" : value['fieldData']['teilgenommen'],
                                    ort: value["fieldData"]["BW_Seminare::Ort"]
                                };
                            });
                            this.exportPdf_Schulungen('meine');
                        }
                    } else if(res.error_msg) {
                        this.text_error = res.error_msg;
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        const code = res.messages[0].code;
                        if (code == 952 || code == 10) {
                            this.logoutTokenExpired();
                        }
                    }
                }else{
                    this.text_error = "Token expired";
                    this.message_visible = true;
                    this.logoutTokenExpired();
                }
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                console.log("er", error);
                const code = _.get(error, "response.data.messages[0].code", 0);
                if (code == 952 || code == 10) {
                    this.logoutTokenExpired();
                }
            });
        },
        fetchDataSchulungenDozent(dozent_query) {
            this.loading = true;
            if(dozent_query.total != 'pdf') {
                const offset = (dozent_query.page - 1) * dozent_query.limit + 1;
                dozent_query.offset = offset;
            }
            bayernwerkResource.getListSchulungenDozent(dozent_query).then((res) => {
                if (res != null) {
                    if(dozent_query.total != 'pdf') {
                        this.dozent_query.total = _.get(res, "response.dataInfo.foundCount", 0);
                    }
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;

                        if(dozent_query.total != 'pdf') {
                            this.dataTableDozent = data_array.map((value) => {
                                return {
                                    Bezeichnung: value.fieldData.Bezeichnung,
                                    Datum: formatDateDMY(value.fieldData.Datum),
                                    Endedatum: formatDateDMY(value.fieldData.Endedatum),
                                    Ort: value.fieldData.Ort,
                                    ID_Kurs: value.fieldData.ID_Kurs,
                                    zielgruppe: value.fieldData.zielgruppe,
                                    seminarnr: value.fieldData.seminarnr,
                                    extra_nr: value.fieldData.extra_nr,
                                    gruppe_kurs: value.fieldData.gruppe_kurs
                                };
                            });
                        }
                        else {
                            this.pdfTable = data_array.map((value) => {
                                return {
                                    Bezeichnung: value.fieldData.Bezeichnung,
                                    Datum: formatDateDMY(value.fieldData.Datum),
                                    Endedatum: formatDateDMY(value.fieldData.Endedatum),
                                    Ort: value.fieldData.Ort,
                                    ID_Kurs: value.fieldData.ID_Kurs,
                                    zielgruppe: value.fieldData.zielgruppe,
                                    seminarnr: value.fieldData.seminarnr,
                                    extra_nr: value.fieldData.extra_nr,
                                    gruppe_kurs: value.fieldData.gruppe_kurs
                                };
                            });
                            this.exportPdf_Schulungen('als_dozent');
                        }
                } else {
                        this.text_error = res.error_msg;
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        const code = res.messages[0].code;
                        if (code == 952 || code == 10) {
                            this.logoutTokenExpired();
                        }
                    }
                }else{
                    this.text_error = "Token expired";
                    this.message_visible = true;
                    this.logoutTokenExpired();
                }
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                console.log("er", error);
                const code = _.get(error, "response.data.messages[0].code", 0);
                if (code == 952 || code == 10) {
                    this.logoutTokenExpired();
                }
            });
        },
        logoutTokenExpired() {
            this.$store.dispatch("user/logout");
            setTimeout(() => this.$router.go(), this.$router.push({ name: "startseite" }), 4000);
        },
        pageChanged(event) {
            this.query.page = event.detail;
            this.fetchDataSchulungen(this.query);
            if(this.$store.state.doz_id)
            {
                this.doz_id = this.$store.state.doz_id;
                this.fetchDataSchulungenDozent(this.dozent_query);
            }
        },
        pageSizeChanged(event) {
            this.query.limit = event.detail;
            this.query.page = 1;
            this.fetchDataSchulungenDozent(this.query);
        },
        selectionChanged() {
            // console.log('selectionChanged',event);
        },
        sortChanged(event) {
            if(event.detail){
                if(this.query.sort_dir != event.detail.sortOrder){
                    this.query.sort_dir = event.detail.sortOrder;
                    this.fetchDataSchulungen(this.query);
                }
            }
        },
        dozent_pageChanged(event) {
            this.dozent_query.page = event.detail;
            this.fetchDataSchulungenDozent(this.dozent_query);
        },
        dozent_pageDecreased(event) {
            this.dozent_query.page = event.detail;
            this.fetchDataSchulungenDozent(this.dozent_query);
        },
        dozent_pageIncreased(event) {
            this.dozent_query.page = event.detail;
            this.fetchDataSchulungenDozent(this.dozent_query);
        },
        dozent_pageSizeChanged(event) {
            this.dozent_query.limit = event.detail;
            this.dozent_query.page = 1;
            this.fetchDataSchulungenDozent(this.dozent_query);
        },
        dozent_selectionChanged() {
            // console.log('selectionChanged',event);
        },
        dozent_sortChanged() {
        },

        exportPdf_Schulungen(art) {
            if (this.dataTable.length || this.dataTableDozent.length) {
                // initialize
                var doc = new jsPDF({
                  orientation: 'landscape',
                  unit: 'px',
                  format: 'a4',
                  putOnlyUsedFonts:true
                });
                // get date
                let objectDate = new Date();
                let day = objectDate.getDate();
                let month = objectDate.getMonth() + 1;
                let year = objectDate.getFullYear();
                if (day < 10) {
                  day = '0' + day;
                }
                if (month < 10) {
                  month = '0' + month;
                }
                // set variables
                let totalPages = '{total_pages_count_string}';
                let date_footer = `${day}.${month}.${year}`;
                let date_filename = `${day}${month}${year}`;
                let logo = '../images/Logo/bayernwerk-header-logo.png';

                if(art == 'meine') {

                    let dataTable = [];
                    this.pdfTable.forEach(row => {
                        if(row['bezeichnung'] != '') {
                            dataTable.push(row);
                        }
                    });

                    autoTable(doc, {
                    headStyles: { fillColor : [0, 143, 189] },
                    styles: { fontSize: 12 },
                    head: [['Nummer', 'Bezeichnung', 'Beginn', 'Ende', 'Status', 'Ort']],
                    columns: [
                      {dataKey: 'nummer', header: 'Nummer'},
                      {dataKey: 'bezeichnung', header: 'Bezeichnung'},
                      {dataKey: 'beginn', header: 'Beginn'},
                      {dataKey: 'ende', header: 'Ende'},
                      {dataKey: 'status', header: 'Status'},
                      {dataKey: 'ort', header: 'Ort'},
                    ],
                    body: dataTable,
                    didDrawPage: function () {
                        // Footer
                        var str = 'Seite ' + doc.internal.getNumberOfPages()
                        if (typeof doc.putTotalPages === 'function') {
                          str = str + ' von ' + totalPages
                        }
                        // Header
                        doc.setFont(undefined, 'bold');
                        doc.setFontSize(18);
                        doc.text('Meine Schulungen', 30, 35);
                        doc.setFontSize(14);
                        doc.setFont(undefined, 'normal');
                        doc.setDrawColor(255, 0, 0);
                        doc.line(30, 418, 600, 418);
                        doc.addImage(logo, 'PNG', 484, 20);
                        doc.setFontSize(10);
                        doc.text(str, 554, 430);
                        doc.text(date_footer, 30, 430);
                    },
                    showHead: 'everyPage',
                    margin: {top: 70},
                    });
                    if (typeof doc.putTotalPages === 'function') {
                        doc.putTotalPages(totalPages);
                    }
                    doc.save('meine_schulungen_' + date_filename + '.pdf');
                }
                if(art == 'als_dozent') {

                    let dataTableDozent = [];
                    this.pdfTable.forEach(row => {
                        if(row['bezeichnung'] != '') {
                            dataTableDozent.push(row);
                        }
                    });

                    autoTable(doc, {
                    headStyles: { fillColor : [0, 143, 189] },
                    styles: { fontSize: 12 },
                    head: [['Nummer', 'Bezeichnung', 'Beginn', 'Ende', 'Seminarart', 'Ort']],
                    columns: [
                      {dataKey: 'extra_nr', header: 'Nummer'},
                      {dataKey: 'Bezeichnung', header: 'Bezeichnung'},
                      {dataKey: 'Datum', header: 'Beginn'},
                      {dataKey: 'Endedatum', header: 'Ende'},
                      {dataKey: 'gruppe_kurs', header: 'Seminarart'},
                      {dataKey: 'Ort', header: 'Ort'}
                    ],
                    body: dataTableDozent,
                    didDrawPage: function () {
                        // Footer
                        var str = 'Seite ' + doc.internal.getNumberOfPages()
                        if (typeof doc.putTotalPages === 'function') {
                          str = str + ' von ' + totalPages
                        }
                        // Header
                        doc.setFont(undefined, 'bold');
                        doc.setFontSize(18);
                        doc.text('Meine Schulungen als Dozent', 30, 35);
                        doc.setFontSize(14);
                        doc.setFont(undefined, 'normal');
                        doc.setDrawColor(255, 0, 0);
                        doc.line(30, 418, 600, 418);
                        doc.addImage(logo, 'PNG', 484, 20);
                        doc.setFontSize(10);
                        doc.text(str, 554, 430);
                        doc.text(date_footer, 30, 430);
                    },
                    showHead: 'everyPage',
                    margin: {top: 70},
                    });
                    if (typeof doc.putTotalPages === 'function') {
                        doc.putTotalPages(totalPages);
                    }
                    doc.save('meine_schulungen_dozent_' + date_filename + '.pdf');
                }
            }
            else {
                this.text_error = 'No data to export!';
                this.message_visible = true;
                setTimeout(() => this.message_visible = false, 4000);
            }
        }
    },
};
</script>
