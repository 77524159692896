import _ from "lodash";
import store from '../store';
const axios = require('axios');

const service = axios.create();
service.defaults.timeout = 20000;

let apim_Subscription_Key = '';

export async function getBaseUrl() {
  let response = await axios.get('/api.json');
  let config_api_url = response.data ? (response.data.api_endpoint) : '';
  let config_stage = response.data ? (response.data.stage) : '';
  return Promise.resolve({ 'config_api_url': config_api_url, 'config_stage': config_stage });
};

if (localStorage.getItem("user_store") && JSON.parse(localStorage.getItem("user_store")).baseUrl && JSON.parse(localStorage.getItem("user_store")).baseUrl !== '') {
  if ((Date.now() - JSON.parse(localStorage.getItem("user_store")).baseUrlTime) / 60000 > 6) {
    store.dispatch("user/resetTimeBaseUrl");
    window.location.reload();
  } else {
    switch (JSON.parse(localStorage.getItem("user_store")).baseStage) {
      case 'qas':
        apim_Subscription_Key = '2d57942b893045dc9f7d0bf420f8f8fd'
        break;
      case 'run':
        apim_Subscription_Key = 'c620dbef60624abcae5b5810f69bb67a';
        break;
      case 'dev':
      default:
        apim_Subscription_Key = '020ef171e1554689aef19416be3122cc';
    }
    service.interceptors.request.use(
      async config => {
        config.baseURL = await JSON.parse(localStorage.getItem("user_store")).baseUrl;
        config.headers['Ocp-Apim-Subscription-Key'] = apim_Subscription_Key;
        return config;
      }, error => Promise.reject(error));
  }
} else {
  getBaseUrl().then(result => {
    if (result) {
      store.dispatch("user/setBaseUrl", result);
      window.location.reload();
    }
  });
}

// Request intercepter  
service.interceptors.request.use(
  config => {
    const token = store.state.token;
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token; // Set JWT token
    }
    return config;
  },
  error => {
    // Do something with request error
    Promise.reject(error);
  }
);

// response pre-processing
service.interceptors.response.use(
  response => {
    // if (response.headers.authorization) {
    //   setLogged(response.headers.authorization);
    //   response.data.token = response.headers.authorization;
    // }
    return response.data.data;
  },
  error => {
    let message = _.get(error, 'response.data.messages[0].message', error.message);
    // if (typeof message !== 'string' && 'email' in message && message.email.length > 0 && message.email[0] === 'validation.unique'){
    //   message = 'Email has been used!';
    // }
    console.log(message);
    return Promise.reject(error);
  }
);

export default service;
