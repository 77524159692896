<template>
<div>
    <!-- <eon-ui-section>
        <eon-ui-image lazy src="https://www.bayernwerk-shop.de/media/image/55/38/53/Header-Schulungen_1920x1080.jpg"
            alt="Technisches Trainingscenter der Bayernwerk Netz GmbH"
            title="Technisches Trainingscenter der Bayernwerk Netz GmbH" use-as-background="true" >
        </eon-ui-image>
    </eon-ui-section> -->

    <eon-ui-section class="hydrated">

        <eon-ui-error-message style="" icon-name="radio_checkbox_tick" :is-visible="message_visible"
        inner-background="eon-bag-green" class="hydrated" fixed-position="true">
            <eon-ui-headline slot="headline" size="h5" :text="text_error" class="hydrated"></eon-ui-headline>
        </eon-ui-error-message>

        <eon-ui-error-message style="" icon-name="radio_checkbox_tick" :is-visible="message_visible_success"
           inner-background="eon-bag-green" class="hydrated custom-message-success" fixed-position="true" close-timer="5">
           <eon-ui-headline slot="headline" size="h5" :text="text_success" class="hydrated"></eon-ui-headline>
        </eon-ui-error-message>

        <!-- <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Übersicht</a>
                <a href="/kontakt">Kontakt</a>
            </eon-ui-breadcrumb>
        </div> -->

        <eon-ui-form method="post" class="hydrated form-register" ref="formSaveContact"  @formsubmit="submitFormSaveContact">

            <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">

                <eon-ui-grid-control-column slot="column-1" class="hydrated">

                    <div class="form-group">
                        <eon-ui-headline size="h4" text="Kontakt" class="hydrated text-blue"></eon-ui-headline>
                    </div>

                    <eon-ui-text class="hydrated" size="small" text-style="copy">Sie haben Fragen zu Schulungen oder möchten Ihre Kontaktdaten ändern? Dann scheuen
                        Sie sich nicht, mit uns in Kontakt zu treten. Unsere Experten kümmern sich um Ihr Anliegen und finden sicherlich eine Lösung. Sie können uns
                        detaillierte Angaben über dieses Formular schicken.
                    </eon-ui-text>

                    <div v-if="this.$store.state.token != null && this.$store.state.Tetra_id == ''">
                        <eon-ui-text-teaser pictogram="" size="h3" align="left" show-button="false">
                            <eon-ui-text class="hydrated" size="small" text-style="copy" color="eon-bag-red"><b>Hinweis:</b> Sie besitzen zur Zeit noch keinen Account in
                                unserer Schulungssoftware. Um Schulungen zu buchen oder Ihre Daten einsehen zu können, muss zunächst ein Account angelegt werden.
                                Bitte nutzen Sie dazu dieses Formular mit dem Betreff "Neuer Account".
                            </eon-ui-text>
                        </eon-ui-text-teaser>
                    </div>

                    <div class="form-group">
                        <eon-ui-text text-style="rtf">Meine Daten</eon-ui-text>
                    </div>
                    <div class="form-group">
                        <eon-ui-input :value="contact_form.name" @valuechanged="(e)=>{changeValue(e.target.value, 'name')}"  :read-only="read_only" required :class="read_only ? 'input-custom input-readonly' : 'input-custom'" label="Name: *" name="name" id="name" type="text">
                        </eon-ui-input>
                    </div>
                    <div class="form-group">
                        <eon-ui-input  :value="contact_form.email" @valuechanged="(e)=>{changeValue(e.target.value, 'email')}"  :read-only="read_only" required :class="read_only ? 'input-custom input-readonly' : 'input-custom'" label="E-Mail: *" name="email" type="text" id="email" >
                        </eon-ui-input>
                    </div>
                    <div class="form-group">
                        <eon-ui-text text-style="rtf">Meine Anfrage</eon-ui-text>
                    </div>
                    <div class="form-group">
                        <eon-ui-dropdown :value="contact_form.betreff" @valuechanged="(e)=>{changeValue(e.target.value, 'betreff')}"  value-key="value" required  name="betreff" label="Thema auswählen..."  class="input-custom hydrated">
                            <!-- <eon-ui-dropdown-option value="" label="Entries"></eon-ui-dropdown-option> -->
                            <eon-ui-dropdown-option class="hydrated" value="Änderung/Stornierung Buchung" label="Änderung / Stornierung Buchung"></eon-ui-dropdown-option>
                            <eon-ui-dropdown-option class="hydrated" value="Nachfrage Schulungen" label="Nachfrage Schulungen"></eon-ui-dropdown-option>
                            <eon-ui-dropdown-option class="hydrated" value="Änderung Kontakt- / Rechnungsdaten" label="Änderung Kontakt- / Rechnungsdaten"></eon-ui-dropdown-option>
                            <eon-ui-dropdown-option class="hydrated" value="Änderung Mitarbeiterdaten" label="Änderung Mitarbeiterdaten"></eon-ui-dropdown-option>
                            <eon-ui-dropdown-option class="hydrated" value="Neuer Account" label="Neuer Account" v-if="this.$store.state.token != null && this.$store.state.Tetra_id == ''"></eon-ui-dropdown-option>

                            <eon-ui-dropdown-option class="hydrated" value="Bildungspartner werden" label="Bildungspartner werden"></eon-ui-dropdown-option>
                            <eon-ui-dropdown-option class="hydrated" value="Sonstiges" label="Sonstiges"></eon-ui-dropdown-option>
                        </eon-ui-dropdown>
                    </div>

                    <div class="form-group">
                        <eon-ui-input :value="contact_form.text" @valuechanged="(e)=>{changeValue(e.target.value, 'text')}"  required label="Nachricht:" rows="5"  textarea="true"
                             name="nachricht"
                            class="hydrated text-black block input-custom">
                        </eon-ui-input>
                    </div>

                    <div class="form-group ">
                        <eon-ui-button :preloader="loading" class="btn-custom-blue" text="Abschicken" input-type="submit"></eon-ui-button>
                    </div>
                    <!-- <div slot="200">
                        <eon-ui-form-message type="info" message="Request successful"></eon-ui-form-message>
                    </div>
                    <div slot="500">
                        <eon-ui-form-message type="warning" message="Request failed"></eon-ui-form-message>
                    </div> -->

                </eon-ui-grid-control-column>

            </eon-ui-grid-control>
        </eon-ui-form>
    </eon-ui-section>
    </div>
</template>

<script>
import _ from "lodash";
import Resource from "../../composables/bayernwerk";

const bayernwerkResource = new Resource();
export default {
    name: "Kontakt",
    data() {
        return {
            loading: false,
            read_only: this.$store.state.email ? true : false,
            contact_form: {
                email: this.$store.state.email ? this.$store.state.email : "",
                name: this.$store.state.name_gesamt ? this.$store.state.name_gesamt : "",
                text: "",
                betreff: this.$store.state.contactSubject ? this.$store.state.contactSubject : "",
                online_id: this.$store.state.ds_id ? this.$store.state.ds_id : 0
            },
            message_visible_success: false,
            message_visible: false,
            text_error: "",
            text_success: "",
            msg: "",
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        };
    },
    setup() { },
    methods: {
        changeValue(value, field){
            _.set(this.contact_form, field, value);
        },
        submitFormSaveContact(event) {
            if (!this.reg.test(this.contact_form.email)) {
                alert('Bitte geben Sie eine gültige E-Mail-Adresse ein!');
                event.preventDefault();
                return "";
            }
            this.loading = true;
            event.preventDefault();
            this.error_msg = '';
            console.log('submitFormSaveContact');
            console.log(this.contact_form);
            bayernwerkResource.saveContactForm(this.contact_form).then((res) => {
            if (res != null) {
                if(res.messages && res.messages[0]['code']== "0" ) {
                    this.text_success = "Ihre Anfrage wurde erfolgreich verarbeitet. Das Veranstaltungsmanagement wird sich mit Ihnen in Verbindung setzen.";
                    this.message_visible_success = true;
                    setTimeout( () => this.$router.push({ name: "startseite" }), 4000);
                }else{
                    this.text_error = 'Ihre Anfrage konnte nicht gespeichert werden. Bitte versuchen Sie es in ein paar Minuten erneut.';
                    this.message_visible = true;
                    setTimeout(() => this.message_visible = false, 4000);
                }
            }
            this.loading = false;
          }).catch((error) => {
            console.log(error);
            this.text_error = 'Save Contact Form Error!';
            this.message_visible = true;
            setTimeout(() => this.message_visible = false, 4000);
            this.loading = false;
          });
        },
        changeBetreff(e) {
            this.contact_form.betreff = e.target.value;
        },
        validateEmail() {
            if (!this.reg.test(this.contact_form.email)) {
                alert('Bitte geben Sie eine gültige E-Mail-Adresse ein!');
            }
        }
    },
};
</script>
