<template>

    <eon-ui-section class="hydrated">

        <!-- <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Übersicht</a>
                <a href="/datenschutz">Datenschutz</a>
            </eon-ui-breadcrumb>
        </div> -->

        <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">

                <eon-ui-headline text="Datenschutzerklärung<br />Bayernwerk Akademie" class="hydrated text-blue" size="h4"></eon-ui-headline>

                <div class="px-8 py-2">
                    <eon-ui-text text-style="copy">
                        Gerne informieren wir, Bayernwerk Akademie GmbH, als Verantwortlicher für die Website
                        akademie.bayernwerk.de i.S.d. Art. 4 Nr. 7 EU-DS-GVO (nachfolgend „wir“, „uns“, etc.), 
                        Sie mit dieser Datenschutzerklärung, ob und in welchem Umfang eine Verarbeitung Ihrer 
                        personenbezogenen Daten bei einem Besuch der Website/s unter dieser Domain erfolgt. 
                        Zudem informieren wir Sie über Ihre Rechte und nennen Ihnen die zuständigen Ansprechpartner.  <br /><br />
                        Personenbezogene Daten sind sämtliche Informationen, die einen Bezug zu Ihrer Person erkennen lassen, 
                        z. B. Name, Anschrift, E-Mail-Adresse, IP, Daten zum Nutzungsverhalten.
                    </eon-ui-text>
                </div>

                <eon-ui-text class="hydrated text-blue" text-style="rtf">
                    Verarbeitung von personenbezogenen Daten bei informatorischer Nutzung aufgrund berechtigter Interessen,
                    Art. 6 Abs. 1 lit. f) DS-GVO
                </eon-ui-text>

                <div class="px-8 py-2">
                    <eon-ui-text text-style="copy">
                        Im Fall einer rein informatorischen Nutzung unserer Website, das heißt, wenn Sie sich weder registrieren, 
                        anmelden noch auf sonstige Weise zusätzliche Informationen an uns übermitteln, 
                        erheben und verarbeiten wir zunächst nur die personenbezogenen Daten, 
                        die Ihr Browser und Ihr Internetzugangsanbieter an unseren Server übermitteln. 
                        Dabei handelt es sich um Daten, die technisch erforderlich sind, 
                        um Ihnen die Website anzuzeigen und die Stabilität und Sicherheit unseres Angebots gewährleisten zu können.<br /><br />

                        Konkret handelt es sich um:<br /><br />

                        <div class="px-9">
                            <ul class="list-disc">

                                <li>Technische Daten (anonymisierte IP-Adresse, Datum und Uhrzeit der Anfrage, Inhalt der Anforderung bzw. des Abrufs, Zugriffsstatus, http-Statuscode)</li>
                                <li>Zeitzonendifferenz zu Greenwich Mean Time (GMT), jeweils übertragene Datenmenge, Referrer-URL</li>
                                <li>Betriebssystem, Browserdaten (Betriebssystem, Oberfläche, Browser, Sprache, Version der Browsersoftware)</li>

                            </ul>
                        </div> <br /><br />

                        Zur Erbringung der Website-Funktionen und zur Erfüllung unserer eigenen Verpflichtungen geben wir zum einen Ihre Daten an unsere Dienstleister weiter, 
                        zum anderen empfangen wir von diesen Daten. 
                        Das gilt auch, wenn Sie mit uns auf den angegebenen Wegen in Kontakt treten oder andere Angebote unserer Website nutzen. <br /><br />

                        Wir speichern die vorgenannten Daten auch in Logfiles. Diese Speicherung erfolgt, um die Funktionsfähigkeit der Website sicherzustellen. 
                        Außerdem nutzen wir die Daten, um unseren Internetauftritt zu optimieren und dessen Sicherheit sicherzustellen. 
                        Es erfolgt jedoch keine Speicherung der vorgenannten Daten zusammen mit anderen personenbezogenen Daten.
                    </eon-ui-text>
                </div>

                <eon-ui-text class="hydrated text-blue" text-style="rtf">
                    Nutzung Ihrer Daten zur Durchführung der Schulungen im Trainingscenter<br />
                    (Durchführung eines Vertragsverhältnisses, Art. 6 Abs. 1 S. 1 lit. b) DS-GVO)
                </eon-ui-text>

                <div class="px-8 py-2">
                    <eon-ui-text text-style="copy">
                        Wir verwenden die Daten, um mit Ihnen einen Vertrag zur Nutzung des Trainingscenters abzuschließen, 
                        durchzuführen oder zu beenden. Bei diesen Daten handelt es sich insbesondere um:<br /><br />

                        <div class="px-9">
                            <ul class="list-disc">

                                <li>Ihre Anrede,</li>
                                <li>Ihren Vor- und Nachnamen,</li>
                                <li>Ihre Firmenanschrift,</li>
                                <li>Ihre Kontaktdaten wie z. B. Telefonnummer und E-Mail-Adresse</li>

                            </ul>
                        </div><br /><br />

                        Wir verarbeiten Namen und E-Mail-Adresse des Nutzers, 
                        um für diesen einen personenbezogenen Zugang zu dem jeweiligen Schulungsmodul einzurichten. 
                        Der Lernfortschritt eines Nutzers im jeweiligen Schulungsmodul wird als Prozentangabe abgespeichert. 
                        Außerdem wird das Datum gespeichert, an dem ein Nutzer einen Selbstcheck absolviert hat
                    </eon-ui-text>
                </div>

                <eon-ui-text class="hydrated text-blue" text-style="rtf">
                    Verwendung von Cookies aufgrund berechtigter Interessen, Art. 6 Abs. 1 lit. f) DS-GVO
                </eon-ui-text>
                
                <div class="px-8 py-2">
                    <eon-ui-text text-style="copy">
                        Zusätzlich zu den zuvor genannten Daten werden beim Besuch unserer Website so genannte „Cookies“ auf Ihrem Rechner gespeichert. 
                        Bei Cookies handelt es sich um kleine Textdateien, 
                        die auf Ihrer Festplatte dem von Ihnen verwendeten Browser zugeordnet gespeichert werden und durch welche der Stelle, 
                        die den Cookie setzt (hier durch uns), bestimmte Informationen zufließen. 
                        Cookies können keine Programme ausführen oder Viren auf Ihren Computer übertragen. 
                        Sie dienen dazu, unsere Website insgesamt nutzerfreundlicher und effektiver zu machen.<br /><br />

                        Sie werden beim ersten Aufruf unserer Website bzw. fehlendem Cookie auf Ihrem Endgerät über den Einsatz von Cookies informiert. 
                        Zudem holen wir für bestimmte Cookies Ihre Einwilligung ein. Hierzu finden Sie weitere Erläuterungen unter D. 
                        Bei Erteilung des initialen Cookie-Hinweises und zur Einholung ihres Einverständnisses verweisen wir auf diese Datenschutzerklärung.<br /><br />

                        Wir nutzen transiente und persistente Cookies, deren Umfang und Funktionsweise im Folgenden erläutert werden:

                        <div class="px-6 py-2 mt-1">
                            <eon-ui-headline text="1. Transiente Cookies" size="h5" class="hydrated text-blue"></eon-ui-headline>
                        </div>
                        <div class="px-12">
                            Transiente Cookies werden automatisiert gelöscht, wenn Sie den Browser schließen. Dazu zählen insbesondere Session-Cookies. 
                            Diese speichern eine sogenannte Session-ID, mit welcher sich verschiedene Anfragen Ihres Browsers der gemeinsamen Sitzung zuordnen 
                            lassen und das Surfverhalten auf unserer Webseite für Sie angenehmer machen. Durch die Session Cookies kann Ihr Rechner 
                            wiedererkannt werden, wenn Sie auf unsere Website zurückkehren. Die Session-Cookies werden gelöscht, wenn Sie den Browser schließen.
                        </div>
                        <div class="px-6 py-2">
                            <eon-ui-headline text="2. Persistente Cookies" size="h5" class="hydrated text-blue"></eon-ui-headline>
                        </div>
                        <div class="px-12">
                            Persistente Cookies werden automatisiert nach einer vorgegebenen Dauer gelöscht, die sich je nach Cookie unterscheiden kann. 
                            Sie können die Cookies in den Sicherheitseinstellungen Ihres Browsers jederzeit löschen.<br /><br />

                            Es werden zu keinem Zeitpunkt personenbezogene Daten in einem Cookie gespeichert.<br /><br />

                            Die meisten Browser sind so voreingestellt, dass sie Cookies automatisch akzeptieren. 
                            Sie können das Speichern von Cookies jedoch deaktivieren oder Ihren Browser so einstellen, dass er Sie benachrichtigt, 
                            bevor Cookies gespeichert werden. Nutzer, die Cookies nicht akzeptieren, 
                            können auf bestimmte Bereiche unserer Websites eventuell nicht zugreifen.<br /><br />
                        </div>
                    </eon-ui-text>
                </div>

                <eon-ui-text class="hydrated text-blue" text-style="rtf">
                    Kontaktaufnahme per E-Mail oder Kontaktformular
                </eon-ui-text>

                <div class="px-8 py-2">
                    <eon-ui-text text-style="copy">
                        Bei Ihrer Kontaktaufnahme mit uns per E-Mail oder über ein Kontaktformular werden die von Ihnen mitgeteilten Daten 
                        (Ihre E-Mail-Adresse, ggf. Ihr Name, Ihre Telefonnummer, Ihre Adresse und Ihre Vertragskontonummer) von uns gespeichert, 
                        um Ihre Anfrage zu bearbeiten und zu beantworten. Wir haben ein berechtigtes Interesse an der Verarbeitung Ihrer personenbezogenen Daten, 
                        da wir sie zur Bearbeitung Ihrer Anfrage benötigen. 
                        Insofern tritt im Rahmen der Nutzung des Kontaktformulars bei Abwägung der beiderseitigen Interessen Ihr Interesse am 
                        Schutz Ihrer personenbezogenen Daten gegenüber unserem Bearbeitungsinteresse zurück. Ohne die Verarbeitung der personenbezogenen Daten 
                        ist eine Bearbeitung Ihrer Anfrage nicht möglich. Die Verwendung des Kontaktformulars ist zudem freiwillig. 
                        Die in diesem Zusammenhang anfallenden Daten löschen wir, nachdem die Speicherung nicht mehr erforderlich ist, 
                        oder schränken die Verarbeitung ein, falls gesetzliche Aufbewahrungspflichten bestehen. 
                    </eon-ui-text>
                </div>

                <eon-ui-text class="hydrated text-blue" text-style="rtf">
                    Empfänger von Daten und Datenquellen
                </eon-ui-text>

                <div class="px-8">
                    <eon-ui-text text-style="copy">
                        <div class="px-6 py-2">
                            <eon-ui-headline text="1. Kategorien von Empfängern von Daten" size="h5" class="hydrated text-blue"></eon-ui-headline>
                        </div>
                        <div class="px-12">
                            Soweit gesetzlich zulässig (wie vorab beschrieben), 
                            geben wir personenbezogene Daten an Unternehmen in unserem Konzern sowie externe Dienstleister weiter:<br /><br />

                            <div class="px-9 mt-1 flex items-center">
                                <ul class="list-disc">

                                    <li>Konzernunternehmen zur Erfüllung vertraglicher Verpflichtungen und für das Berichtswesen</li>
                                    <li>Dienstleister zur gezielten Ansprache, zum Abschluss und für die Durchführung des Vertrages</li>
                                    <li>IT-Dienstleister zur Aufrechterhaltung unserer IT-Infrastruktur</li>
                                    <li>Öffentliche Stellen in begründeten Fällen 
                                        (z.B. Sozialversicherungsträger, Finanzbehörden, Polizei, Staatsanwaltschaft, Aufsichtsbehörden)</li>

                                </ul>
                            </div>
                        </div>
                        <div class="px-6 py-2">
                            <eon-ui-headline text="2. Datenquellen" size="h5" class="hydrated text-blue"></eon-ui-headline>
                        </div>
                        <div class="px-12">
                            Wir verarbeiten personenbezogene Daten, die wir im Rahmen unserer Nutzungs- und Geschäftsbeziehungen von Ihnen erhalten haben. 
                            Soweit es für die Erbringung unserer Dienstleistungen erforderlich ist, verarbeiten wir personenbezogene Daten, 
                            die wir im Rahmen Ihrer Nutzung unserer Websites erhoben haben.<br /><br />
                        </div>
                    </eon-ui-text>
                </div>

                <eon-ui-text class="hydrated text-blue" text-style="rtf">
                    Datenübermittlung in ein Drittland
                </eon-ui-text>

                <div class="px-8 py-2">
                    <eon-ui-text text-style="copy">
                        Es ergeben sich Datenübermittlungen in Länder außerhalb der Europäischen Union und des Europäischen Wirtschaftsraums 
                        („Drittländer“) im Rahmen der Verwaltung, Entwicklung und des Betriebs von IT-Systemen. Dabei muss folgendes gegeben sein:<br /><br />

                        Die Übermittlung ist grundsätzlich zulässig, weil ein gesetzlicher Erlaubnistatbestand erfüllt ist oder Sie in die 
                        Datenübermittlung eingewilligt haben und die besonderen Voraussetzungen für eine Übermittlung in ein Drittland vorliegen. 
                        Insbesondere gewährleistet der Datenimporteur ein angemessenes Datenschutzniveau nach Maßgabe der EU-Standardvertragsklauseln für die 
                        Übermittlung personenbezogener Daten an Datenverarbeiter in Drittländern. 
                        Eine Kopie der durch die EU Kommission vorgegebenen Standardvertragsklauseln finden Sie im Internet unter:<br /><br />

                        <div class="px-12 flex items-center">
                            <eon-ui-link href="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32021D0914&from=DE" text="https://eur-lex.europa.eu/legal-content/DE/TXT/PDF/?uri=CELEX:32021D0914&from=DE" target="_blank" hide-icon="true" scheme="turquoise" role="menuitem" size="small"></eon-ui-link>
                        </div>
                    </eon-ui-text>
                </div>

                <eon-ui-text class="hydrated text-blue" text-style="rtf">
                    Speicherdauer und Kriterien für die Festlegung der Dauer
                </eon-ui-text>

                <div class="px-8 py-2">
                    <eon-ui-text text-style="copy">
                        Im Fall der rein informatorischen Nutzung (siehe Punkt 
                        <eon-ui-link href="https://www.bayernwerk-netz.de/datenschutz-ttc-digitalcampus#a"
                            text="Verarbeitung von personenbezogenen Daten bei informatorischer Nutzung aufgrund berechtigter Interessen, Art. 6 Abs. 1 lit. f) DS-GVO" 
                            target="_blank" display-inline="true" scheme="turquoise"></eon-ui-link>
                        ) speichern wir die genannten personenbezogenen Daten solange dies für die Diensterbringung bzw. 
                        Nutzung erforderlich ist. Nach Erreichung des jeweils benannten Zwecks werden sie gelöscht.<br /><br />

                        Logfiles enthalten IP-Adressen oder sonstige Daten, die eine Zuordnung zu einem Nutzer ermöglichen.<br /><br />

                        Für die in Logfiles gespeicherten Daten gilt, dass diese nach spätestens 7 Tagen gelöscht werden. Darüber hinaus erfolgt eine Anonymisierung der
                        in Logfiles gespeicherten personenbezogenen Daten.<br /><br />

                        Bestehen gesetzliche oder vertragliche Aufbewahrungsfristen (z.B. im Fall eines Nutzungs- bzw. Vertragsverhältnisses), 
                        sind wir verpflichtet, die Daten bis zum Ablauf dieser Fristen zu speichern. Nach Ablauf bzw. Wegfall entsprechender Pflichten,
                        die sich in Fällen gesetzlicher Aufbewahrungspflicht insbesondere aus dem Handels- und Steuerrecht ergeben (vgl. §§ 147 AO und 257 HGB), 
                        löschen wir die entsprechenden Daten.<br /><br />

                        Für werbliche Ansprachen speichern wir Ihre Daten, bis Sie einer Nutzung widersprechen,
                        Sie Ihre Einwilligung widerrufen oder eine Ansprache gesetzlich nicht mehr zulässig ist.<br /><br />

                        Ihre übrigen Daten speichern wir nur, solange wir sie zur Erfüllung des konkreten Zwecks (z.B. zur Vertragserfüllung oder -abwicklung) 
                        benötigen und löschen sie nach Wegfall des Zwecks. 
                    </eon-ui-text>
                </div>

                <eon-ui-text class="hydrated text-blue" text-style="rtf">
                    Ihre Rechte
                </eon-ui-text>

                <div class="px-8 py-2">
                    <eon-ui-text text-style="copy">
                        Für die Verarbeitung Ihrer Daten ist, soweit nicht anders ausgewiesen, verantwortlich:<br /><br />

                        <b>Bayernwerk Akademie GmbH</b><br />
                        Lilienthalstraße 7<br />
                        93049 Regensburg<br /><br />

                        Sie können jederzeit von uns Auskunft zu den über Sie gespeicherten Daten und deren Berichtigung im Fall von Fehlern verlangen. 
                        Weiter können Sie die Einschränkung der Verarbeitung, die Übertragbarkeit der uns durch Sie bereitgestellten Daten in einem 
                        maschinenlesbaren Format oder die Löschung Ihrer Daten – soweit sie nicht mehr benötigt werden – verlangen. 
                        Außerdem haben Sie jederzeit das Recht, uns gegenüber der Nutzung Ihrer Daten, die auf öffentlichen oder berechtigten Interessen beruhen, 
                        zu widersprechen.<br /><br />

                        Wir benötigen die in unseren Logfiles erfassten Daten zwingend, um den Betrieb der Website aufrechtzuhalten. 
                        Daher besteht hinsichtlich dieser Verarbeitung kein Widerspruchsrecht.<br /><br />

                        Bei den Cookies, die wir mit Ihrer Einwilligung gesetzt haben, haben wir jeweils eine technische Möglichkeit zu, Widerruf dieser Einwilligung
                        angegeben. Das ist mit dem Begriff Opt Out bezeichnet worden.<br /><br />

                        Soweit wir Ihre Daten auf der Grundlage einer von Ihnen abgegebenen Einwilligung verarbeiten, 
                        können Sie uns gegenüber jederzeit mit Wirkung für die Zukunft diese Einwilligung widerrufen. 
                        Ab dem Eingang Ihres Widerrufs verarbeiten wir Ihre Daten nicht mehr für die im Rahmen der Einwilligung angegebenen Zwecke.<br /><br />

                        Zudem können Sie sich jederzeit mit einer Beschwerde an eine Aufsichtsbehörde wenden. Die für uns zuständige Behörde ist:<br /><br />

                        <b>Bayerisches Landesamt für Datenschutzaufsicht</b><br />
                        Promenade 18<br />
                        91522 Ansbach<br /><br />

                        Telefon: 0981/180093-0<br />
                        Telefax: 0981/180093-800<br />
                        E-Mail: <eon-ui-link href="mailto: poststelle@lda.bayern.de" text="poststelle@lda.bayern.de" display-inline="true" class="hydrated" scheme="turquoise"></eon-ui-link> <br /><br />

                        Alternativ können Sie auf die für Sie örtlich zuständige Aufsichtsbehörde zuzugehen.
                    </eon-ui-text>
                </div>

                <eon-ui-text class="hydrated text-blue" text-style="rtf">
                    Datenschutzbeauftragter
                </eon-ui-text>

                <div class="px-8 py-2">
                    <eon-ui-text text-style="copy">
                        Unseren Datenschutzbeauftragten erreichen Sie unter:<br /><br />

                        <b>Datenschutzbeauftragter der Bayernwerk Akademie GmbH</b><br /><br />

                        Lilienthalstraße 7<br />
                        93049 Regensburg<br /><br />

                        E-Mail: <eon-ui-link href="mailto: datenschutz@bayernwerk.de" text="datenschutz@bayernwerk.de" display-inline="true" class="hydrated" scheme="turquoise"></eon-ui-link> <br /><br />

                        Wenn Sie mehr zu der Datenverarbeitung erfahren möchten, wenden Sie sich bitte an Ihren zuständigen Ansprechpartner in unserem Hause.
                    </eon-ui-text>
                </div>

            </eon-ui-grid-control-column>
        </eon-ui-grid-control>
    </eon-ui-section>

</template>
