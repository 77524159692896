<template>

<!-- <eon-ui-section >
<eon-ui-image lazy src="https://www.bayernwerk-shop.de/media/image/55/38/53/Header-Schulungen_1920x1080.jpg"
    alt="Technisches Trainingscenter der Bayernwerk Netz GmbH"
    title="Technisches Trainingscenter der Bayernwerk Netz GmbH" use-as-background="true" >
</eon-ui-image>
</eon-ui-section> -->

    <eon-ui-section class="hydrated">

        <!-- <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Startseite</a>
                <a href="/meine_befaehigungen">Meine Befähigungen</a>
            </eon-ui-breadcrumb>
        </div> -->

        <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">

                <eon-ui-headline text="Meine Befähigungen" size="h4" class="hydrated text-blue" v-if="Mitarbeiter_ID != ''"></eon-ui-headline>
                <eon-ui-headline text="Hierfür ist eine Anmeldung erforderlich." size="h4" class="hydrated text-blue" v-else></eon-ui-headline>

                <div v-if="Mitarbeiter_ID != ''">

                    <div class="pb-4">
                        <eon-ui-text text-style="copy">Hier finden Sie Ihre Befähigungen und deren Gültigkeiten.</eon-ui-text>
                    </div>

                    <eon-ui-data-table zebra="true" selectable-rows="false" expandable-rows="false">
                        <eon-ui-table-head>
                            <eon-ui-table-head-row>
                                <eon-ui-table-head-cell sortable="true" sort-key="befaehigung_id">Nummer</eon-ui-table-head-cell>
                                <eon-ui-table-head-cell sortable="true" sort-key="name">Name</eon-ui-table-head-cell>
                                <eon-ui-table-head-cell sortable="true" sort-key="gueltigkeit" sort-order="asc">gültig bis</eon-ui-table-head-cell>
                                <eon-ui-table-head-cell></eon-ui-table-head-cell>
                            </eon-ui-table-head-row>
                        </eon-ui-table-head>
                        <eon-ui-table-body>
                            <eon-ui-table-row v-for="item in dataTable" :key="item.befaehigung_id">
                                <eon-ui-table-cell :value="item.nummer" v-if="item.aktuell == 1"> {{ item.nummer }}</eon-ui-table-cell>
                                <eon-ui-table-cell :value="item.name" v-if="item.aktuell == 1"> {{ item.name }}</eon-ui-table-cell>
                                <eon-ui-table-cell :value="item.gueltigkeit" v-if="item.aktuell == 1"> {{ item.gueltigkeit }}</eon-ui-table-cell>
                                <eon-ui-table-cell v-if="item.aktuell !=''">
                                    <span v-if="item.zulassungseinschraenkungstext != '' && item.aktuell != 0">{{ item.zulassungseinschraenkungstext }}</span>
                                    <span v-if="item.zulassungseinschraenkungstext != '' && item.entzogen != 0 && item.aktuell != 0"><br /><br /></span>
                                    <span v-if="item.entzogen != 0 && item.aktuell != 0">Zulassung entzogen</span>
                                </eon-ui-table-cell>
                            </eon-ui-table-row>
                        </eon-ui-table-body>
                    </eon-ui-data-table>

                </div>

            </eon-ui-grid-control-column>
        </eon-ui-grid-control>

    </eon-ui-section>

    <div class="loading-section" :class="loading ? 'show' : ''">
        <eon-ui-preloader-circle></eon-ui-preloader-circle>
    </div>

</template>

<script>
import Resource from "../../composables/bayernwerk";
import { formatDateDMY } from "../../utils/filter";
import _ from "lodash";
const bayernwerkResource = new Resource();

export default {
    name: "getListBefaehigungen",
    data() {
        return {
            dataTable: [],
            Mitarbeiter_ID: this.$store.state.Tetra_id,
            loading: false,
        };
    },
    computed: {
    user_token() {
      return this.$store.state.token;
    },
  },
    watch: {
        user_token(newUserData, oldUserData) {
            this.Mitarbeiter_ID = this.$store.state.Tetra_id;
            this.fetchDataBefaehigungen(this.Mitarbeiter_ID);
        }
    },
    mounted(){
        this.Mitarbeiter_ID = this.$store.state.Tetra_id;
        this.fetchDataBefaehigungen(this.$store.state.Tetra_id);
    },
    created() {
    },
    setup() {
    },
    methods: {
        fetchDataBefaehigungen(Mitarbeiter_ID) {
            // console.log(this.$store.state.Tetra_id);
            this.loading = true;
            bayernwerkResource.getListBefaehigungen({Mitarbeiter_ID: Mitarbeiter_ID}).then((res) => {
                if (res != null) {
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;
                        this.dataTable = data_array.map((value) => {
                        return {
                            name: value.fieldData.name,
                            nummer: value.fieldData.nr,
                            abgelaufen: value.fieldData.abgelaufen,
                            aktuell: value.fieldData.aktuell,
                            entzogen: value.fieldData.entzogen,
                            gueltigkeit: formatDateDMY(value.fieldData.gültigkeit_datum_anzeige),
                            gueltigkeit_datum_neu: formatDateDMY(value.fieldData.gültigkeit_datum_neu),
                            gueltigkeit_datum: formatDateDMY(value.fieldData.gültigkeit_datum),
                            zulassungseinschraenkung: value.fieldData.Zulassungseinschraenkung,
                            zulassungseinschraenkungstext: value.fieldData.Zulassungseinschraenkungstext,
                            befaehigung_id: value.fieldData.befaehigung_id
                        };
                    });
                } else {
                        this.text_error = res.error_msg;
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        const code = res.messages[0].code;
                        if (code == 952 || code == 10) {
                            this.logoutTokenExpired();
                        }
                    }
                }else{
                    this.text_error = "Token expired";
                    this.message_visible = true;
                    this.logoutTokenExpired();
                }
                this.loading = false;
                }).catch((error) => {
                this.loading = false;
                console.log("er", error);
                const code = _.get(error, "response.data.messages[0].code", 0);
                if (code == 952 || code == 10) {
                    this.logoutTokenExpired();
                }
            });
        },
        logoutTokenExpired() {
            this.$store.dispatch("user/logout");
            setTimeout(() => this.$router.go(), this.$router.push({ name: "startseite" }), 4000);
        }
    },
};
</script>
