<template>
    <eon-ui-error-message icon-name="radio_checkbox_tick" :is-visible="message_visible_reset" inner-background="eon-bag-blue" class="hydrated custom-message-success" fixed-position="true" close-timer="5" >
        <eon-ui-headline slot="headline" size="h5" :text="text_success" class="hydrated"></eon-ui-headline>
        <eon-ui-text size="small" text-style="copy-small">Rückkehr zur Startseite nach 3 Sekunden...</eon-ui-text>
    </eon-ui-error-message>

    <eon-ui-section class="hydrated">

        <eon-ui-grid-control grid="grid__d4-4-4__t12" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated"></eon-ui-grid-control-column>
            <eon-ui-grid-control-column slot="column-2" class="hydrated">
                <div class="form-group">
                    <eon-ui-headline text="Passwort ändern" size="h4" class="hydrated text-blue"></eon-ui-headline>
                </div>
                <eon-ui-form ref="formResetPassword" method="post" enctype="application/x-www-form-urlencoded" class="hydrated form-login"
                    @formSubmit="submitFormResetPassword">
                    <div class="form-group">
                        <eon-ui-input v-model="reset_password.email" read-only="true" type="email" class="input-custom" label="E-Mail-Adresse:" id="email" name="email">
                        </eon-ui-input>
                    </div>
                    <div class="form-group" v-if="!this.$route.query.token">
                        <eon-ui-input v-model="reset_password.current_password" required type="password" class="input-custom" label="Aktuelles Passwort: *" id="current_password" name="current_password">
                        </eon-ui-input>
                    </div>
                    <div class="form-group">
                        <eon-ui-input v-model="reset_password.new_password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required type="password" class="input-custom" label="Neues Passwort: *" name="new_password" id="new_password">
                        </eon-ui-input>
                    </div>
                    <div class="form-group mb-2">
                        <eon-ui-input v-model="reset_password.confirm_password" pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}" required type="password" class="input-custom" label="Neues Passwort bestätigen: *" name="confirm_password" id="confirm_password">
                        </eon-ui-input>
                    </div>
                    <div class="form-group mb-6" align="right">
                        <eon-ui-button :preloader="loading" text="Abschicken" input-type="submit" class="btn-custom-blue hydrated" inner-background="eon-bag-blue"
                            style="--focusBorderColor: transparent"></eon-ui-button>
                    </div>
                    <div class="form-group">
                        <eon-ui-form-message type="danger" :message="error_msg" class="hydrated error-red"></eon-ui-form-message>
                    </div>
                    <div slot="200">
                        <eon-ui-form-message type="info" message="Anfrage erfolgreich" class="hydrated"></eon-ui-form-message>
                    </div>
                    <div slot="500">
                        <eon-ui-form-message type="warning" message="Anfrage fehlgeschlagen" class="hydrated"></eon-ui-form-message>
                    </div>
                </eon-ui-form>
            </eon-ui-grid-control-column>
            <eon-ui-grid-control-column slot="column-3" class="hydrated"></eon-ui-grid-control-column>
        </eon-ui-grid-control>
    </eon-ui-section>
</template>

<script>

import Resource from "../../composables/bayernwerk";

const bayernwerkResource = new Resource();

  export default {
    name: "ResetPassword",
    setup() {
    },
    data() {
    return {
        reset_password: {
            email: this.$store.state.email ? this.$store.state.email : this.$route.query.email,
            token: this.$store.state.token ? this.$store.state.token : this.$route.query.token,
            current_password: "",
            new_password: "",
            confirm_password: "",
            valid_current_password: this.$store.state.passwort,
            status: ""
        },
        loading: false,
        error_msg: "",
        message_visible_reset: false,
        text_success: ""
    };
    },
    mounted() {
        // console.log(this.$route.query)
    },
    methods: {
        submitFormResetPassword(event) {
            this.loading = true;
            event.preventDefault();
            this.error_msg = '';
            if(this.$store.state.token) {
                this.status = "new"
            };
            bayernwerkResource.resetPasswordBayernWerk(this.reset_password).then((res) => {
            if (res != null) {
                if(res.messages && res.messages[0]['code']== "0" ){
                    this.text_success = "Passwort erfolgreich zurückgesetzt!";
                    this.message_visible_reset = true;
                    this.logoutUser();
                    setTimeout( () => this.$router.push({ name: "startseite" }), 6000);
                }else{
                    if(res.error_msg){
                        this.error_msg = res.error_msg;
                    }else{
                        this.error_msg = res.messages[0].message;
                    }
                }
            }
            this.loading = false;
          }).catch((error) => {
            console.log(error);
            this.error_msg = 'Login Error!';
            this.loading = false;
          });
        },
        logoutUser() {
            this.$store.dispatch("user/logout");
        },
    },
  };
  </script>
