<template>
  <eon-ui-section class="hydrated">
    <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
      <eon-ui-grid-control-column slot="column-1" class="hydrated">
        <eon-ui-headline
          size="h5"
          text="FAQ Lorem Ipsum"
          class="hydrated text-black"
        ></eon-ui-headline>
        <!-- <eon-ui-pictogram
          name="candy"
          aria-label="candy"
          class="hydrated"
        ></eon-ui-pictogram> -->

        <eon-ui-accordion
          mode="multiple"
          expand-on-desktop="false"
          expand-all="false"
          inner-background="eon-bag-lightgrey"
          class="hydrated accordion-no-bg"
          size="small"
          scheme="bright"
        >
          <eon-ui-accordion-pane
            pane-title="Nutzerkonto"
            slot="Pane1"
            is-active="false"
            class="hydrated"
          >
            <eon-ui-accordion
              mode="multiple"
              expand-on-desktop="false"
              expand-all="true"
              inner-background="eon-bag-lightgrey"
              class="hydrated px-6 accordion-no-bar"
              size="small"
            >
              <eon-ui-accordion-pane
                pane-title="Ich weiß meinen Nutzernamen nicht."
                slot="Pane11"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Bitte senden Sie eine Nachricht an den Lorem Ipsum
                  (<eon-ui-link
                    size="small"
                    href="mailto:lorem-ipsum@bayernwerk.de"
                    text="lorem-ipsum@bayernwerk.de"
                    target="_self"
                    align-icon-center="true"
                    hide-icon="true"
                    class="hydrated text-link-blue fs-14"
                  ></eon-ui-link
                  >). Geben Sie Ihren vollständigen Namen, die Dienststelle und
                  Ihr Geburtsjahr an.</eon-ui-text
                >
              </eon-ui-accordion-pane>
              <eon-ui-accordion-pane
                pane-title="Ich weiß mein Passwort nicht."
                slot="Pane12"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Bitte nutzen Sie die Passwort-vergessen-Funktion. Sie finden
                  den Button links neben dem Login-Button. Geben Sie die in
                  Ihrem Datensatz hinterlegte E-Mailadresse und den Nutzernamen
                  an und bestätigen Sie, dass Sie kein Roboter sind. Sie
                  erhalten dann eine E-Mail mit einem Link zugesendet, über den
                  Sie ein neues Passwort festlegen können. Beachten Sie dabei
                  unbedingt die angegebenen Hinweise zur notwendigen
                  Passwortstruktur!</eon-ui-text
                >
              </eon-ui-accordion-pane>
              <eon-ui-accordion-pane
                pane-title="Meine Daten haben sich geändert."
                slot="Pane13"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Loggen Sie sich ein und rufen Sie in der linken Menüleiste
                  den Bereich "<router-link :to="{ name: 'profile' }">
                    <eon-ui-link
                      size="small"
                      href=""
                      text="Meine Daten"
                      target="_self"
                      align-icon-center="true"
                      hide-icon="true"
                      class="fs-14 hydrated text-link-blue"
                    ></eon-ui-link> </router-link
                  >" auf. Hier können Sie unter „Allgemein“ und „Dienststelle“
                  alle Daten, außer dem Nutzernamen, korrigieren bzw. ergänzen.
                  Können Sie sich nicht mehr Einloggen, haben aber eine
                  veraltete e-Mail-Adresse hinterlegt, können Sie eine e-Mail an
                  <eon-ui-link
                    size="small"
                    href="mailto:lorem-ipsum@bayernwerk.de"
                    text="lorem-ipsum@bayernwerk.de"
                    target="_self"
                    align-icon-center="true"
                    hide-icon="true"
                    class="hydrated text-link-blue fs-14"
                  ></eon-ui-link>
                  mit Ihrem Namen und Geburtsjahr zur Legitimation sowie der
                  neuen e-Mail-Adresse senden.</eon-ui-text
                >
              </eon-ui-accordion-pane>
              <eon-ui-accordion-pane
                pane-title="Ich möchte mein Konto löschen."
                slot="Pane14"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Loggen Sie sich ein und rufen Sie in der linken Menüleiste
                  den Bereich "<router-link :to="{ name: 'profile' }">
                    <eon-ui-link
                      size="small"
                      href=""
                      text="Meine Daten"
                      target="_self"
                      align-icon-center="true"
                      hide-icon="true"
                      class="fs-14 hydrated text-link-blue"
                    ></eon-ui-link> </router-link
                  >" auf. Wählen Sie im oberen Bereich „Anonymisierung/Löschen“.
                  Füllen Sie die erforderlichen Felder aus und senden Sie den
                  Antrag ab. Ihr Nutzer-Account und die personenbezogenen Daten
                  werden in der Regel innerhalb von 3 Werktagen
                  gelöscht.</eon-ui-text
                >
              </eon-ui-accordion-pane>
            </eon-ui-accordion>
          </eon-ui-accordion-pane>

          <eon-ui-accordion-pane
            pane-title="Registrierung"
            slot="Pane2"
            is-active="false"
            class="hydrated"
          >
            <eon-ui-accordion
              mode="multiple"
              expand-on-desktop="false"
              expand-all="true"
              inner-background="eon-bag-lightgrey"
              class="hydrated px-6 accordion-no-bar"
              size="small"
            >
              <eon-ui-accordion-pane
                pane-title='Fehleranzeige: "Nutzer existiert bereits" bzw. "Nutzername ist schon vorhanden"'
                slot="Pane21"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Der von Ihnen gewählte Name wird bereits von einem anderen
                  Nutzer verwendet. Wählen Sie einen anderen Begriff bzw.
                  bearbeiten Sie Ihre Auswahl. Sollte die Meldung weiterhin
                  erscheinen, wenden Sie sich bitte an den Lorem Ipsum
                  (<eon-ui-link
                    size="small"
                    href="mailto:lorem-ipsum@bayernwerk.de"
                    text="lorem-ipsum@bayernwerk.de"
                    target="_self"
                    align-icon-center="true"
                    hide-icon="true"
                    class="hydrated text-link-blue fs-14"
                  ></eon-ui-link
                  >).</eon-ui-text
                >
              </eon-ui-accordion-pane>
              <eon-ui-accordion-pane
                pane-title="Ich finde meine Dienststelle nicht."
                slot="Pane22"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Suchbegriffe werden zeichengenau mit der Datenbank
                  abgeglichen. Geben Sie deshalb in das Suchfeld so wenig wie
                  möglich und so viel wie nötig ein. Suchen Sie bei kleineren
                  Orten zum Beispiel nach dem Ort oder suchen Sie nach Teilen
                  der Dienststellenbezeichnung (z. B. „Bebel“ für die
                  Sekundarschule "August Bebel" in Leuna).</eon-ui-text
                >
              </eon-ui-accordion-pane>
              <eon-ui-accordion-pane
                pane-title="Muss ich meine Personalnummer angeben?"
                slot="Pane22"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Die Personalnummer ist kein Pflichtfeld. Sie können Sie
                  angeben, da es unter Umständen hilfreich bei der Suche nach
                  Ihrem Datensatz ist.</eon-ui-text
                >
              </eon-ui-accordion-pane>
            </eon-ui-accordion>
          </eon-ui-accordion-pane>

          <eon-ui-accordion-pane
            pane-title="Katalog/Fortbildungsanmeldung"
            slot="Pane3"
            is-active="false"
            class="hydrated"
          >
            <eon-ui-accordion
              mode="multiple"
              expand-on-desktop="false"
              expand-all="true"
              inner-background="eon-bag-lightgrey"
              class="hydrated px-6 accordion-no-bar"
              size="small"
            >
              <eon-ui-accordion-pane
                pane-title="Wie kann ich nach Veranstaltungen suchen?"
                slot="Pane31"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Versuchen Sie so wenig wie möglich und so viel wie nötig
                  einzugeben. Bei „Suche 1 Wort“ können Sie nach Stichworten
                  suchen. Ist Ihnen die Veranstaltungsnummer bekannt, geben Sie
                  diese dort ohne Leerzeichen ein.</eon-ui-text
                >
              </eon-ui-accordion-pane>
              <eon-ui-accordion-pane
                pane-title="Ich finde die von mir gesuchte Veranstaltung nicht."
                slot="Pane32"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Prüfen Sie, ob Sie sich auf die nötigsten Suchparameter
                  beschränkt haben (siehe auch "Wie kann ich nach
                  Veranstaltungen suchen?").</eon-ui-text
                >
              </eon-ui-accordion-pane>
              <eon-ui-accordion-pane
                pane-title="Kann ich mich auch nach dem Anmeldeschluss anmelden?"
                slot="Pane33"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Eine Anmeldung nach dem Anmeldeschluss ist möglich. Sofern
                  noch genügend freie Plätze vorhanden sind und Sie zum
                  Adressatenkreis gehören, könnte die Teilnahme möglich
                  sein.</eon-ui-text
                >
              </eon-ui-accordion-pane>
              <eon-ui-accordion-pane
                pane-title="Kann ich eine*n Kolleg*in zusätzlich mit anmelden?"
                slot="Pane34"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Nein. Die Anmeldung zu einer Veranstaltung ist
                  personenbezogen und kann nur durch Teilnehmende selbst auf dem
                  Lorem Ipsum Portal vorgenommen werden.</eon-ui-text
                >
              </eon-ui-accordion-pane>
              <eon-ui-accordion-pane
                pane-title="Wie kann ich mich von einer Veranstaltung abmelden?"
                slot="Pane35"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Enthält die Veranstaltungsnummer den Buchstaben „F“ können
                  Sie die Abmeldung nach dem Einloggen unter <router-link
                    :to="{ name: 'profile' }"
                  >
                    <eon-ui-link
                      size="small"
                      href=""
                      text="„Meine Anmeldungen“"
                      target="_self"
                      align-icon-center="true"
                      hide-icon="true"
                      class="fs-14 hydrated text-link-blue"
                    ></eon-ui-link> </router-link
                  > vornehmen. In der Auflistung der Veranstaltungen ist ein
                  Button „Abmelden“, wenn die Änderung des Status noch möglich
                  ist. Ist der Button nicht mehr aktiv, wenden Sie sich an das
                  LISA-Veranstaltungsmanagement (<eon-ui-link
                    size="small"
                    href="mailto:lisa-kursorganisation@sachsen-anhalt.de"
                    text="lisa-kursorganisation@sachsen-anhalt.de"
                    target="_self"
                    align-icon-center="true"
                    hide-icon="true"
                    class="hydrated text-link-blue fs-14"
                  ></eon-ui-link
                  >). Ebenso verfahren Sie bitte, wenn die Veranstaltungsnummer
                  einen anderen Buchstaben enthält.</eon-ui-text
                >
              </eon-ui-accordion-pane>
              <eon-ui-accordion-pane
                pane-title="Kann ich mir Veranstaltungen vormerken?"
                slot="Pane36"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Ja. Sie haben bei jeder Veranstaltung im Katalog die
                  Möglichkeit, ein Lesezeichen zu setzen. Bei der Auflistung des
                  Suchergebnisses unter
                  <router-link :to="{ name: 'katalog' }">
                    <eon-ui-link
                      size="small"
                      href=""
                      text="„Katalog“"
                      target="_self"
                      align-icon-center="true"
                      hide-icon="true"
                      class="fs-14 hydrated text-link-blue"
                    ></eon-ui-link
                  ></router-link>
                  finden Sie in der Spalte Optionen ein Kontrollkästchen. Setzen
                  Sie dort den Haken, wird die Veranstaltung auch unter „Meine
                  Lesezeichen“ aufgelistet.</eon-ui-text
                >
              </eon-ui-accordion-pane>
            </eon-ui-accordion>
          </eon-ui-accordion-pane>
          <eon-ui-accordion-pane
            pane-title="Veranstaltungsteilnahme"
            slot="Pane5"
            is-active="false"
            class="hydrated"
          >
          <eon-ui-text class="" size="small" text-style="copy-small"
                  >Erhalte ich immer eine Einladung?</eon-ui-text>
          </eon-ui-accordion-pane>
          <eon-ui-accordion-pane
            pane-title="Ich habe eine andere Frage."
            slot="Pane6"
            is-active="false"
            class="hydrated"
          >
          <eon-ui-accordion
              mode="multiple"
              expand-on-desktop="false"
              expand-all="true"
              inner-background="eon-bag-lightgrey"
              class="hydrated px-6 accordion-no-bar"
              size="small"
            >
              <eon-ui-accordion-pane
                pane-title="Etiam volutpat posuere est ut scelerisque"
                slot="Pane61"
                is-active="false"
                class="hydrated"
              >
                <eon-ui-text class="" size="small" text-style="copy-small"
                  >Lorem ipsum dolor sit amet, consectetur adipiscing elit (<eon-ui-link
                    size="small"
                    href="mailto:lorem-ipsum@bayernwerk.de"
                    text="lorem-ipsum@bayernwerk.de"
                    target="_self"
                    align-icon-center="true"
                    hide-icon="true"
                    class="hydrated text-link-blue fs-14"
                  ></eon-ui-link
                  >).
                </eon-ui-text>
              </eon-ui-accordion-pane>
            </eon-ui-accordion>
          </eon-ui-accordion-pane>
        </eon-ui-accordion>
      </eon-ui-grid-control-column>
    </eon-ui-grid-control>
  </eon-ui-section>
</template>

<script>
export default {
  name: "Faq",
  setup() {
  },
  methods: {
  },
};
</script>
