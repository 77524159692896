// dd.mm.yyyy
export function formatDateDMY(inputDate) {
  var date = new Date(inputDate);
  if (!isNaN(date.getTime())) {
    var day = date.getDate().toString();
    var month = (date.getMonth() + 1).toString();
    return (
      (day[1] ? day : "0" + day[0]) +
      "." +
      (month[1] ? month : "0" + month[0]) +
      "." +
      date.getFullYear()
    );
  }
}

export function formatPrice(value) {
  let val = (value / 1).toFixed(2).replace(".", ",");
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}

// 01/10/2023 mm/dd/yyyy
export function compareDateToday(value) {
  var yesterday = new Date(Date.now() - 86400000);
  if (new Date(value) <= yesterday) {
    return false;
  } else {
    return true;
  }
}
