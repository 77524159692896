<template>
    <eon-ui-error-message style="" icon-name="radio_checkbox_tick" :is-visible="message_visible"
        inner-background="eon-bag-green" class="hydrated" fixed-position="true">
        <eon-ui-headline slot="headline" size="h5" :text="text_error" class="hydrated"></eon-ui-headline>
    </eon-ui-error-message>

    <eon-ui-section class="hydrated">

        <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">
                <eon-ui-headline text="Für Bildungspartner" size="h4" class="hydrated text-blue" ></eon-ui-headline>
                <eon-ui-text size="small" text-style="copy">
                    Als Bildungspartner der Bayernwerk Akademie verknüpfst du dein Netzwerk mit unserem und erweiterst gemeinsam die Grenzen des Wissens.
                    Profitiere von einer starken Vernetzung und dem Austausch mit einem führenden Energieunternehmen.
                    Gemeinsam fördern wir die Entwicklung zukunftsfähiger Kompetenzen und erweitern Bildungsmöglichkeiten,
                    sowohl regional als auch branchenübergreifend. Unsere Kooperationen sind geprägt durch Innovation, Praxisnähe und gegenseitigen Wissenstransfer.
                </eon-ui-text>
            </eon-ui-grid-control-column>
        </eon-ui-grid-control>
        
        
        <div id="bp-banner" class="bp-element">
            <div>
                <div>
                    <eon-ui-text text-style="subheadline">Erweitere dein Bildungsangebot und werde Teil eines zukunftsorientierten Wissensnetzwerks.</eon-ui-text>
                </div>
                <div class="custom-button" v-on:click="becomePartner" >
                    <div>
                        <eon-ui-icon name='arrow_right'></eon-ui-icon>
                    </div>
                    <div>Jetzt Partner werden</div>
                </div>
                <!-- <eon-ui-button class="btn-custom-blue" text="<eon-ui-icon name='arrow_right'></eon-ui-icon>Jetzt Bildungspartner werden" input-type="button" v-on:click="becomePartner"></eon-ui-button> -->
            </div>
            <div class="bw-img">
                <img src="/images/AcademyResources/Bildungspartner/hoersaal.jpg" 
                title="Hörsaal" alt="Hörsaal" />
            </div>
        </div>

        <div class="bp-element">
            <eon-ui-grid-control grid="grid__d8-4__t8-4" >
                <eon-ui-grid-control-column slot="column-1" class="hydrated">
                    <eon-ui-text text-style="subheadline">Das Technische Trainings-Center der Bayernwerk Netz</eon-ui-text>
                    <eon-ui-text size="small" text-style="copy">
                        Im Technischen Trainingscenter (TTC), vereinen wir Expertenwissen mit langjähriger Erfahrung aus der Praxis für die Praxis.
                        Unsere Angebote sind speziell darauf ausgerichtet, theoretisches Wissen und praktische Anwendung nahtlos zu verbinden.
                        Teilnehmende stehen im Mittelpunkt: Unsere Schulungen sind individuell auf deine Bedürfnisse zugeschnitten und werden von erfahrenen Fachkräften geleitet,
                        die nicht nur lehren, sondern auch als Mentoren auf Augenhöhe agieren.<br />
                        Verstärke deine beruflichen Fähigkeiten in einem dynamischen und unterstützenden Umfeld.<br /><br />
                        <strong>Erlebe, wie Theorie und Praxis lebendig werden. Entdecke unsere Kurse am TTC.</strong>
                    </eon-ui-text>
                </eon-ui-grid-control-column>
                <eon-ui-grid-control-column slot="column-2" class="hydrated">
                    <eon-ui-carousel controls="true" pause="true" autoplay="4000" indicators="true" indicator-position="center" indicator-size="small"  class="hydrated">
                        <eon-ui-carousel-item class="hydrated">
                            <eon-ui-image src="/images/AcademyResources/Bildungspartner/image_14.jpg" title="" alt="" class="hydrated"></eon-ui-image>
                        </eon-ui-carousel-item>
                        <eon-ui-carousel-item class="hydrated">
                            <eon-ui-image src="/images/AcademyResources/Bildungspartner/image_15.jpg" title="" alt="" class="hydrated"></eon-ui-image>
                        </eon-ui-carousel-item>
                        <eon-ui-carousel-item class="hydrated">
                            <eon-ui-image src="/images/AcademyResources/Bildungspartner/image_16.jpg" title="" alt="" class="hydrated"></eon-ui-image>
                        </eon-ui-carousel-item>
                        <eon-ui-carousel-item class="hydrated">
                            <eon-ui-image src="/images/AcademyResources/Bildungspartner/image_17.jpg" title="" alt="" class="hydrated"></eon-ui-image>
                        </eon-ui-carousel-item>
                        <eon-ui-carousel-item class="hydrated">
                            <eon-ui-image src="/images/AcademyResources/Bildungspartner/BayernwerkZentraleRegensburg_Unternehmenszentrale.jpg" title="Unternehmenszentrale" alt="Bayernwerk-Zentrale Regensburg" class="hydrated"></eon-ui-image>
                        </eon-ui-carousel-item>
                    </eon-ui-carousel>
                </eon-ui-grid-control-column>
            </eon-ui-grid-control>
        </div>

        <div class="bp-element">
            <eon-ui-grid-control grid="grid__d4-8__t4-8" same-height="true">
                <eon-ui-grid-control-column slot="column-1" class="hydrated">
                    <eon-ui-image src="/images/AcademyResources/Bildungspartner/ihk_akademie_ostbayern.jpg" title="IHK Akademie Ostbayern" alt="IHK Akademie Ostbayern, Außenansicht" class="hydrated bp-ihk-image"></eon-ui-image>
                    <a target="_blank" href="https://de.wikipedia.org/wiki/IHK_Regensburg_für_Oberpfalz_/_Kelheim" class="bp-image-ref-link">Bildnachweis</a>
                </eon-ui-grid-control-column>
                <eon-ui-grid-control-column slot="column-2" class="hydrated">
                    <eon-ui-text text-style="subheadline">Die IHK Akademie in Ostbayern</eon-ui-text>
                    <eon-ui-text size="small" text-style="copy">
                        Verbinde dein Netzwerk mit der innovativen Kraft der Bayernwerk Akademie und der IHK Ostbayern.
                        Gemeinsam setzen wir neue Maßstäbe in der beruflichen Weiterbildung.
                        Unsere Partnerschaft mit der IHK Ostbayern erweitert die Möglichkeiten für Bildungspartner,
                        indem wir zusammen ein umfangreiches Spektrum an zertifizierten Kursen anbieten,
                        die sowohl theoretisches Wissen als auch praktische Anwendungen umfassen.
                        Ein Beispiel ist unsere Ausbildung Industriemeister:in Elektrotechnik.<br />
                        Profitiere von einem starken Netzwerk, das sich durch Qualität und Praxisnähe auszeichnet und dabei unterstützt, 
                        Deine Bildungsziele zu erreichen und Deine Teilnehmer optimal auf ihre beruflichen Aufgaben vorzubereiten.<br /><br />
                        <strong>Gestalte die Zukunft der Bildung mit uns.</strong>
                    </eon-ui-text>
                </eon-ui-grid-control-column>
            </eon-ui-grid-control>
        </div>


    </eon-ui-section>

    <div class="loading-section" :class="loading ? 'show' : ''">
        <eon-ui-preloader-circle></eon-ui-preloader-circle>
    </div>

</template>

<script>
import Resource from "../../composables/bayernwerk";

const bayernwerkResource = new Resource();

export default {
    name: "Bildungspartner",
    data() {
        return {}
    },
    computed: {
    user_token() {
    },
  },
    watch: {
    },
    mounted(){
    },
    created() {
    },
    setup() {
    },
    methods: {
        becomePartner() {
            this.$store.state.contactSubject = "Bildungspartner werden";
            this.$router.push({ name: "kontakt" });
        },
    },
};
</script>
