<template>
  <!-- <NavsideMenu /> -->
  <div class="w-full layout-bayernwerk">
    <div class="container-main">
      <header class="header">
        <div class="header-menu custom-Header"><!-- max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 -->
          <HeaderVue />
        </div>
      </header>
      <div class="bw-main max-w-7xl mx-auto sm:px-6 lg:px-8">
        <div class="overflow-hidden">
          <router-view />
        </div>
      </div>
    </div>
    <footer>
      <FooterVue />
    </footer>
  </div>
</template>

<script>
import HeaderVue from "./HeaderVue.vue";
// import NavtopMenu from "./NavtopMenu.vue";
// import NavsideMenu from "./NavsideMenu.vue";
import FooterVue from "./FooterVue.vue";
import { useStore } from "vuex";

export default {
  components: { HeaderVue, FooterVue },
  name: "layout",
  created() {},
  beforeCreate() {
    const store = useStore();
    store.commit("user/initialiseStore");
  },
  data() {
    return {
      loading: false,
    };
  },
  beforeMount() {},
  beforeUpdate() {},
  methods: {},
};
</script>
