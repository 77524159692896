<template>
    <div>
        <eon-ui-error-message style="" icon-name="radio_checkbox_tick" :is-visible="message_visible" inner-background="eon-bag-green" class="hydrated" fixed-position="true">
            <eon-ui-headline slot="headline" size="h5" :text="text_error" class="hydrated"></eon-ui-headline>
        </eon-ui-error-message>
        
        <div class="lp-banner">
            <div class="lp-banner-left">
                <eon-ui-image lazy src="/images/Logo/Academy/bayernwerk-akademie-Icon-farbig_162x206.svg" src-desktop="/images/Logo/Academy/bayernwerk-akademie-Icon-farbig_162x206.svg" src-mobile="/images/Logo/Academy/bayernwerk-akademie-Icon-farbig_162x206.svg"
                    alt="Technisches Trainingscenter der Bayernwerk Netz GmbH"
                    title="Technisches Trainingscenter der Bayernwerk Netz GmbH" height="auto"  width="162px" class="mb-6">
                </eon-ui-image>
                <div>
                    <div class="lp-banner-text-element">
                        <eon-ui-text text-style="subheadline"><strong>Baue deine Zukunft bei uns!</strong></eon-ui-text>
                    </div>
                    <div class="lp-banner-text-element">
                        <eon-ui-text text-style="subheadline" >
                            Von Zertifikaten bis zu Masterprogrammen – entdecke Bildungswege, die dich voranbringen.
                        </eon-ui-text>
                    </div>
                    <div class="btn-custom-blue custom-button" v-on:click="scrollTo('quote-carousel')" >
                        <div>
                            <eon-ui-icon name='arrow_right'></eon-ui-icon>
                        </div>
                        <div> Jetzt erkunden!</div>
                    </div>
                    <!-- <eon-ui-button class="btn-custom-blue" text="Jetzt mehr erfahren" input-type="button" v-on:click="scrollTo('quote-carousel')"></eon-ui-button> -->
                </div>
            </div>
            <div class="lp-banner-image-container bw-img-start">
                <img lazy src="/images/AcademyResources/Startseite/Keyvisual_Loeten_Quer.jpg" 
                    title="Keyvisual Loeten Quer" alt="Mann beim Löten"
                    class="mb-6 lp-banner-image" />
            </div>
        </div>

        <eon-ui-section remove-top-spacing="true">
            <br/>
            <eon-ui-grid-control grid="grid__d12__t12">
                <eon-ui-grid-control-column slot="column-1" class="hydrated">
                    <eon-ui-text text-style="subheadline">Willkommen bei der Bayernwerk Akademie – Dein Wissensnetzwerk für Zukunftsgestaltung</eon-ui-text>
                    <eon-ui-text size="small" text-style="copy">
                        Entdecke maßgeschneiderte, praxisorientierte Bildungsprogramme, die Pioniere wie dich für morgen rüsten.
                        Unsere Experten fördern mit modernen Lehrmethoden und digitalen Tools dein effektives Lernen. Offen für alle,
                        die aktiv die Zukunft mitgestalten wollen, unterstützt die Akademie als dein Wegbegleiter deine berufliche Entwicklung in einem innovativen Umfeld.
                        Mach dich bereit, das Meisterwerk deiner Zukunft zu gestalten und werde Teil unseres Wissensnetzwerks!
                    </eon-ui-text>
                </eon-ui-grid-control-column>
            </eon-ui-grid-control>

            <div ref="quote-carousel" class="mt_4rem">
                <eon-ui-grid-control grid="grid__d12__t12">
                    <eon-ui-grid-control-column slot="column-1" class="hydrated">
                        <eon-ui-carousel controls="true" pause="true" autoplay="4000" indicators="true" indicator-position="center" indicator-size="small"  class="hydrated">
                            
                            <eon-ui-carousel-item class="hydrated">
                                <div class="lp-carousel-padding">
                                    <eon-ui-grid-control grid="grid__d6-6__t6-6">
                                        <eon-ui-grid-control-column slot="column-1" class="hydrated">
                                            <div class="lp-capousel-text">
                                                <p class="lp-carousel-textblock" size="small" text-style="abstract" >
                                                    “Wissensnetzwerk bedeutet für uns: zusammen kommen, zusammen wachsen. 
                                                    Mit der Akademie schaffen wir einen Raum in dem es Spaß macht gemeinsam zu lernen und zu wachsen. 
                                                    Wir nehmen die Herausforderungen von Fachkräftemangel, Energiewende und Digitalisierung an und bauen ein Netzwerk auf, 
                                                    das Zukunft gestaltet.”
                                                </p><br />
                                                <eon-ui-text text-style="caption">Sabrina Hanner und Jürgen Kandlbinder<br />(Geschäftsführung Bayernwerk Akademie GmbH)</eon-ui-text>
                                            </div>
                                        </eon-ui-grid-control-column>
                                        <eon-ui-grid-control-column slot="column-2" class="hydrated">
                                            <eon-ui-image src="/images/AcademyResources/Startseite/Facetune_08-03-2024-09-01-01.jpg" title="GeschäftsführungBayernwerkAkademieGmbH" alt="Sabrina Hanner und Jürgen Kandlbinder" class="hydrated"></eon-ui-image>
                                        </eon-ui-grid-control-column>
                                    </eon-ui-grid-control>
                                </div>
                            </eon-ui-carousel-item>

                            <eon-ui-carousel-item class="hydrated">
                                <div class="lp-carousel-padding">
                                    <eon-ui-grid-control grid="grid__d6-6__t6-6">
                                        <eon-ui-grid-control-column slot="column-1" class="hydrated">
                                            <div class="lp-capousel-text">
                                                <p class="lp-carousel-textblock" size="small" text-style="abstract" >
                                                    “Bei uns steht das gemeinsame Lernen im Vordergrund: Hand in Hand arbeiten wir in praxisnahen Trainings, 
                                                    teilen unser Fachwissen und fördern praktische Anwendung. So gestalten wir gemeinsam die Energiewende.”
                                                </p><br />
                                                <eon-ui-text text-style="caption">Johann Federl<br />(Leiter Technisches Trainingscenter)</eon-ui-text>
                                            </div>
                                        </eon-ui-grid-control-column>
                                        <eon-ui-grid-control-column slot="column-2" class="hydrated">
                                            <eon-ui-image src="/images/AcademyResources/Startseite/LeiterTechnischesTrainingscenter.jpg" title="LeiterTechnischesTrainingscenter" alt="Johann Federl" class="hydrated"></eon-ui-image>
                                        </eon-ui-grid-control-column>
                                    </eon-ui-grid-control>
                                </div>
                            </eon-ui-carousel-item>


                            <!--
                                <eon-ui-carousel-item class="hydrated">
                                    <div class="lp-carousel-padding">
                                        <eon-ui-grid-control grid="grid__d6-6__t6-6">
                                            <eon-ui-grid-control-column slot="column-1" class="hydrated">
                                                <div class="lp-capousel-text">
                                                    <p class="lp-carousel-textblock" size="small" text-style="abstract" >
                                                        “Mit dem Start der Akademie stehen uns spannende Aufgabe bevor: Der Aufbau eines Wissensnetzwerks, 
                                                        das sich den Herausforderungen unserer Zeit stellt und Lösungen für die Zukunft entwickelt. 
                                                        Vom Fachkräftemangel, über die Energiewende bis hin zur Digitalisierung – die Themen sind vielfältig 
                                                        und fordern unser ganzes Engagement.”
                                                    </p><br />
                                                    <eon-ui-text text-style="caption">Sabrina Hanner <br />(Geschäftsführung Bayernwerk Akademie)</eon-ui-text>
                                                </div>
                                            </eon-ui-grid-control-column>
                                            <eon-ui-grid-control-column slot="column-2" class="hydrated">
                                                <eon-ui-image src="/images/AcademyResources/Startseite/Rectangle_196.jpg" title="" alt="" class="hydrated"></eon-ui-image>
                                            </eon-ui-grid-control-column>
                                        </eon-ui-grid-control>
                                    </div>
                                </eon-ui-carousel-item>
                            -->
                        
                            
                        </eon-ui-carousel>
                    </eon-ui-grid-control-column>
                </eon-ui-grid-control>
            </div>

            <eon-ui-grid-control grid="grid__d4-4-4__t12" class="mt_4rem">
                
                <eon-ui-grid-control-column slot="column-1" class="hydrated lp-column-element" style="">
                    
                    <eon-ui-image src="/images/AcademyResources/Startseite/Keyvisual_Innovationsraum_Quer.jpg" 
                        title="Innovationsraum" alt="Innovationsraum" class="hydrated"></eon-ui-image>
                    <div class="lp-grid-text-block" >
                        <eon-ui-text text-style="subheadline" >Für Unternehmen</eon-ui-text>
                        <eon-ui-text size="small" text-style="copy" >
                            Stärke dein Team! Maßgeschneiderte Weiterbildung für deine Belegschaft.
                        </eon-ui-text>
                        <div>
                            <router-link :to="{ name: 'katalog', params: { detail: 'gesamt' } }">
                                <eon-ui-link text="Jetzt mehr erfahren" class="hydrated" scheme="turquoise"  icon-position="right"></eon-ui-link>
                            </router-link>
                        </div>
                    </div>
                </eon-ui-grid-control-column>

                <eon-ui-grid-control-column slot="column-2" class="hydrated lp-column-element" style="">
                    <eon-ui-image src="/images/AcademyResources/Startseite/Sidevisual_Kueche_Quer.jpg" 
                        title="Kueche" alt="Kueche" class="hydrated"></eon-ui-image>
                    <div class="lp-grid-text-block">
                        <eon-ui-text text-style="subheadline" >Für Bildungspartner: Neue Bildungslandschaft gestalten!</eon-ui-text>
                        <eon-ui-text size="small" text-style="copy" >
                            Erweitern wir gemeinsam die Grenzen des Wissens.
                        </eon-ui-text>
                        <div>
                            <eon-ui-link text="Jetzt Bildungspartner werden" v-on:click="goToContact('Bildungspartner werden')" class="hydrated" scheme="turquoise"  icon-position="right"></eon-ui-link>
                        </div>
                    </div>
                </eon-ui-grid-control-column>

                <eon-ui-grid-control-column slot="column-3" class="hydrated lp-column-element" style="">
                    <eon-ui-image src="/images/AcademyResources/Startseite/Keyvisual_Schaltschrank_Quer.jpg" 
                        title="Schaltschrank" alt="Schaltschrank" class="hydrated"></eon-ui-image>
                    <div class="lp-grid-text-block">
                        <eon-ui-text text-style="subheadline" >Für Energiepioniere</eon-ui-text>
                        <eon-ui-text size="small" text-style="copy" >
                            Deine Zukunft gestalten! Erweitere dein Wissen mit unseren fortschrittlichen Kursen.
                        </eon-ui-text>
                        <div></div>
                    </div>
                </eon-ui-grid-control-column>

            </eon-ui-grid-control>

            <eon-ui-grid-control grid="grid__d8-4__t8-4" class="mt_4rem">
                <eon-ui-grid-control-column slot="column-1" class="hydrated">
                    <eon-ui-text text-style="subheadline">Schulungen vor Ort: Flexibel und nah</eon-ui-text>
                    <eon-ui-text size="small" text-style="copy">
                        Unsere Schulungen beschränken sich nicht auf die Standorte des Technischen Trainingscenters.<br />
                        Wir bieten flexible Schulungsoptionen an, die auch in den 19 Kundencentern der Bayernwerk&nbsp;Netz&nbsp;GmbH oder bei unseren Bildungspartnern stattfinden können. 
                        Von Niederspannungsschaltungen in Ponholz bis hin zu Erdungsmesstechnik-Schulungen – wir erweitern ständig unsere Standorte, 
                        um Ihnen Bildung nah an Ihrem Wunschort zu bringen.<br /><br />
                        <strong>Möchten Sie Schulungen in Ihrer Nähe?</strong>
                    </eon-ui-text>
                    <div style="display: inline-block">
                        <eon-ui-link text="Jetzt anfragen" class="hydrated" icon-position="right" v-on:click="goToContact('')" ></eon-ui-link>
                    </div>
                </eon-ui-grid-control-column>
                <eon-ui-grid-control-column slot="column-2" class="hydrated">
                    <eon-ui-image src="/images/AcademyResources/Startseite/Bayernwerk-Ausbildungskarte-2024-jpg.jpg" title="Standorte des Bayernwerk Akademie GmbH" alt="Karte der Standorte der Bayernwerk Akademie GmbH" class="hydrated"></eon-ui-image>
                </eon-ui-grid-control-column>
            </eon-ui-grid-control>

            <div class="form-group mt_4rem lp-contact-headline">
                <eon-ui-headline size="h3" text="Jetzt Kontakt aufnehmen" class="hydrated "></eon-ui-headline>
            </div>
            <eon-ui-grid-control grid="grid__d6-6__t6-6" >
                <eon-ui-grid-control-column slot="column-1" class="hydrated">
                    
                    <eon-ui-form method="post" class="hydrated form-register" ref="formSaveContact"  @formsubmit="submitFormSaveContact" style="">

                        <!-- <div class="form-group lp-contact">
                            <eon-ui-text text-style="rtf">Vor- und Nachname: *</eon-ui-text>
                        </div> -->
                        <div class="form-group">
                            <eon-ui-input :value="contact_form.name" @valuechanged="(e)=>{changeValue(e.target.value, 'name')}"  :read-only="read_only" required :class="read_only ? 'input-custom input-readonly' : 'input-custom'" label="Vor- und Nachname" name="name" id="name" type="text">
                            </eon-ui-input>
                        </div>

                        <!-- <div class="form-group lp-contact">
                            <eon-ui-text text-style="rtf">Deine E-Mail Adresse: *</eon-ui-text>
                        </div> -->
                        <div class="form-group lp-contact">
                            <eon-ui-input  :value="contact_form.email" @valuechanged="(e)=>{changeValue(e.target.value, 'email')}"  :read-only="read_only" required :class="read_only ? 'input-custom input-readonly' : 'input-custom'" label="Deine E-Mail Adresse" name="email" type="text" id="email" >
                            </eon-ui-input>
                        </div>

                        <!-- <div class="form-group lp-contact">
                            <eon-ui-text text-style="rtf">Grund auswählen *</eon-ui-text>
                        </div> -->
                        <div class="form-group lp-contact">
                            <eon-ui-dropdown :value="contact_form.betreff" @valuechanged="(e)=>{changeValue(e.target.value, 'betreff')}"  value-key="value" required  name="betreff" label="Thema auswählen..."  class="input-custom hydrated" >
                                <!-- <eon-ui-dropdown-option value="" label="Entries"></eon-ui-dropdown-option> -->
                                <eon-ui-dropdown-option class="hydrated" value="Änderung/Stornierung Buchung" label="Änderung / Stornierung Buchung"></eon-ui-dropdown-option>
                                <eon-ui-dropdown-option class="hydrated" value="Nachfrage Schulungen" label="Nachfrage Schulungen"></eon-ui-dropdown-option>
                                <eon-ui-dropdown-option class="hydrated" value="Änderung Kontakt- / Rechnungsdaten" label="Änderung Kontakt- / Rechnungsdaten"></eon-ui-dropdown-option>
                                <eon-ui-dropdown-option class="hydrated" value="Änderung Mitarbeiterdaten" label="Änderung Mitarbeiterdaten"></eon-ui-dropdown-option>
                                <eon-ui-dropdown-option class="hydrated" value="Neuer Account" label="Neuer Account" v-if="this.$store.state.token != null && this.$store.state.Tetra_id == ''"></eon-ui-dropdown-option>

                                <eon-ui-dropdown-option class="hydrated" value="Bildungspartner werden" label="Bildungspartner werden"></eon-ui-dropdown-option>
                                <eon-ui-dropdown-option class="hydrated" value="Sonstiges" label="Sonstiges"></eon-ui-dropdown-option>
                            </eon-ui-dropdown>
                        </div>

                        <!-- <div class="form-group lp-contact">
                            <eon-ui-text text-style="rtf">Nachricht: *</eon-ui-text>
                        </div> -->
                        <div class="form-group lp-contact">
                            <eon-ui-input :value="contact_form.text" @valuechanged="(e)=>{changeValue(e.target.value, 'text')}"  required label="Nachricht" rows="5"  textarea="true"
                                name="nachricht"
                                class="hydrated text-black block input-custom">
                            </eon-ui-input>
                        </div>

                        <!-- <div id="lp-submit-btn" class="form-group lp-contact">
                            <eon-ui-button :preloader="loading" class="btn-custom-blue" text="Anfrage absenden" input-type="submit"></eon-ui-button>
                        </div> -->
                        
                        <div id="lp-submit-btn" class="custom-button" v-on:click="submitFormSaveContact" >
                            <div>
                                <eon-ui-icon name='arrow_right'></eon-ui-icon>
                            </div>
                            <div>Anfrage absenden</div>
                        </div>

                    </eon-ui-form>
                    
                </eon-ui-grid-control-column>
                <eon-ui-grid-control-column slot="column-2" class="hydrated">
                    <eon-ui-image src="/images/AcademyResources/Startseite/3D_Drucker_mit_Bayernwerk_Akademie_GmbH_Logo.jpg" title="3D Drucker mit Bayernwerk Akademie GmbH Logo" alt="3D Drucker mit Bayernwerk Akademie GmbH Logo" class="hydrated"></eon-ui-image>
                </eon-ui-grid-control-column>
            </eon-ui-grid-control>

        </eon-ui-section>


        <div class="loading-section" :class="loading ? 'show' : ''">
            <eon-ui-preloader-circle></eon-ui-preloader-circle>
        </div>
    </div>
</template>

<script>

import Resource from "../composables/bayernwerk";
import { formatDateDMY } from "../utils/filter";

const bayernwerkResource = new Resource();

export default {
    name: "Startseite",
    data() {
        return {
            dataTable: [],
            query:
            {
                page: 1,
                limit: 4,
                extern: this.$store.state.OrPe_extern == '1' ? '1' : '0',
            },
            loading: false,
            text_error: "",
            message_visible: false,
            
            
            read_only: this.$store.state.email ? true : false,
            contact_form: {
                email: this.$store.state.email ? this.$store.state.email : "",
                name: this.$store.state.name_gesamt ? this.$store.state.name_gesamt : "",
                text: "",
                betreff: "",
                online_id: this.$store.state.ds_id ? this.$store.state.ds_id : 0
            },
            message_visible_success: false,
            message_visible: false,
            text_error: "",
            text_success: "",
            msg: "",
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        };
    },
    computed: {
        user_orpe_extern() {
            return this.$store.state.OrPe_extern;
        }
    },
    watch: {
        user_orpe_extern() {
        }
    },
    mounted(){
    },
    created() {
    },
    setup() {
    },
    methods: {
        changeValue(value, field){
            _.set(this.contact_form, field, value);
        },
        submitFormSaveContact(event) {
            if (!this.reg.test(this.contact_form.email)) {
                alert('Bitte geben Sie eine gültige E-Mail-Adresse ein!');
                event.preventDefault();
                return "";
            }
            this.loading = true;
            event.preventDefault();
            this.error_msg = '';
            console.log('submitFormSaveContact');
            console.log(this.contact_form);
            bayernwerkResource.saveContactForm(this.contact_form).then((res) => {
            if (res != null) {
                if(res.messages && res.messages[0]['code']== "0" ) {
                    this.text_success = "Ihre Anfrage wurde erfolgreich verarbeitet. Das Veranstaltungsmanagement wird sich mit Ihnen in Verbindung setzen.";
                    this.message_visible_success = true;
                    setTimeout( () => this.$router.push({ name: "startseite" }), 4000);
                }else{
                    this.text_error = 'Ihre Anfrage konnte nicht gespeichert werden. Bitte versuchen Sie es in ein paar Minuten erneut.';
                    this.message_visible = true;
                    setTimeout(() => this.message_visible = false, 4000);
                }
            }
            this.loading = false;
          }).catch((error) => {
            console.log(error);
            this.text_error = 'Save Contact Form Error!';
            this.message_visible = true;
            setTimeout(() => this.message_visible = false, 4000);
            this.loading = false;
          });
        },
        changeBetreff(e) {
            this.contact_form.betreff = e.target.value;
        },
        validateEmail() {
            if (!this.reg.test(this.contact_form.email)) {
                alert('Bitte geben Sie eine gültige E-Mail-Adresse ein!');
            }
        },
        goToKatalog() {
            this.$router.push({ name: 'katalog', params: { detail: 'gesamt' } });
        },
        scrollTo(target) {
            console.log(this.$refs[target]);
            window.scrollTo(0,this.$refs[target].offsetTop-138);
        },
        /** Sets the contact subject and roots to '/kontakt'.
         * 
         * @param {string} subject 
         */
        goToContact(subject) {
            this.$store.state.contactSubject = subject;
            this.$router.push({ name: "kontakt" });
        }
    },
};
</script>
