<template>

    <eon-ui-section class="hydrated">

        <!-- <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Übersicht</a>
                <a href="/abkuerzungsverzeichnis">Abkürzungsverzeichnis</a>
            </eon-ui-breadcrumb>
        </div> -->

        <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">

                <eon-ui-headline text="Abkürzungsverzeichnis" class="hydrated text-blue" size="h4"></eon-ui-headline>
                <div class="mb-5"><eon-ui-headline text="Allgemeine Hinweise" class="hydrated text-blue" size="h7"></eon-ui-headline></div>

                <eon-ui-bullet-list icon-name="radio_checkbox_tick">
                    <eon-ui-bullet-list-item role="listitem" text="Es wird zum Zwecke der einfacheren Lesbarkeit ausschließlich die männliche
                        Ausdrucksform im Schulungsprogramm verwendet." highlight="false"></eon-ui-bullet-list-item>
                    <eon-ui-bullet-list-item role="listitem" text="Im Schulungsprogramm werden Querverweise zu geltenden Richtlinien der Bayernwerk
                        Netz GmbH aufgeführt. Sollten Sie keinen Zugriff auf diese Richtlinien haben, sprechen Sie uns bitte an." highlight="false"></eon-ui-bullet-list-item>
                    <eon-ui-bullet-list-item role="listitem" text="Die im Schulungsprogramm aufgeführten medizinischen Untersuchungen gemäß den
                        Berufsgenossenschaftlichen Grundsätzen E25 und E41 entsprechen den ehemaligen Bezeichnungen G25 und G41." highlight="false"></eon-ui-bullet-list-item>
                </eon-ui-bullet-list>

                <table>
                    <colgroup>
                        <col />
                        <col />
                    </colgroup>
                    <thead align="left">
                        <tr>
                            <th>Abkürzung</th>
                            <th>Erklärung</th>
                        </tr>
                    </thead>
                    <tbody class="sb-argstableBlock-body">
                        <tr>
                            <td>AuS</td>
                            <td>Arbeiten unter Spannung</td>
                        </tr>
                        <tr>
                            <td>B</td>
                            <td>Basisschulung</td>
                        </tr>
                        <tr>
                            <td>BGR </td>
                            <td>Berufsgenossenschaftliche Regelung</td>
                        </tr>
                        <tr>
                            <td>DGUV </td>
                            <td>Deutsche Gesetzliche Unfallversicherung</td>
                        </tr>
                        <tr>
                            <td>DVGW  </td>
                            <td>Deutscher Verein des Gas- und Wasserfaches e. V</td>
                        </tr>
                        <tr>
                            <td>EEG </td>
                            <td>Erneuerbare-Energien-Gesetz</td>
                        </tr>
                        <tr>
                            <td>EFK</td>
                            <td>Elektrofachkraft</td>
                        </tr>
                        <tr>
                            <td>ET  </td>
                            <td>Eintarif</td>
                        </tr>
                        <tr>
                            <td>EUP </td>
                            <td>Elektrotechnisch unterwiesene Person</td>
                        </tr>
                        <tr>
                            <td>EV</td>
                            <td>Endverschluss</td>
                        </tr>
                        <tr>
                            <td>FRE</td>
                            <td>Funkrundsteuerempfänger</td>
                        </tr>
                        <tr>
                            <td>G  </td>
                            <td>Grundschulung</td>
                        </tr>
                        <tr>
                            <td>GmbH </td>
                            <td>Gesellschaft mit beschränkter Haftung</td>
                        </tr>
                        <tr>
                            <td>HAK </td>
                            <td>Hausanschlusskasten</td>
                        </tr>
                        <tr>
                            <td>KÜK </td>
                            <td>Kabelübergangskasten</td>
                        </tr>
                        <tr>
                            <td>kV  </td>
                            <td>Kilovolt</td>
                        </tr>
                        <tr>
                            <td>KVS  </td>
                            <td>Kabelverteilerschrank</td>
                        </tr>
                        <tr>
                            <td>LV </td>
                            <td>Leistungsverzeichnis</td>
                        </tr>
                        <tr>
                            <td>NS </td>
                            <td>Niederspannung</td>
                        </tr>
                        <tr>
                            <td>NSL</td>
                            <td>Niederspannungsleitstelle</td>
                        </tr>
                        <tr>
                            <td>MS</td>
                            <td>Mittelspannung</td>
                        </tr>
                        <tr>
                            <td>MSR</td>
                            <td>Mess-, Steuer- und Regelungstechnik</td>
                        </tr>
                        <tr>
                            <td>PSA </td>
                            <td>Persönliche Schutzausrüstung</td>
                        </tr>
                        <tr>
                            <td>PSAgA</td>
                            <td> Persönliche Schutzausrüstung gegen Absturz</td>
                        </tr>
                        <tr>
                            <td>PV</td>
                            <td>Photovoltaik</td>
                        </tr>
                        <tr>
                            <td>RAN</td>
                            <td> Richtlinie für Arbeiten und Netzführung</td>
                        </tr>
                        <tr>
                            <td>SLP</td>
                            <td> Standardlastprofil</td>
                        </tr>
                        <tr>
                            <td>TAB</td>
                            <td> Technische Anschlussbedingungen</td>
                        </tr>
                        <tr>
                            <td>TRGI</td>
                            <td>Technische Regel für Gasinstallationen</td>
                        </tr>
                        <tr>
                            <td>TSG</td>
                            <td>Tarifsteuergerät</td>
                        </tr>
                        <tr>
                            <td>TTC</td>
                            <td>Technisches Trainingscenter</td>
                        </tr>
                        <tr>
                            <td>U</td>
                            <td> Unterweisung</td>
                        </tr>
                        <tr>
                            <td>VE</td>
                            <td>Verfügungserlaubnis</td>
                        </tr>
                        <tr>
                            <td>VIU</td>
                            <td> Vertragsinstallationsunternehmen</td>
                        </tr>
                        <tr>
                            <td>W</td>
                            <td>Wiederholungsschulung</td>
                        </tr>
                        <tr>
                            <td>ZMI</td>
                            <td>Zählermontage durch Installateure</td>
                        </tr>
                    </tbody>
                </table>

            </eon-ui-grid-control-column>
        </eon-ui-grid-control>
    </eon-ui-section>

</template>
