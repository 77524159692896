<template>
  <div class="flex-container">
    <div class="text-center">
      <eon-ui-image
        slot="logo"
        src="https://www.bayernwerk.de/content/dam/revu-global/bayernwerk/logo/bayernwerk-header-logo.svg"
        alt="E.ON Logo"
        link-href="/startseite"
      ></eon-ui-image>
      <h3 class="fadeIn" style="font-weight: bold;padding-top: 1rem;">PAGE NOT FOUND</h3>
      <h3>Oops, it looks like the page you're looking for doesn't exist.</h3>
      <router-link :to="{ name: 'startseite' }"
        ><button type="button" name="button">
          Return To Home
        </button></router-link
      >
    </div>
  </div>
</template>

<style scoped>
* {
  font-family: Google sans, Arial;
}

html,
body {
  margin: 0;
  padding: 0;
}

.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  color: white;
  animation: colorSlide 15s cubic-bezier(0.075, 0.82, 0.165, 1) infinite;
}
.flex-container .text-center {
  text-align: center;
}
.flex-container .text-center h1,
.flex-container .text-center h3 {
  margin: 10px;
  cursor: default;
}
.flex-container .text-center h1 .fade-in,
.flex-container .text-center h3 .fade-in {
  animation: fadeIn 2s ease infinite;
}
.flex-container .text-center h1 {
  font-size: 8em;
  transition: font-size 200ms ease-in-out;
  border-bottom: 1px dashed white;
}
.flex-container .text-center h1 span#digit1 {
  animation-delay: 200ms;
}
.flex-container .text-center h1 span#digit2 {
  animation-delay: 300ms;
}
.flex-container .text-center h1 span#digit3 {
  animation-delay: 400ms;
}
.flex-container .text-center button {
  border: 1px solid white;
  background: transparent;
  outline: none;
  padding: 10px 20px;
  font-size: 1.1rem;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  transition: background-color 200ms ease-in;
  margin: 20px 0;
}
.flex-container .text-center button:hover {
  background-color: white;
  color: #555;
  cursor: pointer;
}

@keyframes colorSlide {
  0% {
    background-color: #8de4fc;
  }
  /* 25% {
    background-color: rgb(111, 138, 218);
  }
  50% {
    background-color: rgb(60, 135, 93);
  } */
  50% {
    background-color: rgb(248, 123, 100);
  }
  100% {
    background-color: #8de4fc;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
</style>
