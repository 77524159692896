<template>

    <eon-ui-error-message style="" icon-name="radio_checkbox_tick" :is-visible="message_visible" inner-background="eon-bag-blue" class="hydrated" fixed-position="true">
    <eon-ui-headline slot="headline" size="h5" :text="text_error" class="hydrated"></eon-ui-headline></eon-ui-error-message>

    <eon-ui-section class="hydrated">

        <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Übersicht</a>
                <a href="/katalog/gesamt">Unsere Angebote</a>
                <a :href="'/seminare/' + ID_Kurs">Seminar {{ this.nummer }}</a>
            </eon-ui-breadcrumb>
        </div>

        <eon-ui-grid-control grid="grid__d9-3__t8-4" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">

                <eon-ui-headline size="h4" :text="this.nummer + ' ' + this.bezeichnung" class="hydrated text-blue" v-if="dataSeminar.bezeichnung != ''"></eon-ui-headline>
                <eon-ui-headline size="h4" text="" class="hydrated text-blue" v-else></eon-ui-headline>

                <div class="mt-4">
                    <eon-ui-text text-style="rtf">Allgemeines</eon-ui-text>
                </div>

                <eon-ui-grid-control grid="grid__d4-4-4__t12" class="hydrated">

                    <eon-ui-grid-control-column slot="column-1" class="hydrated">
                        <eon-ui-data-table zebra="true">
                            <eon-ui-table-head style="display:none">
                                <eon-ui-table-head-row>
                                </eon-ui-table-head-row>
                            </eon-ui-table-head>
                            <eon-ui-table-body>
                              <eon-ui-table-row>
                                <eon-ui-table-cell>Beginn </eon-ui-table-cell>
                                <eon-ui-table-cell>{{ dataSeminar.Datum }}</eon-ui-table-cell>
                              </eon-ui-table-row>
                              <eon-ui-table-row>
                                <eon-ui-table-cell>Ende </eon-ui-table-cell>
                                <eon-ui-table-cell>{{ dataSeminar.Endedatum }}</eon-ui-table-cell>
                              </eon-ui-table-row>
                              <eon-ui-table-row v-if="dataSeminar.anzahl_tage != ''">
                                <eon-ui-table-cell width="20%">Schulungstage </eon-ui-table-cell>
                                <eon-ui-table-cell>{{ dataSeminar.anzahl_tage }}</eon-ui-table-cell>
                              </eon-ui-table-row>
                              <eon-ui-table-row v-if="dataSeminar.Zeit != ''">
                                <eon-ui-table-cell>von / bis </eon-ui-table-cell>
                                <eon-ui-table-cell>{{ dataSeminar.Zeit }} - {{ dataSeminar.Zeit_ende }} Uhr</eon-ui-table-cell>
                              </eon-ui-table-row>
                            </eon-ui-table-body>
                        </eon-ui-data-table>
                    </eon-ui-grid-control-column>

                    <eon-ui-grid-control-column slot="column-2" class="hydrated">
                        <eon-ui-data-table zebra="true">
                            <eon-ui-table-head style="display:none">
                                <eon-ui-table-head-row>
                                </eon-ui-table-head-row>
                            </eon-ui-table-head>
                            <eon-ui-table-body>
                                <eon-ui-table-row>
                                    <eon-ui-table-cell >Schulungsort</eon-ui-table-cell>
                                    <eon-ui-table-cell>{{ dataSeminar.Ort }}</eon-ui-table-cell>
                                </eon-ui-table-row>
                                <eon-ui-table-row v-if="dataSeminar.Raum != ''">
                                    <eon-ui-table-cell>Raum</eon-ui-table-cell>
                                    <eon-ui-table-cell>{{ dataSeminar.Raum }}</eon-ui-table-cell>
                                </eon-ui-table-row>
                                <eon-ui-table-row v-if="dataSeminar.schulungstreffpunkt != ''">
                                    <eon-ui-table-cell>Schulungstreffpunkt</eon-ui-table-cell>
                                    <eon-ui-table-cell>{{ dataSeminar.schulungstreffpunkt }}</eon-ui-table-cell>
                                </eon-ui-table-row>
                            </eon-ui-table-body>
                        </eon-ui-data-table>
                    </eon-ui-grid-control-column>
                </eon-ui-grid-control>

                <span v-if="dataSeminar.inhalt" grid="grid__d10__t12" class="hydrated">
                    <div class="mb-2">
                        <eon-ui-text text-style="rtf">Inhalt</eon-ui-text>
                    </div>
                    <eon-ui-text text-style="copy-small" v-html="seminar_inhalt"></eon-ui-text>
                </span>

                <span v-if="dataSeminar.zielgruppe">
                    <div class="mb-2">
                        <eon-ui-text text-style="rtf">Zielgruppe</eon-ui-text>
                    </div>
                    <eon-ui-text text-style="copy-small">{{ dataSeminar.zielgruppe }}</eon-ui-text>
                </span>

                <span v-if="dataSeminar.zulassungsvoraussetzungen">
                    <div class="mb-2">
                        <eon-ui-text text-style="rtf">Voraussetzungen</eon-ui-text>
                    </div>
                    <eon-ui-text text-style="copy-small">{{ dataSeminar.zulassungsvoraussetzungen }}</eon-ui-text>
                </span>

                <span v-if="dataSeminar.hinweise">
                    <div class="mb-2">
                        <eon-ui-text text-style="rtf">Hinweise</eon-ui-text>
                    </div>
                    <eon-ui-text text-style="copy-small">{{ dataSeminar.hinweise }}</eon-ui-text>
                </span>

                <span v-if="dataSeminar.befaehigungen_liste">
                    <div class="mb-2">
                        <eon-ui-text text-style="rtf">Befähigungen</eon-ui-text>
                    </div>
                    <eon-ui-text text-style="copy-small" v-html="dataSeminar.befaehigungen_liste"></eon-ui-text>
                </span>

                <span v-if="dataSeminar.gebuehr">
                    <div class="mb-2">
                        <eon-ui-text text-style="rtf">Preis (zzgl. ges. Mwst.)</eon-ui-text>
                    </div>
                    <eon-ui-text text-style="copy-small">{{ dataSeminar.preis_art }} {{ dataSeminar.gebuehr }} €</eon-ui-text>
                </span>

                <span>
                    <div class="mb-2">
                        <eon-ui-text text-style="rtf">Mindestteilnehmeranzahl / freie Plätze</eon-ui-text>
                    </div>
                    <eon-ui-text text-style="copy-small">
                        {{ dataSeminar.TeilnehmerMin }} / {{ dataSeminar.TeilnehmerMax - dataSeminar.F_AnzahlTeilnehmer }}
                    </eon-ui-text>
                </span>

                <eon-ui-button v-if="this.$store.state.Tetra_id !=''" class="btn-custom-blue" text="Anmelden" input-type="button" v-on:click="registrationSeminar"></eon-ui-button>

                <div v-if="this.$store.state.token != null && this.$store.state.Tetra_id == ''">
                    <eon-ui-text-teaser pictogram="" size="h3" align="left" show-button="false">
                        <eon-ui-text class="hydrated" size="small" text-style="copy" color="eon-bag-red"><b>Hinweis:</b> Sie besitzen zur Zeit noch keinen Account
                            in unserer Schulungssoftware. Um Schulungen zu buchen oder Ihre Daten einsehen zu können, muss zunächst ein Account angelegt werden.
                            Bitte nutzen Sie dazu unser
                            <router-link :to="{ name: 'kontakt' }">
                                <eon-ui-link text="Kontaktformular" class="hydrated" size="small" display-inline="true" scheme="turquoise"></eon-ui-link>
                            </router-link> mit dem Betreff "Neuer Account".
                        </eon-ui-text>
                    </eon-ui-text-teaser>
                </div>

            </eon-ui-grid-control-column>
            <eon-ui-grid-control-column slot="column-2" class="hydrated">
                <div>
                    <eon-ui-image lazy :src="'../' + dataSeminar.pic_src" :src-desktop="'../' + dataSeminar.pic_src" :src-mobile="'../' + dataSeminar.pic_400"
                        alt="Technisches Trainingscenter der Bayernwerk Netz GmbH" title="Technisches Trainingscenter der Bayernwerk Netz GmbH" height="auto" v-if="dataSeminar.pic_src != ''">
                    </eon-ui-image>
                    <eon-ui-image lazy src="../images/AcademyResources/SeminarDetails/Sidevisual_3DDrucker_Quer.jpg" src-desktop="images/AcademyResources/SeminarDetails/Sidevisual_3DDrucker_Quer.jpg" src-mobile="images/AcademyResources/SeminarDetails/Sidevisual_3DDrucker_Quer.jpg"
                        alt="Technisches Trainingscenter der Bayernwerk Netz GmbH" title="Technisches Trainingscenter der Bayernwerk Netz GmbH" height="auto" v-else>
                    </eon-ui-image>
                </div>

                <section>

                    <div class="mb-2" v-if="dataSeminar.ansprechpartner != ''">
                        <eon-ui-text font-weight="bold" class="hydrated text-blue" text-style="subheadline">Ansprechpartner</eon-ui-text>
                        <div>
                            <div v-for="item in dataSeminar.ansprechpartner">
                                <eon-ui-text class="text-black" size="small" text-style="copy">{{ item['Ansprechpartner::Name'] }}</eon-ui-text><br />
                                <eon-ui-link :href="'tel:'+item['Ansprechpartner::Telefon']" :text="item['Ansprechpartner::Telefon']" size="small" scheme="turquoise" v-if="item['Ansprechpartner::Telefon']"></eon-ui-link>
                            </div>
                        </div>
                        <eon-ui-divider class="mt-6 mb-6"></eon-ui-divider>
                    </div>

                    <div class="mb-2" v-if="dataSeminar.strasse != ''">
                        <eon-ui-text font-weight="bold" class="hydrated text-blue" text-style="subheadline">Adresse</eon-ui-text>
                        <div>
                            <eon-ui-text class="text-black" size="small" text-style="copy">{{ dataSeminar.strasse }}</eon-ui-text><br />
                            <eon-ui-text class="text-black" size="small" text-style="copy">{{ dataSeminar.plz }} {{ dataSeminar.ort }}</eon-ui-text>
                        </div>
                        <div class="mt-4">
                            <eon-ui-link :href="'https://maps.google.com?q=' + dataSeminar.strasse + '+' + dataSeminar.plz + '+' + dataSeminar.ort" text="Google Maps" icon="location" scheme="turquoise" size="small" target="_blank"></eon-ui-link>
                        </div>
                        <eon-ui-divider class="mt-6 mb-6"></eon-ui-divider>
                    </div>

                    <span class="mb-2">
                        <div class="mb-4">
                            <!-- <router-link :to="{ name: 'gesamtkatalog' }"> -->
                                <eon-ui-link href="/pdfs/Akademie_Schulungsprogramm_2024_web.pdf" target="_blank"
                                text="Gesamtkatalog" scheme="turquoise" size="small" hide-icon="true"></eon-ui-link>
                            <!-- </router-link> -->
                        </div>
                        <div>
                            <router-link :to="{ name: 'teilnahmebedingungen' }">
                                <eon-ui-link text="Teilnahmebedingungen" scheme="turquoise" size="small" hide-icon="true"></eon-ui-link>
                            </router-link>
                        </div>
                        <div>
                            <router-link :to="{ name: 'abkuerzungsverzeichnis' }">
                                <eon-ui-link text="Abkürzungsverzeichnis" scheme="turquoise" size="small" hide-icon="true"></eon-ui-link>
                            </router-link>
                        </div>
                    </span>

                    <div v-if="this.dozent === 1">
                        <eon-ui-divider class="mt-6 mb-6"></eon-ui-divider>
                        <eon-ui-text font-weight="bold" class="hydrated text-blue" text-style="subheadline">Dokumente für Dozenten</eon-ui-text>
                        <div>
                            <eon-ui-link text="Anmeldeliste" icon="download" scheme="turquoise" size="small" v-on:click="this.document = 'anmeldeliste', getListTeilnehmer(this.ID_Kurs)"></eon-ui-link>
                        </div>
                        <div>
                            <eon-ui-link text="Teilnehmerliste" icon="download" scheme="turquoise" size="small" v-on:click="this.document = 'teilnehmerliste', getListTeilnehmer(this.ID_Kurs)"></eon-ui-link>
                        </div>
                    </div>

                </section>

            </eon-ui-grid-control-column>
        </eon-ui-grid-control>
    </eon-ui-section>

    <div class="loading-section" :class="loading ? 'show' : ''">
        <eon-ui-preloader-circle></eon-ui-preloader-circle>
    </div>
</template>

<script>
import Resource from "../../composables/bayernwerk";
import { formatDateDMY, formatPrice } from "../../utils/filter";
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import _ from "lodash";
const bayernwerkResource = new Resource();

export default {
  name: "SeminareDetail",
  data() {
    return {
      dataSeminar: {},
      dataTeilnehmer: [],
      ID_Kurs: this.$route.params.id,
      loading: false,
      message_visible: false,
      text_error: "",
      seminar_inhalt: '',
      bezeichnung: '',
      nummer: '',
      document: '',
      dozent: 0,
    };
  },
  computed: {
    user_token() {
      return this.$store.state.token;
    },
  },
  watch: {
    user_token(newUserData, oldUserData) {
      this.ID_Kurs = this.$route.params.id;
      this.fetchDataSeminarDetail(this.ID_Kurs);
    },
  },
  mounted() {
    this.ID_Kurs = this.$route.params.id;
    this.fetchDataSeminarDetail(this.ID_Kurs);
  },
  created() {},
  setup() {},
  methods: {
    fetchDataSeminarDetail(ID_Kurs) {
      this.loading = true;
      bayernwerkResource.getSeminareDetail({ ID_Kurs: ID_Kurs }).then((res) => {
          if (res != null) {
            if (res.response && res.response.data && res.messages[0].code == "0") {
              this.dataSeminar = {
                ...res.response.data[0]["fieldData"],
                bezeichnung: res.response.data[0]["fieldData"]["Bezeichnung"],
                extra_nr: res.response.data[0]["fieldData"]["extra_nr"],
                Datum: formatDateDMY(res.response.data[0]["fieldData"]["Datum"]),
                Endedatum: formatDateDMY(res.response.data[0]["fieldData"]["Endedatum"]),
                befaehigungen_liste: res.response.data[0]["fieldData"]["seminartyp::befaehigungen_liste_web"],
                hinweise: res.response.data[0]["fieldData"]["seminartyp::hinweise"],
                zielgruppe: res.response.data[0]["fieldData"]["seminartyp::zielgruppe"],
                zulassungsvoraussetzungen: res.response.data[0]["fieldData"]["seminartyp::zulassungsvoraussetzungen"],
                ansprechpartner: res.response.data[0]["portalData"]["Ansprechpartner"],
                gebuehr: formatPrice(res.response.data[0]["fieldData"]["Gebuehr"]),
                pic_300: res.response.data[0]["fieldData"]["pictures_details::url_300"],
                pic_400: res.response.data[0]["fieldData"]["pictures_details::url_400"],
                pic_900: res.response.data[0]["fieldData"]["pictures_details::url_900"],
                pic_1200: res.response.data[0]["fieldData"]["pictures_details::url_1200"],
                pic_src: res.response.data[0]["fieldData"]["pictures_details::url_source"],
                doz_id: res.response.data[0]["portalData"]["dozenten_seminare"],
                ort: res.response.data[0]["fieldData"]["seminarort"],
                strasse: res.response.data[0]["fieldData"]["Strasse"],
                plz: res.response.data[0]["fieldData"]["plz"]
              };
              this.fixHeader();
              this.formatContent();
              this.checkDozent();
            } else {
              this.text_error = res.error_msg;
              this.message_visible = true;
              setTimeout(() => this.message_visible = false, 4000);
              console.log(res.error_msg);
            }
          } else {
            console.log("Token expired!");
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          const code = _.get(error, "response.data.messages[0].code", 0);
          if (code == 952 || code == 10) {
            this.$store.dispatch("user/logout");
            setTimeout(() => this.$router.go(),this.$router.push({ name: "startseite" }), 4000);
          }
        });
    },
    registrationSeminar() {
        this.message_visible = false;
        if(!this.$store.state.token){
            this.text_error = 'Sie sind nicht angemeldet. Bitte loggen Sie sich über die Schaltfläche Login (oben rechts) ein.';
            this.message_visible = true;
            setTimeout(() => this.message_visible = false, 4000);
        }else {
            this.$router.push({ name: "registrationSeminar", params: { id: this.ID_Kurs } });
        }
    },
    getListTeilnehmer(ID_Kurs) {
        if(this.dataTeilnehmer == '') {
            this.loading = true;
            bayernwerkResource.getListTeilnehmer({ID_Kurs: ID_Kurs}).then((res) => {
            if (res != null) {
                if (res.response && res.response.data && res.messages[0].code == "0") {
                    const data_array = res.response.data;
                    this.dataTeilnehmer = data_array.map((value, index) => {
                    return {
                        index: index + 1 < 10 ? '0' + (index + 1) : index + 1,
                        bezeichnung: value["fieldData"]["BW_Seminare::Bezeichnung"],
                        beginn: formatDateDMY(value["fieldData"]["BW_Seminare::Datum"]),
                        ende: formatDateDMY(value["fieldData"]["BW_Seminare::Endedatum"]),
                        ort: value["fieldData"]["BW_Seminare::Ort"],
                        firma: value["fieldData"]["BW_Teilnehmer::firma_bereich"],
                        ma_name: value.fieldData.f_name_vorname,
                        variante: value["fieldData"]["gewuenschte_befaehigung_schulung"],
                        tage: value.fieldData.gewaehlte_zeiten_anzahl,
                        drittgeschaeft: value.fieldData.drittgeschaeft_auswahl,
                        zangen_nr: value["fieldData"]["zangen_teilnehmer::zangen_nr"],
                        bemerkung: value.fieldData.bemerkungen_teilnahme,
                        // inhalte: value.fieldData.gewuenschte_befaehigung_schulung
                    };
                });
                this.exportPdf_Anmeldeliste();
                } else {
                    this.text_error = 'Keine Anmeldungen gefunden.';
                    this.message_visible = true;
                    setTimeout(() => this.message_visible = false, 4000);
                    console.log(res.error_msg);
                }
            } else {
                console.log("Token expired!");
            }
            this.loading = false;
            })
            .catch((error) => {
            this.loading = false;
            const code = _.get(error, "response.data.messages[0].code", 0);
            if (code == 952 || code == 10) {
                this.$store.dispatch("user/logout");
                setTimeout(() => this.$router.go(),this.$router.push({ name: "startseite" }), 4000);
            }
            });
        }
        else {
            this.exportPdf_Anmeldeliste();
        }
    },
    exportPdf_Anmeldeliste() {
      if (this.dataTeilnehmer.length) {
        // initialize
        var doc = new jsPDF({
          orientation: 'landscape',
          unit: 'px',
          format: 'a4',
          putOnlyUsedFonts:true
        });
        // get date
        let objectDate = new Date();
        let day = objectDate.getDate();
        let month = objectDate.getMonth() + 1;
        let year = objectDate.getFullYear();
        if (day < 10) {
          day = '0' + day;
        }
        if (month < 10) {
          month = `0${month}`;
        }
        // set variables
        let totalPages = '{total_pages_count_string}';
        let date_footer = `${day}.${month}.${year}`;
        let date_filename = `${day}${month}${year}`;
        let bezeichnung = this.dataTeilnehmer[0].bezeichnung;
        let ort = this.dataTeilnehmer[0].ort;
        let beginn = this.dataTeilnehmer[0].beginn;
        let ende = this.dataTeilnehmer[0].ende;
        let logo = '../images/Logo/logo_pdf.png';

        if(this.document == 'anmeldeliste') {
            autoTable(doc, {
            headStyles: { fillColor : [0, 143, 189], Tage: { halign: 'right' } },
            styles: { fontSize: 12 },
            columnStyles: { tage: { cellWidth: 32, halign: 'right' } },
            head: [['#', 'Firma', 'Mitarbeiter', 'Unterschrift', 'gebuchte Schulungsinhalte', 'Tage']],
            columns: [
              {dataKey: 'index', header: '#'},
              {dataKey: 'firma', header: 'Firma'},
              {dataKey: 'ma_name', header: 'Mitarbeiter'},
              {dataKey: '', header: 'Unterschrift'},
              {dataKey: 'variante', header: 'gebuchte Schulungsinhalte'},
              {dataKey: 'tage', header: 'Tage'},
            ],
            body: this.dataTeilnehmer,
            didDrawPage: function () {
                // Footer
                var str = 'Seite ' + doc.internal.getNumberOfPages()
                if (typeof doc.putTotalPages === 'function') {
                  str = str + ' von ' + totalPages
                }
                // Header
                doc.setFont(undefined, 'bold');
                doc.setFontSize(18);
                doc.text('Anwesenheitsliste zur Schulung', 30, 30);
                doc.setFontSize(14);
                doc.text(bezeichnung, 30, 46);
                doc.setFont(undefined, 'normal');
                doc.text('Schulungsort:   ' + ort, 30, 75);
                doc.text('Schulungsdatum:   ' + beginn + '   bis   ' + ende, 375, 75);
                doc.setDrawColor(255, 0, 0);
                doc.line(30, 418, 600, 418);
                doc.addImage(logo, 'PNG', 494, 20);
                doc.setFontSize(10)
                doc.text(str, 554, 430)
                doc.text(date_footer, 30, 430)
            },
            showHead: 'everyPage',
            margin: {top: 85},
            });
            if (typeof doc.putTotalPages === 'function') {
                doc.putTotalPages(totalPages)
            }
            doc.save('Anwesenheitsliste_' + date_filename + '.pdf')
        }
        if(this.document == 'teilnehmerliste') {
            autoTable(doc, {
            headStyles: { Tage: { halign: 'right' } },
            styles: { fontSize: 12 },
            columnStyles: { tage: { cellWidth: 32, halign: 'right' } },
            head: [['#', 'Firma', 'Mitarbeiter', 'gebuchte Schulungsinhalte', '3. G.', 'Bemerkung', 'Reg.-Nr.', 'Tage']],
            columns: [
              {dataKey: 'index', header: '#'},
              {dataKey: 'firma', header: 'Firma'},
              {dataKey: 'ma_name', header: 'Mitarbeiter'},
              {dataKey: 'variante', header: 'gebuchte Schulungsinhalte'},
              {dataKey: 'drittgeschaeft', header: '3. G.'},
              {dataKey: 'bemerkung', header: 'Bemerkung'},
              {dataKey: 'zangen_nr', header: 'Reg.-Nr.'},
              {dataKey: 'tage', header: 'Tage'}
            ],
            body: this.dataTeilnehmer,
            didDrawPage: function () {
                // Footer
                var str = 'Seite ' + doc.internal.getNumberOfPages()
                if (typeof doc.putTotalPages === 'function') {
                  str = str + ' von ' + totalPages
                }
                // Header
                doc.setFont(undefined, 'bold');
                doc.setFontSize(18);
                doc.text('Teilnehmerliste zur Schulung', 30, 30);
                doc.setFontSize(14);
                doc.text(bezeichnung, 30, 46);
                doc.setFont(undefined, 'normal');
                doc.text('Schulungsort:   ' + ort, 30, 75);
                doc.text('Schulungsdatum:   ' + beginn + '   bis   ' + ende, 375, 75);
                doc.setDrawColor(255, 0, 0);
                doc.line(30, 418, 600, 418);
                doc.addImage(logo, 'PNG', 494, 20);
                doc.setFontSize(10)
                doc.text(str, 554, 430)
                doc.text(date_footer, 30, 430)
            },
            showHead: 'everyPage',
            margin: {top: 85},
            });
            if (typeof doc.putTotalPages === 'function') {
                doc.putTotalPages(totalPages)
            }
            doc.save('teilnehmerliste_' + date_filename + '.pdf')
        }
      }
      else {
          this.text_error = 'No data to export!';
          this.message_visible = true;
          setTimeout(() => this.message_visible = false, 4000);
      }
    },
    formatContent()
    {
      let temp = this.dataSeminar.inhalt.replaceAll(/(\r\t|\t\r|\r\r|\r)/g, '<br />');
      this.seminar_inhalt = temp;
    },
    checkDozent()
    {
        if(this.dataSeminar.doz_id != '')
        {
            for(let i=0; i < this.dataSeminar.doz_id.length; i++) {
                if(this.dataSeminar.doz_id[i]['dozenten_seminare::doz_id']  === this.$store.state.doz_id) {
                    this.dozent = 1;
                }
            }
        }
    },
    fixHeader()
    {
        if (this.dataSeminar.nummer != this.dataSeminar.bezeichnung) {
            this.bezeichnung = this.dataSeminar.bezeichnung;
            this.nummer = this.dataSeminar.extra_nr;
            }
            else {
              this.bezeichnung = this.dataSeminar.bezeichnung;
            }
    },
    logoutTokenExpired() {
            this.$store.dispatch("user/logout");
            setTimeout(() => this.$router.go(), this.$router.push({ name: "startseite" }), 4000);
    }
  },
};
</script>
