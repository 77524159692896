<template>

    <eon-ui-error-message style="" icon-name="radio_checkbox_tick" :is-visible="message_visible" inner-background="eon-bag-green" class="hydrated" fixed-position="true">
        <eon-ui-headline slot="headline" size="h5" :text="text_error" class="hydrated"></eon-ui-headline>
    </eon-ui-error-message>

    <!-- <eon-ui-section height="100px">
        <eon-ui-image lazy src="https://www.bayernwerk-shop.de/media/image/55/38/53/Header-Schulungen_1920x1080.jpg"
            alt="Technisches Trainingscenter der Bayernwerk Netz GmbH"
            title="Technisches Trainingscenter der Bayernwerk Netz GmbH" use-as-background="true">
        </eon-ui-image>
    </eon-ui-section> -->

    <eon-ui-section class="hydrated">

        <!-- <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Übersicht</a>
                <a href="/katalog/gesamt">Unsere Angebote</a>
            </eon-ui-breadcrumb>
        </div> -->

        <eon-ui-grid-control grid="grid__d8-4__t8-4">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">

                <eon-ui-headline size="h3" text="Schulungskatalog" class="hydrated text-blue"></eon-ui-headline>

                    <!-- <eon-ui-text text-style="rtf" v-if="this.$route.params.detail == 'gesamt'">Gesamtansicht</eon-ui-text>
                    <eon-ui-text text-style="rtf" v-if="this.$route.params.detail == 'strom'">zum Thema Strom</eon-ui-text>
                    <eon-ui-text text-style="rtf" v-if="this.$route.params.detail == 'gas'">zum Thema Gas</eon-ui-text>
                    <eon-ui-text text-style="rtf" v-if="this.$route.params.detail == 'qualifizierungskonzept'">zum Thema Qualifizierungskonzept</eon-ui-text> -->
                    
            </eon-ui-grid-control-column>
            <eon-ui-grid-control-column slot="column-2" class="hydrated">

                <!-- <eon-ui-dropdown name="Ansicht" class="hydrated" size="small" @valuechanged="dropdownLinkChanged" :selected="this.$route.params.detail">
                    <eon-ui-dropdown-option class="hydrated" value="gesamt" label="Gesamt"></eon-ui-dropdown-option>
                    <eon-ui-dropdown-option class="hydrated" value="strom" label="Strom"></eon-ui-dropdown-option>
                    <eon-ui-dropdown-option class="hydrated" value="gas" label="Gas"></eon-ui-dropdown-option>
                    <eon-ui-dropdown-option class="hydrated" value="qualifizierungskonzept" label="Qualifizierungskonzept"></eon-ui-dropdown-option>
                </eon-ui-dropdown> -->

            </eon-ui-grid-control-column>
        </eon-ui-grid-control>

        <div class="mb-6">
            <eon-ui-text class="" size="small" text-style="copy">Unser Schulungsangebot wurde gemäß den E.ON- Konzernrichtlinien konzipiert. Es umfasst neben Basis- und
                Grundschulungen auch Wiederholungsschulungen sowie Unterweisungen. Das elektrotechnische Schulungsangebot richtet sich in erster Linie an elektrotechnisch
                unterwiesene Personen (EUP) und Elektrofachkräfte (EFK) (gemäß „DGUV Vorschrift 3“) von Energieversorgungsunternehmen, Netzbetreibern, Stadt- und
                Gemeindewerken. Mit unserem gas-technischen Schulungsangebot wollen wir Fachkräfte für ein sicheres Arbeiten im Bereich Gas weiterbilden. Durch die
                erfolgreiche Teilnahme an unseren Schulungen können Sie zusätzliche Arbeitsberechtigungen für die themenspezifische Ausführung im Netz erhalten. Beachten
                Sie hierzu die Beschreibungen der einzelnen Schulungen in unserem Programm.<br /><br />
                Eine Übersicht über den Gesamtkatalog ist
                <a class="pdf-link" href="/pdfs/Akademie_Schulungsprogramm_2024_web.pdf" target="_blank">hier</a> zu finden.
                <!-- <router-link :to="{ name: 'gesamtkatalog' }">
                    <eon-ui-link text="hier" hide-icon="true"></eon-ui-link>
                </router-link> zu finden. -->
            </eon-ui-text>
        </div>


        <div id="btn-block" >
            <div class="btn-block-entry" v-for="item in categories" 
                :style="(item.toLowerCase()==activCategory)? 'background-color: #0091bb;color:#fff;' : ''"
                v-on:click="changeCategory(item)">{{ item }}</div>
        </div>


        <eon-ui-data-table class="hydrated" paging="true" zebra="true" selectable-rows="false"
            expandable-rows="false" page-sizes="10,20,50,100" :initial-page-size="query.limit" :total-items="query.total"
            @pagechanged="pageChanged" @pagedecreased="pageDecreased" @pageincreased="pageIncreased" @pagesizechanged="pageSizeChanged"
            @selectionchanged="selectionChanged" @sortchanged="sortChanged">
            <eon-ui-table-toolbar slot="table-toolbar" @searchtermchanged="getSearchValueKatalog"></eon-ui-table-toolbar>
            <eon-ui-table-head>
                <eon-ui-table-head-row>
                    <eon-ui-table-head-cell class="" sortable="false" sort-key="id_kurs" :sort-order="sort.id_kurs">Nr.</eon-ui-table-head-cell>
                    <eon-ui-table-head-cell class="" sortable="false" sort-key="extra_nr" :sort-order="sort.extra_nr">Titel</eon-ui-table-head-cell>
                    <eon-ui-table-head-cell class="" sortable="false" sort-key="datum" :sort-order="sort.datum">Beginn</eon-ui-table-head-cell>
                    <eon-ui-table-head-cell class="" sortable="false" sort-key="endedatum" :sort-order="sort.endedatum">Ende</eon-ui-table-head-cell>
                    <eon-ui-table-head-cell class="" sortable="false" sort-key="gruppe_kurs" :sort-order="sort.gruppe_kurs">Seminarart</eon-ui-table-head-cell>
                    <eon-ui-table-head-cell class="" sortable="false" sort-key="ort" :sort-order="sort.ort">Ort</eon-ui-table-head-cell>
                </eon-ui-table-head-row>
            </eon-ui-table-head>
            <eon-ui-table-body v-show="dataTable">
                <eon-ui-table-row v-for="item in dataTable" :key="item.ID_Kurs">
                    <eon-ui-table-cell :value="item?.extra_nr">
                        <router-link :to="{ name: 'seminareDetail', params: { id: item.ID_Kurs } }"><eon-ui-link size="small" :text="item?.extra_nr" target="_self"
                            hide-icon="true" class="hydrated katalog-link"></eon-ui-link></router-link>
                    </eon-ui-table-cell>
                    <eon-ui-table-cell :value="item?.Bezeichnung">
                        <router-link :to="{ name: 'seminareDetail', params: { id: item.ID_Kurs } }"><eon-ui-link size="small" :text="item?.Bezeichnung" target="_self"
                            hide-icon="true" class="hydrated katalog-link"></eon-ui-link></router-link>
                    </eon-ui-table-cell>
                    <eon-ui-table-cell :value="item?.Datum">{{ item?.Datum }}</eon-ui-table-cell>
                    <eon-ui-table-cell :value="item?.Endedatum">{{ item?.Endedatum }}</eon-ui-table-cell>
                    <eon-ui-table-cell :value="item?.gruppe_kurs">{{ item?.gruppe_kurs }}</eon-ui-table-cell>
                    <eon-ui-table-cell :value="item?.Ort">{{item?.Ort}}</eon-ui-table-cell>
                </eon-ui-table-row>
            </eon-ui-table-body>
        </eon-ui-data-table>

    </eon-ui-section>

    <div class="loading-section" :class="loading ? 'show' : ''">
        <eon-ui-preloader-circle></eon-ui-preloader-circle>
    </div>

</template>

<script>
import Resource from "../../composables/bayernwerk.js";
import { formatDateDMY } from "../../utils/filter";
import _ from "lodash";
const bayernwerkResource = new Resource();

export default {
    name: "Katalog",
    data() {
        return {
            dataTable: [],
            dataTable_switch: 0,
            dataTable_searchValue: '',
            extern: this.$store.state.OrPe_extern == '1' ? '1' : '0',
            query:
            {
                page: 1,
                limit: 10,
                total: 10,
                extern: this.$store.state.OrPe_extern == '1' ? '1' : '0',
                katalog_detail: this.$route.params.detail
            },
            sort: {
                id_kurs: "",
                extra_nr: "",
                datum: "",
                endedatum: "",
                gruppe_kurs: "",
                ort: "",
            },
            loading: false,
            text_error: "",
            message_visible: false,
            categories: ["Gesamt","Strom","Gas","Qualifizierung"],
            activCategory: (this.$route.params.detail=='qualifizierungskonzept')? 'qualifizierung' : this.$route.params.detail
        };
    },
    computed: {
        user_orpe_extern() {
            return this.$store.state.OrPe_extern;
        }
    },
    watch: {
        user_orpe_extern() {
            this.fetchDataKatalog(this.query);
        }
    },
    mounted(){
        this.fetchDataKatalog(this.query);
    },
    created() {
    },
    setup() {
    },
    methods: {
        fetchDataKatalog(query) {
            this.loading = true;
            this.dataTable = [];
            const offset = (query.page - 1) * query.limit + 1;
            query.offset = offset;
            bayernwerkResource.getListKatalog(query).then((res) => {

                if (res != null) {
                    this.query.total = _.get(res, "response.dataInfo.foundCount", 0);
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;
                        this.dataTable = data_array.map((value) => {
                        return {
                            Bezeichnung: value.fieldData.Bezeichnung,
                            Datum: formatDateDMY(value.fieldData.Datum),
                            Endedatum: formatDateDMY(value.fieldData.Endedatum),
                            Ort: value.fieldData.Ort,
                            ID_Kurs: value.fieldData.ID_Kurs,
                            zielgruppe: value.fieldData.zielgruppe,
                            seminarnr: value.fieldData.seminarnr,
                            extra_nr: value.fieldData.extra_nr,
                            gruppe_kurs: value.fieldData.gruppe_kurs
                        };
                    });
                } else {
                    this.text_error = "Keine Ergebnisse gefunden.";
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        const code = res.messages[0].code;
                        if (code == 952 || code == 10) {
                            this.logoutTokenExpired();
                        }
                    }
                }else{
                    this.text_error = "Token expired";
                    this.message_visible = true;
                    this.logoutTokenExpired();
                }
            this.loading = false;
            }).catch((error) => {
                this.loading = false;
                const code = _.get(error, "response.data.messages[0].code", 0);
                if (code == 952 || code == 10) {
                    this.logoutTokenExpired();
                }
            });
        },
        searchKatalog() {
            this.loading = true;
            this.dataTable.length = 0;
            const offset = (this.query.page - 1) * this.query.limit + 1;
            bayernwerkResource.getSearchResultKatalog(this.extern, this.dataTable_searchValue, offset, this.query.limit, this.$route.params.detail).then((res) => {
                if (res != null) {
                    this.query.total = _.get(res, "response.dataInfo.foundCount", 0);
                    if(res.response && res.response.data && res.messages[0].code == "0" ){
                        const data_array = res.response.data;
                        this.dataTable = data_array.map((value) => {
                        return {
                            Bezeichnung: value.fieldData.Bezeichnung,
                            Datum: formatDateDMY(value.fieldData.Datum),
                            Endedatum: formatDateDMY(value.fieldData.Endedatum),
                            Ort: value.fieldData.Ort,
                            ID_Kurs: value.fieldData.ID_Kurs,
                            zielgruppe: value.fieldData.zielgruppe,
                            seminarnr: value.fieldData.seminarnr,
                            extra_nr: value.fieldData.extra_nr,
                            gruppe_kurs: value.fieldData.gruppe_kurs
                        };
                    });
                    }
                    else {
                        this.text_error = "Keine Ergebnisse gefunden.";
                        this.message_visible = true;
                        setTimeout(() => this.message_visible = false, 4000);
                        const code = res.messages[0].code;
                        if (code == 952 || code == 10) {
                            this.logoutTokenExpired();
                        }
                    }
                }
                else {
                    this.text_error = "Token expired";
                    this.message_visible = true;
                    this.logoutTokenExpired();
                }
            this.loading = false;
            }).catch((error) => {
                this.loading = false;
                console.log("er", error);
                const code = _.get(error, "response.data.messages[0].code", 0);
                if (code == 952 || code == 10) {
                    this.logoutTokenExpired();
                }
            });
            this.dataTable.length = 0;
        },
        pageChanged(event) {
            if(this.dataTable_switch == 0) {
                this.query.page = event.detail;
                this.fetchDataKatalog(this.query);
            }
            else {
                this.query.page = event.detail;
                this.searchKatalog();
            }
        },
        pageDecreased(event) {
            if(this.dataTable_switch == 0) {
                this.query.page = event.detail;
                this.fetchDataKatalog(this.query);
            }
            else {
                this.query.page = event.detail;
                this.searchKatalog();
            }
        },
        pageIncreased(event) {
            if(this.dataTable_switch == 0) {
                this.query.page = event.detail;
                this.fetchDataKatalog(this.query);
            }
            else {
                this.query.page = event.detail;
                this.searchKatalog();
            }
        },
        pageSizeChanged(event) {
            if(this.dataTable_switch == 0) {
                this.query.limit = event.detail;
                this.query.page = 1;
                this.fetchDataKatalog(this.query);
            }
            else {
                this.query.limit = event.detail;
                this.query.page = 1;
                this.searchKatalog();
            }
        },
        selectionChanged(event) {
            // console.log('selectionChanged',event);
        },
        sortChanged(event) {
            this.sort = {
                id_kurs: "",
                extra_nr: "",
                datum: "",
                endedatum: "",
                gruppe_kurs: "",
                ort: ""
            }
            this.sort[event.detail.sortKey] = event.detail.sortOrder;
        },
        logoutTokenExpired() {
            this.$store.dispatch("user/logout");
            setTimeout(() => this.$router.go(), this.$router.push({ name: "startseite" }), 4000);
        },
        dropdownLinkChanged(event) {
            if(event.detail === 'gesamt') {
                this.$router.push({ name: "katalog", params: { detail: 'gesamt' } });
                this.query.katalog_detail = "*";
                this.fetchDataKatalog(this.query);
            }
            if(event.detail === 'strom') {
                this.$router.push({ name: "katalog", params: { detail: 'strom' } });
                this.query.katalog_detail = "strom";
                this.fetchDataKatalog(this.query);
            }
            if(event.detail === 'gas') {
                this.$router.push({ name: "katalog", params: { detail: 'gas' } });
                this.query.katalog_detail = "gas";
                this.fetchDataKatalog(this.query);
            }
            if(event.detail === 'qualifizierungskonzept') {
                this.$router.push({ name: "katalog", params: { detail: 'qualifizierungskonzept' } });
                this.query.katalog_detail = "qualifizierungskonzept";
                this.fetchDataKatalog(this.query);
            }
        },
        resetSort() {
            // console.log('resetSort');
            this.sort = {
                id_kurs: "",
                extra_nr: "",
                datum: "",
                endedatum: "",
                gruppe_kurs: "",
                ort: ""
            }
        },
        getSearchValueKatalog(event) {
            if (event.detail != "")
            {
                this.dataTable_switch = 1;
                this.dataTable_searchValue = event.detail;
                this.query.page = 1;
                this.query.limit = 10;
                this.query.total = 10;
                this.searchKatalog();
            }
            else
            {
                this.dataTable_switch = 0;
                this.query.page = 1;
                this.query.limit = 10;
                this.query.total = 10;
                this.fetchDataKatalog(this.query);
            }
        },
        changeCategory(category) {
            let detail = category.toLowerCase();
            if(detail == this.activCategory) return;
            
            this.activCategory = detail;

            switch(detail) {
                case 'gesamt': 
                    this.query.katalog_detail = "*";
                    break;
                case 'qualifizierung': 
                    detail = 'qualifizierungskonzept';
                default: 
                    this.query.katalog_detail = detail; 
                    break;
            }
            
            // this.query.katalog_detail = (category==='Qualifizierung')? 'qualifizierungskonzept' : detail;

            this.$router.push({ name: "katalog", params: { detail: detail } });
            this.fetchDataKatalog(this.query);
        }
    },
};
</script>
