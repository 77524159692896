<template>

    <eon-ui-section class="hydrated">

        <!-- <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Übersicht</a>
                <a href="/agb">Allgemeine Geschäftsbedingungen</a>
            </eon-ui-breadcrumb>
        </div> -->

        <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">

                <eon-ui-headline text="Disclaimer" size="h4"></eon-ui-headline>

                <div class="px-8">
                    <eon-ui-text class="hydrated text-blue" text-style="rtf">
                        Allgemeine Nutzungsbedingungen
                    </eon-ui-text>
                    <div class="px-8 py-2">
                        <eon-ui-text text-style="copy">
                            Die Bayernwerk Akademie GmbH  bemüht sich im Rahmen des Zumutbaren, auf dieser Website richtige und
                            vollständige Informationen zur Verfügung zu stellen. Die Bayernwerk Akademie GmbH  übernimmt jedoch keine Haftung oder Garantie für die
                            Aktualität, Richtigkeit und Vollständigkeit der auf dieser Website bereitgestellten Informationen. Dies gilt auch für alle
                            Verbindungen ("Links"), auf die diese Website direkt oder indirekt verweist. Die Bayernwerk Akademie GmbH  ist für den Inhalt einer
                            Seite, die mit einem solchen Link erreicht wird, nicht verantwortlich. Die Bayernwerk Akademie GmbH  behält sich das Recht vor, ohne
                            vorherige Ankündigung Änderungen oder Ergänzungen der bereitgestellten Informationen vorzunehmen.<br /><br />

                            Der Inhalt dieser Website ist urheberrechtlich geschützt. Die Bayernwerk Akademie GmbH  gewährt Ihnen jedoch das Recht, den auf dieser
                            Website bereitgestellten Text ganz oder ausschnittsweise zu speichern und zu vervielfältigen. Aus Gründen des Urheberrechts ist
                            allerdings die Speicherung und Vervielfältigung von Bildmaterial oder Grafiken aus dieser Website nicht gestattet.<br /><br />

                            Die Informationen auf dieser Website sind weder eine Aufforderung zum Kauf noch gelten sie als Aufforderung zum sonstigen Handel
                            mit Aktien.<br /><br />

                            Die Bayernwerk Akademie GmbH  haftet nicht für direkte oder indirekte Schäden, einschließlich entgangenen Gewinns, die aufgrund von
                            oder sonst wie in Verbindung mit Informationen entstehen, die auf dieser Website bereitgehalten werden.<br /><br />
                        </eon-ui-text>
                    </div>

                    <eon-ui-text class="hydrated text-blue" text-style="rtf">
                        Links zu anderen Websites
                    </eon-ui-text>
                    <div class="px-8 py-2">
                        <eon-ui-text text-style="copy">
                            Die Bayernwerk-Website enthält Links zu anderen Websites. Für die Datenschutzprinzipien oder den Inhalt der verlinkten Seiten sind
                            ausschließlich deren Betreiber verantwortlich.<br /><br />
                        </eon-ui-text>
                    </div>

                    <eon-ui-text class="hydrated text-blue" text-style="rtf">
                        Sicherheit
                    </eon-ui-text>
                    <div class="px-8 py-2">
                        <eon-ui-text text-style="copy">
                            Bayernwerk setzt technische und organisatorische Sicherheitsmaßnahmen ein, um die Daten, die Sie Bayernwerk zur Verfügung stellen,
                            durch zufällige oder vorsätzliche Manipulationen, Verlust, Zerstörung oder den Zugriff unberechtigter Personen zu schützen. Unsere
                            Sicherheitsmaßnahmen werden entsprechend der technologischen Entwicklung fortlaufend verbessert.<br /><br /><br />

                            Wenn Sie weitere Fragen zur Verarbeitung Ihrer persönlichen Daten bzw. zum Datenschutz haben, wenden Sie sich bitte an den
                        <eon-ui-link href="https://www.bayernwerk-netz.de/de/bayernwerk-netz-gmbh/datenschutz.html" text="Datenschutzbeauftragten der Bayernwerk Akademie GmbH " target="_blank" display-inline="true" class="hydrated" scheme="turquoise"></eon-ui-link>.
                        </eon-ui-text>
                    </div>
                </div>

            </eon-ui-grid-control-column>
        </eon-ui-grid-control>
    </eon-ui-section>

</template>


