import request from '../utils/request';


/**
 * Simple RESTful resource class
 */
class BayernwerkResource {

  constructor() {
    this.uri = JSON.parse(localStorage.getItem("user_store")) && JSON.parse(localStorage.getItem("user_store")).baseUrl != "" ? JSON.parse(localStorage.getItem("user_store")).baseUrl: "";
  }
  list(offset = 0, limit = 10) {
    return request({
      url: '/seminars',
      method: 'get',
      params: {_offset: offset, _limit: limit},
    });
  }
  get(id) {
    return request({
      url: '/seminar/' + id,
      method: 'get',
    });
  }
  slug(title, id = '') {
    var url = this.uri + '/slug/' + title;
    if (id != null && id !== ''){
      url += '/' + id;
    }
    return request({
      url: url,
      method: 'get',
    });
  }
  store(resource) {
    return request({
      url: '/' + this.uri,
      method: 'post',
      data: resource,
    });
  }

  getListKatalog(query) {
    return request({
      url: '/get-list-katalog',
      method: 'post',
      data: query,
    });
  }

  getGesamtkatalog() {
    return request({
      url: '/get-gesamtkatalog',
      method: 'get'
    });
  }

  getListKatalogLastMinute(query) {
    return request({
      url: this.uri + '/get-list-katalog-last-minute',
      method: 'post',
      data: query
    });
  }

  login(auth) {
    return request({
      url: '/get-user-info-login',
      method : 'post',
      data: auth
    });
  }


  registerCiam(obj) {
    return request({
      url: '/register-ciam',
      method : 'post',
      data: obj
    });
  }

  resetPassword(auth) {
    return request({
      url: '/reset-password',
      method : 'post',
      data: auth
    });
  }

  forgotPassword(data) {
    return request({
      url: '/forgot-password',
      method : 'post',
      data: data
    });
  }

  resetPasswordBayernWerk(data) {
    return request({
      url: '/reset-password-bayernwerk',
      method : 'post',
      data: data
    });
  }

  getSeminareDetail(resource) {
    return request({
      url: '/get-seminare-detail',
      method: 'post',
      data: resource
    });
  }

  getListSeminartyp(query) {
    return request({
      url: '/get-list-seminartyp',
      method: 'post',
      data: query
    });
  }

  getListTeilnehmer(ID_Kurs) {
    return request({
      url: '/get-list-teilnehmer',
      method: 'post',
      data: ID_Kurs
    });
  }

  getMitarbeiterDetail(resource) {
    return request({
      url: '/get-mitarbeiter-detail',
      method: 'post',
      data: resource,
    });
  }

  getListSchulungen(query) {
    return request({
      url: '/get-list-schulungen',
      method: 'post',
      data: query
    });
  }

  getListSchulungenDozent(query) {
    return request({
      url: '/get-list-schulungen-dozent',
      method: 'post',
      data: query
    });
  }

  getBefaehigungenFuehrungskraft(ds_id) {
    return request({
      url: '/get-befaehigungen-fuehrungskraft',
      method: 'post',
      data: ds_id,
    });
  }

  getBefaehigungenAnsprechpartner(ds_id) {
    return request({
      url: '/get-befaehigungen-ansprechpartner',
      method: 'post',
      data: ds_id,
    });
  }

  getListBefaehigungen(resource) {
    return request({
      url: '/get-list-befaehigungen',
      method: 'post',
      data: resource,
    });
  }

  getTeilnehmerBefaehigungenJahr(jahr, ds_id, status) {
    return request({
        url: '/get-teilnehmer-befaehigungen-jahr',
        method: 'post',
        data: {jahr: jahr, ds_id: ds_id, status: status},
      });
  }

  getTeilnehmerSchulungenJahr(jahr, ds_id, status) {
    return request({
        url: '/get-teilnehmer-schulungen-jahr',
        method: 'post',
        data: {jahr: jahr, ds_id: ds_id, status: status},
      });
  }

  getAnsprechpartnerDetail(resource) {
    return request({
      url: '/get-ansprechpartner-detail',
      method: 'post',
      data: resource,
    });
  }

  getSeminareBookingDetail(resource) {
    return request({
      url: '/get-seminare-booking-detail',
      method: 'post',
      data: resource,
    });
  }

  saveSeminareBooking(resource) {
    return request({
      url: '/save-seminare-booking',
      method: 'post',
      data: resource,
    });
  }

  saveContactForm(resource) {
    return request({
      url: '/save-contact-form',
      method: 'post',
      data: resource,
    });
  }

  getTeilnehmerAnsprechpartner(ds_id) {
    return request({
      url: '/get-teilnehmer-ansprechpartner',
      method: 'post',
      data: ds_id,
    });
  }

  getTeilnehmerFuehrungskraft(ds_id) {
    return request({
      url: '/get-teilnehmer-fuehrungskraft',
      method: 'post',
      data: ds_id,
    });
  }

  sendMailRegistration(resource) {
    return request({
      url: '/send-mail-registration',
      method: 'post',
      data: resource,
    });
  }

  sendMailRegistrationEmployees(resource) {
    return request({
      url: '/send-mail-registration-employees',
      method: 'post',
      data: resource,
    });
  }

  getSearchResultTeilnehmerFK(ds_id, searchValue, offset, limit) {
    return request({
      url: '/get-search-result-teilnehmer-fk',
      method: 'post',
      data: {ds_id: ds_id, searchValue: searchValue, offset: offset, limit: limit}
    });
  }

  getSearchResultBefaehigungenFK(ds_id, searchValue, offset, limit) {
    return request({
      url: '/get-search-result-befaehigungen-fk',
      method: 'post',
      data: {ds_id: ds_id, searchValue: searchValue, offset: offset, limit: limit}
    });
  }

  getSearchResultTeilnehmerAP(ds_id, searchValue, offset, limit) {
    return request({
      url: '/get-search-result-teilnehmer-ap',
      method: 'post',
      data: {ds_id: ds_id, searchValue: searchValue, offset: offset, limit: limit}
    });
  }

  getSearchResultBefaehigungenAP(ds_id, searchValue, offset, limit) {
    return request({
      url: '/get-search-result-befaehigungen-ap',
      method: 'post',
      data: {ds_id: ds_id, searchValue: searchValue, offset: offset, limit: limit}
    });
  }

  getSearchResultKatalog(extern, searchValue, offset, limit, detail) {
    return request({
      url: '/get-search-result-katalog',
      method: 'post',
      data: {extern: extern, searchValue: searchValue, offset: offset, limit: limit, detail: detail}
    });
  }
}

export { BayernwerkResource as default };
