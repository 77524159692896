<template>
    <eon-ui-section class="hydrated">

        <eon-ui-headline text="Registrierung" size="h4" class="hydrated text-blue"></eon-ui-headline>
        <div class="pb-4 pt-4">
            <eon-ui-text text-style="copy">Felder mit * sind Pflichtfelder.</eon-ui-text>
        </div>

        <eon-ui-form name="formElement" method="post" class="form-register"
            @formsubmit="submitForm">
            <eon-ui-grid-control grid="grid__d6-6__t6-6" class="hydrated">
                <eon-ui-grid-control-column slot="column-1" class="hydrated">

                    <div class="form-group">
                      <eon-ui-input @valuechanged="(e)=>{changeValue(e.target.value, 'firstName')}" required class="input-custom" label="Vorname: *" name="vorname"></eon-ui-input>
                    </div>
                    <div class="form-group">
                      <eon-ui-input @valuechanged="(e)=>{changeValue(e.target.value, 'lastName')}"  required class="input-custom" label="Name: *" name="name"></eon-ui-input>
                    </div>
                    <div class="form-group">
                        <eon-ui-input type="tel" @valuechanged="(e)=>{handleValidateTel(e);changeValue(e.target.value, 'mobilePhone')}" required class="input-custom" label="Telefon: *" name="telefon"> </eon-ui-input>
                    </div>
                    <eon-ui-input type="hidden" name="hiddenField" value="iamhidden"></eon-ui-input>
                    <div class="form-group">
                        <eon-ui-text color="eon-bag-darkgrey" class="" size="small" text-style="copy-small">
                            E-Mail: Wir senden Ihnen im Anschluss an die Registrierung eine E-Mail zur Verifizierung dieser E-Mail Adresse zu.</eon-ui-text>
                        <eon-ui-input required class="input-custom" type="email" @valuechanged="(e)=>{handleValidateEmail(e);changeValue(e.target.value, 'email')}" label="E-Mail: *" name="email"> </eon-ui-input>
                    </div>


                </eon-ui-grid-control-column>
                <eon-ui-grid-control-column slot="column-2" class="hydrated">
                    <div class="form-group">
                        <eon-ui-checkbox required label="Hiermit stimme ich der Erfassung und Verarbeitung meiner Daten gemäß den Datenschutzhinweisen zu." value="agb"></eon-ui-checkbox>
                    </div>
                    <div class="form-group">
                        <eon-ui-button :disabled="loading" class="btn-custom-blue mr-2" text="Registrierung abschließen" input-type="submit"></eon-ui-button>
                    </div>
                    <eon-ui-form-message :type="messageType" :message="message" class="hydrated error-red"></eon-ui-form-message>
                </eon-ui-grid-control-column>
            </eon-ui-grid-control>
        </eon-ui-form>
    </eon-ui-section>
</template>

<script>
import _ from "lodash";
import Resource from "../../composables/bayernwerk";

const bayernwerkResource = new Resource();
export default {
  name: "CiamRegister",
  setup() {

  },
  data(){
    return {
        obj:  {
            firstName: "",
            lastName: "",
            email: "",
            mobilePhone: "",
            },
        message: '',
        messageType: 'danger',
        loading: false,
    }
  },
  methods: {
    changeValue(value, field){
        _.set(this.obj, field, value);
    },
    handleValidateEmail(e){
        const element = e.target;
        var errorMessage =null;
         if(!String(element.value)
            .toLowerCase()
            .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )){
                errorMessage ='Ungültige E-Mail!';
            }
            ;
        element.handleCustomValidation(errorMessage);
    },
    handleValidateTel(e){
        const element = e.target;
        var errorMessage =null;
         if(!String(element.value)
            .toLowerCase()
            .match(
            /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
            )){
                errorMessage ='Ungültige Telefon!';
            }
            ;
        element.handleCustomValidation(errorMessage);
    },
        submitForm(event) {
            this.loading = true;
            event.preventDefault();
            this.message = '';
            console.log('obj', this.obj);
            bayernwerkResource.registerCiam(this.obj).then((res) => {
                console.log('res', res);
                if (res != null && _.get(res,'egcid', null) != null) {
                    this.messageType = 'info';
                    this.message = 'Erfolgreich registriert! Überprüfen Sie Ihre E-Mails, um ein Passwort für die Anmeldung festzulegen.';
                }
                else if(_.get(res,'0.message', null) != null)
                {
                    this.messageType = 'warning';
                    this.message = _.get(res,'0.message', null);
                }
                this.loading = false;
            });
        }
  },
};
</script>
