const state_initial = {
    token: null,
    Tetra_id: "",
    ansprechpartner: "",
    dozent: "",
    ds_id: "",
    doz_id: "",
    email: "",
    fuehrungskraft: "",
    name_gesamt: "",
    // passwort: "",
    teilnehmer: "",
    recordId: "",
    OrPe_extern: "1",
    baseUrl: "",
    baseUrlTime: "",
    // ciam_endpoint: "",
    // microsoft_endpoint:"",
    baseStage: "dev",
};

const mutations = {
    SET_TOKEN: (state, token) => {
        state_initial.token = token;
        localStorage.setItem("user_store", JSON.stringify(state_initial));
    },
    SET_NAME: (state, name) => {
        state_initial.name_gesamt = name;
        localStorage.setItem("user_store", JSON.stringify(state_initial));
    },
    SET_USER: (state, user) => {
        state_initial.token = user.token;
        state_initial.Tetra_id = user.Tetra_id;
        state_initial.ansprechpartner = user.ansprechpartner;
        state_initial.dozent = user.dozent;
        state_initial.ds_id = user.ds_id;
        state_initial.doz_id = user.doz_id;
        state_initial.email = user.email;
        state_initial.fuehrungskraft = user.fuehrungskraft;
        state_initial.name_gesamt = user.name_gesamt;
        // state_initial.passwort = user.passwort;
        state_initial.teilnehmer = user.teilnehmer;
        state_initial.recordId = user.recordId;
        state_initial.OrPe_extern = user.OrPe_extern;
        localStorage.setItem("user_store", JSON.stringify(state_initial));
    },
    REMOVE: () => {
        state_initial.recordId = "";
        state_initial.token = null;
        state_initial.email = "";
        state_initial.name_gesamt = "";
        state_initial.OrPe_extern = "1";
        state_initial.Tetra_id = "";
        state_initial.ds_id = "";
        state_initial.doz_id = "";
        localStorage.setItem("user_store", JSON.stringify(state_initial));
    },
    SET_BASE_URL: (state, url) => {
        state_initial.baseUrl = url.config_api_url;
        state_initial.baseStage = url.config_stage ? url.config_stage : 'dev';
        // state_initial.ciam_endpoint = url.ciam_endpoint;
        // state_initial.microsoft_endpoint = url.microsoft_endpoint;
        state_initial.baseUrlTime = Date.now();
        localStorage.setItem("user_store", JSON.stringify(state_initial));
    },
    RESET_BASE_URL: (state) => {
        state_initial.baseUrl = "";
        state_initial.baseStage = "dev";
        state_initial.baseUrlTime = "";
        localStorage.setItem("user_store", JSON.stringify(state_initial));
    },
    initialiseStore(state) {
        if (localStorage.getItem("user_store")) {
            // Replace the state object with the stored item
            this.replaceState(
                Object.assign(state,JSON.parse(localStorage.getItem("user_store")))
            );
        }
    },
};

const actions = {
    login({ commit }, userInfo) {
        commit("SET_USER", userInfo);
    },
    setToken({ commit }, token) {
        commit("SET_TOKEN", token);
    },
    setName({ commit }, name) {
        commit("SET_NAME", name);
    },
    // user logout
    logout({ commit }) {
        commit("REMOVE");
    },
    setBaseUrl({ commit }, url) {
        commit("SET_BASE_URL", url);
    },
    resetTimeBaseUrl({ commit }) {
        commit("RESET_BASE_URL");
    },
};

export default {
    namespaced: true,
    state: state_initial,
    mutations,
    actions,
};
