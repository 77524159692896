<template>
    <eon-ui-error-message icon-name="radio_checkbox_tick" :is-visible="message_visible_reset" inner-background="eon-bag-blue" class="hydrated custom-message-success" fixed-position="true" close-timer="5" >
        <eon-ui-headline slot="headline" size="h5" :text="text_success" class="hydrated"></eon-ui-headline>
        <eon-ui-text size="small" text-style="copy-small">Rückkehr zur Startseite nach 3 Sekunden...</eon-ui-text>
    </eon-ui-error-message>

    <eon-ui-section class="hydrated">
        <eon-ui-grid-control grid="grid__d4-4-4__t12" class="hydrated">

            <eon-ui-grid-control-column slot="column-1" class="hydrated"></eon-ui-grid-control-column>
            <eon-ui-grid-control-column slot="column-2" class="hydrated">

                <div class="form-group">
                    <eon-ui-headline text="Passwort setzen" size="h4" class="hydrated text-blue"></eon-ui-headline>
                </div>

                <eon-ui-form ref="formForgotPassword" method="post" enctype="application/x-www-form-urlencoded" class="hydrated form-login"
                    @formsubmit="submitFormForgotPassword">

                    <div class="form-group mb-2">
                        <eon-ui-input v-model="reset_password.email" type="email" class="input-custom" label="Ihre E-Mail-Adresse:" id="email" name="email">
                        </eon-ui-input>
                    </div>
                    <div class="mt-2">
                        <router-link :to="{ name: 'login' }">
                            <eon-ui-link text="< Zurück" class="hydrated text-link-blue" hide-icon="true" size="small"></eon-ui-link>
                        </router-link>
                    </div>
                    <div class="form-group mb-6" align="right">
                        <eon-ui-button :preloader="loading" text="Abschicken" input-type="submit" class="btn-custom-blue"></eon-ui-button>
                    </div>

                    <div class="form-group">
                        <eon-ui-form-message type="danger" :message="error_msg" class="hydrated error-red"></eon-ui-form-message>
                    </div>
                    <div slot="200">
                        <eon-ui-form-message type="info" message="Anfrage erfolgreich" class="hydrated"></eon-ui-form-message>
                    </div>
                    <div slot="500">
                        <eon-ui-form-message type="warning" message="Anfrage fehlgeschlagen" class="hydrated"></eon-ui-form-message>
                    </div>

                </eon-ui-form>

            </eon-ui-grid-control-column>
            <eon-ui-grid-control-column slot="column-3" class="hydrated"></eon-ui-grid-control-column>

        </eon-ui-grid-control>
    </eon-ui-section>
</template>

<script>
import _ from "lodash";
import Resource from "../../composables/bayernwerk";

const bayernwerkResource = new Resource();

export default {
    name: "ForgotPassword",
    setup() { },
    data() {
    return {
        reset_password:
        {
        email: ""
        },
        loading: false,
        message_visible_reset: false,
        text_success: "",
        error_msg: ""
    };
  },
  mounted() {
    // console.log(this.$store.state);
  },
    methods: {
        submitFormForgotPassword(event) {
            this.loading = true;
            event.preventDefault();
            this.error_msg = '';
            bayernwerkResource.forgotPassword(this.reset_password).then((res) => {
            if (res != null) {
                if(res.messages && res.messages[0]['code']== "0"){
                    this.text_success = "Die E-Mail zum Zurücksetzen des Passworts wurde erfolgreich gesendet. Bitte überprüfen Sie Ihr Postfach";
                    this.message_visible_reset = true;
                }else{
                    if(res.error_msg){
                        this.error_msg = res.error_msg;
                    }else{
                        this.error_msg = res.messages[0].message;
                    }
                }
            }
            this.loading = false;
          }).catch((error) => {
            console.log(error);
            this.error_msg = 'Senden der E-Mail zum Zurücksetzen des Passworts fehlgeschlagen!';
            this.loading = false;
          });
        }
    },
};
</script>
