<!-- Umbau loginpage, Funktionalität: Header.Login && Header.Mitarbeiter-Login && Header.Registrieren -->
<template>
  <eon-ui-error-message icon-name="radio_checkbox_tick" :is-visible="message_visible_reset" inner-background="eon-bag-blue" class="hydrated custom-message-success" fixed-position="true" close-timer="5" >
    <eon-ui-headline slot="headline" size="h5" :text="text_success" class="hydrated"></eon-ui-headline>
    <eon-ui-text size="small" text-style="copy-small">Rückkehr zur Startseite nach 3 Sekunden...</eon-ui-text>
  </eon-ui-error-message>

  <eon-ui-section class="hydrated">
    <div class="login-headline">
      <eon-ui-headline size="h3" text="Im Partnerbereich der Bayernwerk Akademie anmelden" class="hydrated "></eon-ui-headline>
    </div>
    <eon-ui-link text="E.ON Mitarbeiter Login" icon-position="right" :href="microsoft_endpoint" v-on:click="loading=true"></eon-ui-link><br /><br />
    <eon-ui-link text="Partnerfirmen-Login" icon-position="right" :href="ciam_endpoint" v-on:click="loading=true"></eon-ui-link><br />
    <eon-ui-link text="Registrieren" icon-position="right" href="/ciam-registrieren"></eon-ui-link>

    <div class="login-headline">
      <eon-ui-headline size="h3" text="Du willst Teil der neuen Energiewelt werden? Dann nimm mit uns Kontakt auf." class="hydrated login-headline"></eon-ui-headline>
    </div>

    <div class="custom-button" v-on:click="goToKontakt" >
      <div>
        <eon-ui-icon name='arrow_right'></eon-ui-icon>
      </div>
      <div>Jetzt Partner werden</div>
    </div>
    <!-- <eon-ui-button class="btn-custom-blue" text="Jetzt Partner werden" input-type="button" v-on:click="goToKontakt"></eon-ui-button> -->
  </eon-ui-section>
  
  <div class="loading-section" :class="loading ? 'show' : ''">
    <eon-ui-preloader-circle></eon-ui-preloader-circle>
  </div>
</template>

<script>
import _ from "lodash";
import Resource from "../../composables/bayernwerk";
import { getCiam, getAad } from "../../utils/request";

const bayernwerkResource = new Resource();

export default {
  name: "Login",
  data() {
    return {
      name_gesamt: this.$store.state.name_gesamt,
      loading: false,
      hasLogin: false,

      ciam_endpoint: "",
      microsoft_endpoint: "",
      
      aad:{
        url:"https://login.microsoftonline.com/b914a242-e718-443b-a47c-6b4c649d8c0a/oauth2/v2.0/authorize",
        clientId:{
          run:"f1d1c4f0-06c7-44bc-a49a-8ffa33a388ed",
          qas:"f972b60a-79ac-48c3-ae2e-f0e3167d3eed",
          dev:"b7f6615e-b151-4de7-b66d-b3eb9dac6b8a"
        },
        scope:"openid%20profile%20email",
        responseType:"code"
      },
      ciam:{
        url:{
          run:"https://login.bayernwerk.de/services/oauth2/authorize/expid_A0024L02",
          qas:"https://login-sit.bayernwerk.de/services/oauth2/authorize/expid_A0024L02",
          dev:"https://login-dev.bayernwerk.de/services/oauth2/authorize/expid_A0024L02"
        },
        clientId:{
          run:"3MVG9TSaZ8P6zP1oFE0hIlrNSo6zTAtydDwKJxKWCynv6nXFWWuL1fPvmQwHdseMFPi2Cl_fwfaWiwsesp8_E",
          qas:"3MVG9od6vNol.eBhEpCYDGdnu3FlNr87GLiLa7_zZrXMi.g4e0jmlQeC0SbodlKM4kFn_DL41lLYf4GYmHQ9M",
          dev:"3MVG9GXbtnGKjXe5LNKzBzCozyoAaiF.VuMQyD7qTM3g51uy08bKAQgpaTHnez2lRCo.kByUqwT83zxIHr6Rv"
        },
        scope:"openid",
        responseType:"code"
      },
      
      user: {
        user_teilnehmer: this.$store.state.teilnehmer,
        user_fuehrungskraft: this.$store.state.fuehrungskraft,
        user_ansprechpartner: this.$store.state.ansprechpartner,
        user_ds_id: this.$store.state.ds_id,
        user_tetra_id: this.$store.state.Tetra_id,
        user_email: this.$store.state.email,
      },
      message_visible: false,
      // message_visible_reset: false,
      text_success: "",
      error_msg: ""
    };
  },
  created() {
    if(!(typeof this.$store.state.token == 'undefined' || this.$store.state.token === '' || this.$store.state.token == null)) {
      this.hasLogin = true;
    }
  },
  mounted() {
    this.setEndpoints();
    
    if(this.$route.query.code) {
      const paths = this.$route.path.split('/');
      if(paths.length> 2) {
        const provider = paths[2];
        this.loading = true;
        this.error_msg = '';
        
        bayernwerkResource.login({code: this.$route.query.code, provider}).then((res) => {

          if(res != null) {

            if(res.data !== undefined) {

              if(res.data.token_session_login) {

                const userData = res.data;
                const fullName = (userData.name_gesamt.includes('@'))? 
                  userData.name_gesamt.substring(0,userData.name_gesamt.indexOf('@')) :
                  _.get(userData, 'name_gesamt','');

                this.$store.dispatch("user/login", {
                  token: _.get(userData, 'token_session_login',''),
                  Tetra_id: _.get(userData, 'Tetra_id',''),
                  ansprechpartner: _.get(userData, 'ansprechpartner',''),
                  dozent: _.get(userData, 'dozent',''),
                  ds_id: _.get(userData, 'ds_id',''),
                  email: _.get(userData, 'email',''),
                  fuehrungskraft: _.get(userData, 'fuehrungskraft',''),
                  name_gesamt: fullName,
                  teilnehmer: _.get(userData, 'teilnehmer',''),
                  OrPe_extern: _.get(userData, 'OrPe_extern', '1'),
                  doz_id: _.get(userData, 'doz_id','')
                });
                
                this.hasLogin = true;
                this.name_gesamt = userData.name_gesamt;
                this.text_success = "Erfolgreich eingeloggt!";
                this.message_visible = true;
                
                this.$router
                  .push({ name: "startseite" })
                  .then(() => { this.$router.go(0) });
              }
            }
            else {
              if(res.error_msg){
                this.error_msg = res.error_msg;
              }else{
                this.error_msg = res.message;
              }
            }

          }
          this.loading = false;
        });
      }
    }
  },
  methods: {
    goToKontakt() {
      this.$router.push({ name: 'kontakt' });
    },
    setEndpoints() {
      const redirectUri = this.getRedirectUri();
      this.ciam_endpoint = this.ciam.url[this.$store.state.baseStage] + 
        "?response_type=" + this.ciam.responseType + 
        "&client_id=" + this.ciam.clientId[this.$store.state.baseStage] + 
        "&scope=" + this.ciam.scope + 
        "&redirect_uri=" + redirectUri + "ciam";
      
        this.microsoft_endpoint = this.aad.url + 
        "?response_type=" + this.aad.responseType + 
        "&client_id=" + this.aad.clientId[this.$store.state.baseStage] + 
        "&scope=" + this.aad.scope + 
        "&redirect_uri="+ redirectUri + "aad";
    },
    getRedirectUri() {
      let location = window.location.href;

      // for local debugging
      if( window.location.hostname === "127.0.0.1" ) {
        location = location.replace(window.location.hostname, 'localhost');
      }
      
      return encodeURIComponent(location + "/");
    }
  },
};
</script>
