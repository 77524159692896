import { createRouter, createWebHistory } from "vue-router";

// import Dashboard from "../components/Dashboard.vue";
import Login from "../components/bayernwerk/Login.vue";
import Registers from "../components/bayernwerk/Register.vue";
import CiamRegister from "../components/bayernwerk/CiamRegister.vue";
import Faq from "../components/bayernwerk/allgemein/Faq.vue";
import Katalog from "../components/bayernwerk/Katalog.vue";
import Gesamtkatalog from "../components/bayernwerk/katalog/Gesamtkatalog.vue";
import Seminartyp from "../components/bayernwerk/Seminartyp.vue";
import Profile from "../components/bayernwerk/Profile.vue";
import ResetPassword from "../components/bayernwerk/ResetPassword.vue";
import ForgotPassword from "../components/bayernwerk/ForgotPassword.vue";
import SeminareDetail from "../components/bayernwerk/SeminareDetail.vue";
import Impressum from "../components/bayernwerk/allgemein/Impressum.vue";
import Datenschutz from "../components/bayernwerk/allgemein/Datenschutz.vue";
import AGB from "../components/bayernwerk/allgemein/AGB.vue";
import MeineDaten from "../components/bayernwerk/MeineDaten.vue";
import MeineSchulungen from "../components/bayernwerk/MeineSchulungen.vue";
import MeineBefaehigungen from "../components/bayernwerk/MeineBefaehigungen.vue";
import MeineDatenAP from "../components/bayernwerk/MeineDatenAP.vue";
import MeineMitarbeiterAP from "../components/bayernwerk/MeineMitarbeiterAP.vue";
import MeineMitarbeiter from "../components/bayernwerk/MeineMitarbeiter.vue";
import Trainingscenter from "../components/bayernwerk/allgemein/Trainingscenter.vue";
import Teilnahmebedingungen from "../components/bayernwerk/katalog/Teilnahmebedingungen.vue";
import Abkuerzungsverzeichnis from "../components/bayernwerk/katalog/Abkuerzungsverzeichnis.vue";
import NotFound from "../components/NotFound.vue";
import Bildungspartner from "../components/bayernwerk/Bildungspartner.vue";
import Startseite from "../components/Startseite.vue";

import RegistrationSeminar from "../components/bayernwerk/RegistrationSeminar.vue";
import Kontakt from "../components/bayernwerk/Kontakt.vue";

import Layout from "../components/Layout.vue";

const routes = [
  // {
  //   path: "/dashboard",
  //   alias: "/",
  //   component: Layout,
  //   children: [
  //     {
  //       path: "",
  //       name: "dashboard",
  //       component: Dashboard,
  //     },
  //   ],
  // },
  {
    path: "/startseite",
    alias: "/",
    component: Layout,
    children: [
      {
        path: "",
        name: "startseite",
        component: Startseite,
      },
    ],
  },
  {
    path: "/katalog/:detail",
    component: Layout,
    children: [
      {
        path: "",
        name: "katalog",
        component: Katalog,
      },
    ],
  },
  {
    path: "/gesamtkatalog",
    component: Layout,
    children: [
      {
        path: "",
        name: "gesamtkatalog",
        component: Gesamtkatalog,
      },
    ],
  },
  {
    path: "/seminartyp/:id",
    component: Layout,
    children: [
      {
        path: "",
        name: "seminartyp",
        component: Seminartyp,
      },
    ],
  },
  {
    path: "/login",
    component: Layout,
    props: true,
    children: [
      {
        path: "",
        name: "login",
        component: Login,
      },
    ],
  },
  {
    path: "/login/ciam",
    component: Layout,
    props: true,
    children: [
      {
        path: "",
        name: "login-ciam",
        component: Login,
      },
    ],
  },
  {
    path: "/login/aad",
    component: Layout,
    props: true,
    children: [
      {
        path: "",
        name: "login-aad",
        component: Login,
      },
    ],
  },
  // {
  //   path: "/registrieren",
  //   component: Layout,
  //   props: true,
  //   children: [
  //     {
  //       path: "",
  //       name: "registrieren",
  //       component: Registers,
  //     },
  //   ],
  // },
  {
    path: "/ciam-registrieren",
    component: Layout,
    props: true,
    children: [
      {
        path: "",
        name: "ciamRegistrieren",
        component: CiamRegister,
      },
    ],
  },
  {
    path: "/faq",
    component: Layout,
    props: true,
    children: [
      {
        path: "",
        name: "faq",
        component: Faq,
      },
    ],
  },
  // {
  //   path: "/profile",
  //   component: Layout,
  //   children: [
  //     {
  //       path: "",
  //       name: "profile",
  //       component: Profile,
  //     },
  //   ],
  // },
  // {
  //   path: "/reset-password-bayernwerk",
  //   component: Layout,
  //   children: [
  //     {
  //       path: "",
  //       name: "resetPasswordBayernwerk",
  //       component: ResetPassword,
  //     },
  //   ],
  // },
  // {
  //   path: "/forgot-password-bayernwerk",
  //   component: Layout,
  //   children: [
  //     {
  //       path: "",
  //       name: "forgotPasswordBayernwerk",
  //       component: ForgotPassword,
  //     },
  //   ],
  // },
  {
    path: "/seminare/:id",
    component: Layout,
    children: [
      {
        path: "",
        name: "seminareDetail",
        component: SeminareDetail,
      },
    ],
  },
  {
    path: "/registration/:id",
    component: Layout,
    children: [
      {
        path: "",
        name: "registrationSeminar",
        component: RegistrationSeminar,
      },
    ],
  },
  {
    path: "/impressum",
    component: Layout,
    children: [
      {
        path: "",
        name: "impressum",
        component: Impressum,
      },
    ],
  },
  {
    path: "/datenschutz",
    component: Layout,
    children: [
      {
        path: "",
        name: "datenschutz",
        component: Datenschutz,
      },
    ],
  },
  {
    path: "/agb",
    component: Layout,
    children: [
      {
        path: "",
        name: "agb",
        component: AGB,
      },
    ],
  },
  {
    path: "/kontakt",
    component: Layout,
    children: [
      {
        path: "",
        name: "kontakt",
        component: Kontakt,
      },
    ],
  },
  {
    path: "/meine_daten",
    component: Layout,
    children: [
      {
        path: "",
        name: "meine_daten",
        component: MeineDaten,
      },
    ],
  },
  {
    path: "/meine_schulungen",
    component: Layout,
    children: [
      {
        path: "",
        name: "meine_schulungen",
        component: MeineSchulungen,
      },
    ],
  },
  {
    path: "/meine_befaehigungen",
    component: Layout,
    children: [
      {
        path: "",
        name: "meine_befaehigungen",
        component: MeineBefaehigungen,
      },
    ],
  },
  {
    path: "/meine_daten_ap",
    component: Layout,
    children: [
      {
        path: "",
        name: "meine_daten_ap",
        component: MeineDatenAP,
      },
    ],
  },
  {
    path: "/meine_mitarbeiter",
    component: Layout,
    children: [
      {
        path: "",
        name: "meine_mitarbeiter",
        component: MeineMitarbeiter,
      },
    ],
  },
  {
    path: "/meine_mitarbeiter_ap",
    component: Layout,
    children: [
      {
        path: "",
        name: "meine_mitarbeiter_ap",
        component: MeineMitarbeiterAP,
      },
    ],
  },
  {
    path: "/trainingscenter",
    component: Layout,
    children: [
      {
        path: "",
        name: "trainingscenter",
        component: Trainingscenter,
      },
    ],
  },
  {
    path: "/teilnahmebedingungen",
    component: Layout,
    children: [
      {
        path: "",
        name: "teilnahmebedingungen",
        component: Teilnahmebedingungen,
      },
    ],
  },
  {
    path: "/abkuerzungsverzeichnis",
    component: Layout,
    children: [
      {
        path: "",
        name: "abkuerzungsverzeichnis",
        component: Abkuerzungsverzeichnis,
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    component: NotFound,
  },
  {
    path: "/bildungspartner",
    alias: "/",
    component: Layout,
    children: [
      {
        path: "",
        name: "bildungspartner",
        component: Bildungspartner,
      },
    ],
  }
];

export default createRouter({
  history: createWebHistory(),
  routes,
});
