<template>

    <eon-ui-section class="hydrated" style="">

        <!-- <div class="mb-10">
            <eon-ui-breadcrumb>
                <a href="/startseite">Startseite</a>
                <a href="/meine_daten_ap">Meine Daten</a>
            </eon-ui-breadcrumb>
        </div> -->

        <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
            <eon-ui-grid-control-column slot="column-1" class="hydrated">

                <eon-ui-headline text="Meine Daten" size="h4" class="hydrated text-blue" v-if="onlineportal_id != ''"></eon-ui-headline>
                <eon-ui-headline text="Hierfür ist eine Anmeldung erforderlich." size="h4" class="hydrated text-blue" v-else></eon-ui-headline>

                <div class="w-2/4" v-if="onlineportal_id != ''">

                  <eon-ui-data-table zebra="true" selectable-rows="false" expandable-rows="false">
                    <eon-ui-table-head style="display:none">
                        <eon-ui-table-head-row>
                        </eon-ui-table-head-row>
                    </eon-ui-table-head>
                    <eon-ui-table-body>

                      <eon-ui-table-row v-for="(entry, index) in tableData" :key="index" :class="(index % 2 == 0)? classNameEven : classNameOdd">
                        <eon-ui-table-cell>{{ entry.name }}</eon-ui-table-cell>
                        <eon-ui-table-cell>{{ (entry.value != '')? entry.value : ' - ' }}</eon-ui-table-cell>
                      </eon-ui-table-row>

                    </eon-ui-table-body>
                  </eon-ui-data-table>

                </div>

                <div>
                  <eon-ui-text text-style="copy">Sie können Ihre personenbezogenen Daten nicht ändern. Bitte benutzen Sie hierzu das
                      <eon-ui-link text="Kontaktformular" v-on:click="goToContact('Änderung Mitarbeiterdaten')"  class="hydrated" scheme="turquoise" size="small" display-inline="true"></eon-ui-link>.
                  </eon-ui-text>
                </div>

            </eon-ui-grid-control-column>
        </eon-ui-grid-control>
    </eon-ui-section>

    <div class="loading-section" :class="loading ? 'show' : ''">
        <eon-ui-preloader-circle></eon-ui-preloader-circle>
    </div>

</template>

<script>
import Resource from "../../composables/bayernwerk";
import _ from "lodash";
const bayernwerkResource = new Resource();

export default {
  name: "AnsprechpartnerDetail",
  data() {
    return {
      onlineportal_id: this.$store.state.ds_id,
      loading: false,
      message_visible: false,
      text_error: "",
      tableData: [],
      classNameEven: "bw-table-even",
      classNameOdd: "bw-table-odd",
      dataAnsprechpartner: {}
    };
  },
  computed: {
    user_token() {
      return this.$store.state.token;
    },
  },
  watch: {
    user_token(newUserData, oldUserData) {
      this.onlineportal_id = this.$store.state.ds_id;
      this.fetchDataAnsprechpartnerDetail(this.onlineportal_id);
    },
  },
  mounted() {
    this.onlineportal_id = this.$store.state.ds_id;
    this.fetchDataAnsprechpartnerDetail(this.onlineportal_id);
  },
  created() {},
  setup() {},
  methods: {
    fetchDataAnsprechpartnerDetail(onlineportal_id) {
      this.loading = true;
      bayernwerkResource
        .getAnsprechpartnerDetail({ onlineportal_id: onlineportal_id })
        .then((res) => {
          if (res != null) {
            if (res.response && res.response.data && res.messages[0].code == "0") {
              let data = res.response.data[0]["fieldData"];
              this.dataAnsprechpartner = {
                ...data
              }
              this.tableData = [
                {
                  value: data["anrede"],
                  name:"Anrede"
                },{
                  value: data["titel"],
                  name:"Titel"
                },{
                  value: data["vorname"],
                  name:"Vorname"
                },{
                  value: data["name"],
                  name:"Nachname"
                },{
                  value: data["SV_Firmen::OrFFi_NameOffiziell"],
                  name:"Firma"
                },{
                  value: data["tel"],
                  name:"Telefon"
                },{
                  value: data["tel2"],
                  name:"Telefon 2"
                },{
                  value: data["mail"],
                  name:"EMail"
                },{
                  value: data["position"],
                  name:"Position"
                }
              ];
            } else {
              this.text_error = res.error_msg;
              this.message_visible = true;
              setTimeout(() => this.message_visible = false, 4000);
              const code = res.messages[0].code;
              if (code == 952 || code == 10) {
                this.logoutTokenExpired();
              }
            }
          } else {
            this.text_error = "Token expired";
            this.message_visible = true;
            this.logoutTokenExpired();
          }
          this.loading = false;
        }).catch((error) => {
          this.loading = false;
          console.log("er", error);
          const code = _.get(error, "response.data.messages[0].code", 0);
          if (code == 952 || code == 10) {
            this.logoutTokenExpired();
          }
        });
    },
    logoutTokenExpired() {
      this.$store.dispatch("user/logout");
      setTimeout(() => this.$router.go(), this.$router.push({ name: "startseite" }), 4000);
    },
    goToContact(subject) {
      this.$store.state.contactSubject = subject;
      this.$router.push({ name: "kontakt" });
    }
  }
};
</script>

