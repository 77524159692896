<template>

  <eon-ui-section class="hydrated">

    <!-- <div class="mb-10">
      <eon-ui-breadcrumb>
        <a href="/startseite">Übersicht</a>
        <a href="/kataloguebersicht">Katalogübersicht</a>
      </eon-ui-breadcrumb>
    </div> -->

    <eon-ui-grid-control grid="grid__d12__t12" class="hydrated">
      <eon-ui-grid-control-column slot="column-1" class="hydrated">

        <eon-ui-headline text="Übersicht Gesamtkatalog" class="hydrated text-blue" size="h4"></eon-ui-headline>

        <div>
          <eon-ui-text size="small" text-style="copy" v-html="dataKataloguebersicht.katalog"></eon-ui-text>
        </div>

        </eon-ui-grid-control-column>
      </eon-ui-grid-control>

  </eon-ui-section>

  <div class="loading-section" :class="loading ? 'show' : ''">
    <eon-ui-preloader-circle></eon-ui-preloader-circle>
  </div>

</template>

<script>
import Resource from "../../../composables/bayernwerk";
import _ from "lodash";

const bayernwerkResource = new Resource();

export default {
  name: "Kataloguebersicht",
  data() {
    return {
      dataKataloguebersicht: {},
      loading: false,
      message_visible: false,
      text_error: "",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    // this.fetchDataGesamtkatalog();
    this.$router.push({ name: "startseite" });
    // console.log(this.dataKataloguebersicht);
  },
  created() {},
  setup() {},
  methods: {
    fetchDataGesamtkatalog() {
      this.loading = true;
      bayernwerkResource.getGesamtkatalog().then((res) => {
        if (res != null) {
          if (res.response && res.response.data && res.messages[0].code == "0") {
            this.dataKataloguebersicht = {
              ...res.response.data[0]["fieldData"],
              katalog: res.response.data[0]["fieldData"]["katalog"]
            };
            // console.log(this.$store.state);
          }
          else {
            this.text_error = res.error_msg;
            this.message_visible = true;
            setTimeout(() => this.message_visible = false, 4000);
            const code = res.messages[0].code;
            if (code == 952 || code == 10) {
            }
          }
        }
        else {
          this.text_error = "Token expired";
          this.message_visible = true;
        }
        this.loading = false;
      }).catch((error) => {
        this.loading = false;
        console.log("er", error);
        const code = _.get(error, "response.data.messages[0].code", 0);
        if (code == 952 || code == 10) {
        }
      });
    }
  }
};
</script>
